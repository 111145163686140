import React, { useEffect, useMemo } from 'react'
import cn from 'classnames'

import { Icon } from 'ui/icon'

import { Portal } from 'components/portal'

import { useModal } from 'lib/hooks/useModal'

import s from './styles.module.scss'

interface ModalProps {
  open: boolean
  fullScreen?: boolean
  onClickOutside?: () => void
  onCloseButtonClick?: () => void
  onEscKeyPress?: () => void
  hideCloseButton?: boolean
  animation?: 'slideUp' | 'fade' | null
  contentClassName?: string
  topOffset?: string
  showOnTop?: boolean
}

const Modal: React.FC<ModalProps> = ({
  children,
  open,
  onClickOutside,
  onEscKeyPress,
  fullScreen,
  onCloseButtonClick,
  hideCloseButton,
  animation = 'fade',
  contentClassName,
  topOffset,
  showOnTop = false,
}) => {
  const { overlayRef, getOverlayRef, containerRef, activateAnimation } = useModal({
    open,
    onClickOutside,
    onEscKeyPress,
  })

  useEffect(() => {
    if (getOverlayRef.current) {
      getOverlayRef.current.style.setProperty('--responsive-modal-margin-top', topOffset || '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, topOffset])

  const animationStyle = useMemo(() => {
    if (animation === 'slideUp') return s.slideUp
    if (animation === 'fade') return s.fade

    return ''
  }, [animation])

  return (
    <Portal open={open}>
      <div
        ref={overlayRef}
        className={cn(s.backdrop, {
          [s.showOnTop]: showOnTop,
          [s.active]: activateAnimation,
          [s.fullScreen]: fullScreen,
        })}
      >
        <div ref={containerRef} className={cn(s.content, contentClassName, animationStyle)}>
          {children}

          {!hideCloseButton && (
            <button className={cn(s.closeBtn, 'cursor-pointer')} onClick={onCloseButtonClick}>
              <Icon name="delete-x" size="medium" />
            </button>
          )}
        </div>
      </div>
    </Portal>
  )
}

export { Modal }
