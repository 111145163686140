// use only in _app. not a custom hook that can be shared
// extracted the logic to a hook to avoid cluttering _app
import { useState, useCallback, useEffect, useRef } from 'react'
import { useRouter } from 'next/router'

import { YELLOW_AI_EVENT_WHITELIST_DOMAIN } from 'lib/constants'

const useYellowAi = ({ shouldRender }: { shouldRender: boolean }) => {
  const { query } = useRouter()

  const defaultRenderScript = shouldRender || query?.chat === 'auto'
  const [renderScript, setRenderScript] = useState(defaultRenderScript)
  const [displayingWidget, setDisplayingWidget] = useState(shouldRender)
  const [showIcon, setShowIcon] = useState(shouldRender)

  const intervalRef = useRef<any>(null)
  const [counter, setCounter] = useState(0)

  useEffect(() => {
    // set it again in useEffect because default view is mobile
    // and we need to listen to isMobileView state changes
    setRenderScript(defaultRenderScript)
    setDisplayingWidget(shouldRender)
    setShowIcon(shouldRender)
  }, [shouldRender, defaultRenderScript])

  // fallback mechanism
  // rely on timer to show the chat widget if the message event method failed. 20 max tries
  useEffect(() => {
    if (counter < 6) return // wait for 3 seconds (6 * 500) before using the fallback method

    const isChatWidgetHidden = window?.YellowMessengerPlugin?.hidden

    // isChatWidgetHidden = undefined -> chat widget is not loaded yet
    if (isChatWidgetHidden === undefined) {
      // hide method helps to attach hidden prop to YellowMessengerPlugin object once the chat widget resources are downloaded
      // by default, the hidden prop is not available on the YellowMessengerPlugin object
      window?.YellowMessengerPlugin?.hide?.()
    }

    if (isChatWidgetHidden === true) {
      setTimeout(() => {
        window?.YellowMessengerPlugin?.show?.()
        window?.YellowMessengerPlugin?.openBot?.()
        setDisplayingWidget(true)
      }, 0)
    }

    if (counter > 20 || isChatWidgetHidden === false) {
      clearInterval(intervalRef.current)
    }
  }, [counter])

  const showAndToggle = useCallback(() => {
    if (!renderScript) {
      setRenderScript(true)

      // backup method using timer in case `create-ui` event is not triggered
      intervalRef.current = setInterval(() => setCounter((prevCounter) => prevCounter + 1), 500)

      const onMessage = (e: any) => {
        if (YELLOW_AI_EVENT_WHITELIST_DOMAIN.includes(e.origin)) {
          try {
            const eventData = JSON.parse(e.data)
            const eventCode = eventData.event_code

            if (['create-ui'].includes(eventCode)) {
              // wait for browser to be idle before showing the chat widget
              setTimeout(() => {
                window?.YellowMessengerPlugin?.show()
                window?.YellowMessengerPlugin?.openBot()
                setDisplayingWidget(true)
              }, 0)
            }
          } catch (e) {}
        }
      }
      window?.addEventListener('message', onMessage)
    }

    window?.YellowMessengerPlugin?.show()
    window?.YellowMessengerPlugin?.toggleChat()
  }, [renderScript])

  const hide = () => {
    setShowIcon(false)
  }

  const show = () => {
    setShowIcon(true)
  }

  useEffect(() => {
    if (displayingWidget) {
      intervalRef.current && clearInterval(intervalRef.current)
    }
  }, [displayingWidget])

  return {
    renderScript,
    showAndToggle,
    hide,
    show,
    showIcon,
  }
}

export { useYellowAi }
