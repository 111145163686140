import { FC, useMemo } from 'react'
import cn from 'classnames'

import { Drawer } from 'components/drawer'

import useTranslation from 'lib/hooks/useTranslation'

const LangCurrencyDrawer: FC<DrawerProps> = ({
  showLanguageSelector,
  noHeaderOffset,
  belowHeader,
  currencyOptions,
  languageOptions,
  selectedCurrencyId,
  selectedLangId,
  langDrawerActive,
  currencyDrawerActive,
  onCurrencySwitch,
  onLanguageSwitch,
  onCurrencyDrawerClose,
  onLangDrawerClose,
}) => {
  const { t } = useTranslation()

  const _currencyOptions = useMemo(
    () =>
      currencyOptions?.map((currency) => {
        const { id, name } = currency

        return {
          id,
          content: (
            <span className={cn({ 'text-dark bold': id === selectedCurrencyId })}>
              <span className="label-sm bold mr-4">{id}</span>
              <span className="label-sm capitalize">{name}</span>
            </span>
          ),
        }
      }),
    [currencyOptions, selectedCurrencyId]
  )

  return (
    <>
      <Drawer
        title={t('t.currency', { ns: 'common' })}
        items={_currencyOptions}
        selectedId={selectedCurrencyId}
        titleIconName="currency-converter"
        active={currencyDrawerActive}
        onClose={onCurrencyDrawerClose}
        noHeaderOffset={noHeaderOffset}
        belowHeader={belowHeader}
        onSelect={(item) => onCurrencySwitch(item.id)}
      />
      {showLanguageSelector && (
        <Drawer
          title={t('t.language', { ns: 'common' })}
          titleIconName="globe"
          selectedId={selectedLangId}
          items={languageOptions}
          active={langDrawerActive}
          noHeaderOffset={noHeaderOffset}
          belowHeader={belowHeader}
          onClose={onLangDrawerClose}
          onSelect={(lang: { id: string }) => onLanguageSwitch(lang.id)}
        />
      )}
    </>
  )
}

export { LangCurrencyDrawer }
