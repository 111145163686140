import React, { forwardRef, useContext } from 'react'
import cn from 'classnames'

import { Icon } from 'ui/icon'

import HighlightText from 'components/highlight-text'

import { AutoCompleteContext, AutocompleteContextProps } from '..'

import s from './styles.module.scss'

interface DestinationCardProps {
  className?: string
  name: string
  countryName: string
  onClick: () => void
}

const DestinationCard = forwardRef<HTMLDivElement, DestinationCardProps>(
  ({ className, name, countryName, onClick }, ref) => {
    const { value } = useContext<AutocompleteContextProps>(AutoCompleteContext)
    const destinationLabel = `${name}, ${countryName}`

    return (
      <div ref={ref} className={cn(s.card, s.destinationCard, className)} onClick={onClick} tabIndex={-1}>
        <div className={s.destination}>
          <Icon name="map-pin" size="semi-medium" />
        </div>
        <div className={s.body}>
          <div className={s.content}>
            <h4 className={s.title}>
              {value ? <HighlightText text={destinationLabel} highlight={value} /> : destinationLabel}
            </h4>
          </div>
          <Icon name="arrow-right" size="medium" className={s.hoverIcon} />
        </div>
      </div>
    )
  }
)

DestinationCard.displayName = 'DestinationCard'

export { DestinationCard }
