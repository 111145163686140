/**
 * This is a singleton to ensure we only instantiate Stripe once.
 */
// Import from `@stripe//stripe-js/pure` will Delay loading the Stripe.js
// script until loadStripe is first called
import { loadStripe } from '@stripe/stripe-js/pure'
import { Stripe } from '@stripe/stripe-js'

import { logError } from '.'

let stripePromise: Promise<Stripe | null>

const getStripe = () => {
  if (!stripePromise) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_KEY!)
    } catch (e: any) {
      // https://github.com/stripe/stripe-js/issues/26#issuecomment-1477716731
      // Stripe throws unhandled exception error and sentry logs which is
      // not required to be logged. And ignoreErrors not working for unhandled errors.
      const hasScriptError = typeof e?.message === 'string' && /Script error./.test(e?.message)
      if (!hasScriptError) {
        logError(e)
      }
    }
  }
  return stripePromise
}

export default getStripe
