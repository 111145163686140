import cn from 'classnames'

import { useState } from 'react'

import { Icon } from 'ui/icon'
import { Chip } from 'ui/chip'

import { Drawer } from 'components/drawer'

import { useBlogData } from 'lib/context/blog-context'
import { getCustomCSSVar, scrollTo } from 'lib/utils'

import useCustomTranslation from 'lib/hooks/useTranslation'

import s from './styles.module.scss'

const SummaryDrawer = () => {
  const { t } = useCustomTranslation()
  const { summary, summaryListRefs } = useBlogData()
  const [activeDrawer, setActiveDrawer] = useState(false)
  const headerHeight = Number(getCustomCSSVar('--header-height')?.split?.('px')?.[0]) * -1

  return (
    <>
      <div className={cn(s.summaryDrawer, { [s.active]: activeDrawer })}>
        <div className={s.container} onClick={() => setActiveDrawer(false)}>
          {/* this empty div is for grey fill */}
          <div className={cn(s.panel, { [s.active]: activeDrawer })}>
            <Drawer onClose={() => setActiveDrawer(false)} active={activeDrawer} noBackButton belowHeader>
              <div className={s.header}>
                <h2>{'Summary'}</h2>
                <Icon
                  name="delete-x"
                  size={'medium'}
                  className="cursor-pointer"
                  onClick={() => setActiveDrawer(false)}
                />
              </div>
              <div>
                {summary.topics?.map((item, index) => {
                  return (
                    <p
                      key={index}
                      className={s.list}
                      onClick={() => {
                        if (summaryListRefs?.current?.[index]) {
                          // 30 : offsetting some space
                          scrollTo(summaryListRefs?.current?.[index], headerHeight + 30, 'smooth')
                        }
                        setActiveDrawer(false)
                      }}
                    >
                      {item}
                    </p>
                  )
                })}
              </div>
            </Drawer>
          </div>
        </div>
      </div>
      <div className={s.summaryStickyButton}>
        <Chip
          label={'Summary'}
          iconName="list"
          iconSize="semi-medium"
          size="large"
          variant="light-ghost-bold"
          onClick={() => setActiveDrawer(true)}
        />
      </div>
    </>
  )
}

export { SummaryDrawer }
