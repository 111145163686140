import { gql } from '@apollo/client'

import { PelagoError } from '../fragments'

export const CREATE_ITINERARY_MUTATION = {
  mutation: gql`
    mutation createItinerary($details: ItineraryCreateInput!) {
      createItinerary(details: $details) {
        ...on Itinerary {
          itineraryId
          itineraryCode
          itineraryNameTranslated
          itineraryUri
          itineraryState
          itineraryType
          dayCount
          preference
          itineraryPrompt
          isVisible
          itineraryMetaData
          primaryDestinationId
          customerId
          dsUserId
          dateCreated
          dateModified
          itineraryName
          itineraryTextPrompt
        }
        ${PelagoError}
      }
    }
  `,
  mutationName: 'createItinerary',
}
