import React from 'react'

import { Button } from 'ui/button'

import MainLayout from 'components/layout/main-layout'

import useTranslation from 'lib/hooks/useTranslation'

import { buildPath } from 'lib/utils'

import { HOME_ROUTE } from 'lib/constants/routes'

import SomthingWentWrongImage from 'brand-assets/illustrations/something-went-wrong.svg'

import s from './styles.module.scss'

const ErrorFallback = () => {
  const { t, tp } = useTranslation()

  return (
    <MainLayout noFooter variant="static">
      <MainLayout.Content whiteBg>
        <div className={s.container}>
          <div className={s.content}>
            <div className={s.title}>
              {t('t.oops')}
              <br />
              {t('msg.somethingWentWrong')}
            </div>
            <SomthingWentWrongImage className={s.illustration} />
            <div className={s.description}>{tp('msg.pleaseTryAgainLater')}</div>
            <div className={s.buttonContainer}>
              <Button
                variant="primary"
                size="large"
                fluid
                onClick={() => {
                  // Need a full page refresh here to trigger the getInitialProps in _app page.
                  // Static pages doesnt call _app getInitialProps and this is required for getting the latest globalArgs.
                  window.location.href = buildPath(HOME_ROUTE)
                }}
              >
                {t('action.back', { ns: 'common' })}
              </Button>
            </div>
          </div>
        </div>
      </MainLayout.Content>
    </MainLayout>
  )
}

export { ErrorFallback }
