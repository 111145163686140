import { gql } from '@apollo/client'
import { PelagoError } from 'gql/fragments'

export const CREATE_REVIEW_MUTATION = {
  mutation: gql`
    mutation createReview($details: ReviewInput!) {
      createReview(details: $details) {
        ...on PelagoSuccess {
          code
          additionalDetails
        }
        ${PelagoError}
      }
    }
  `,
  mutationName: 'createReview',
}

export const TRANSLATE_PRODUCT = {
  mutation: gql`
    mutation translateProduct($productId: String!) {
      translateProduct(productId: $productId) {
        ... on PelagoSuccess {  
          code
        }
        ${PelagoError}
      }
    }
  `,
  mutationName: 'translateProduct',
}
