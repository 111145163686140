import { gql } from '@apollo/client'
import { customerFields, PelagoError } from '../fragments'

export const PROFILE_MUTATION = {
  mutation: gql`
    mutation editProfile($details: CustomerUpdateInput!) {
      editProfile(details: $details) {
        ...on Customer {
          ${customerFields}
        }
        ${PelagoError}
      }
    }
  `,
  mutationName: 'editProfile',
}

export const PASSWORD_MUTATION = {
  mutation: gql`
    mutation changePassword($currentPassword: String!, $newPassword: String!) {
      changePassword(currentPassword: $currentPassword, newPassword: $newPassword) {
        ...on PelagoSuccess {
          code
        }
        ${PelagoError}
      }
    }
  `,
  mutationName: 'changePassword',
}

export const REWARD_PROMO_CLAIM = {
  mutation: gql`
    mutation rewardsPromoClaim($promoCode: String!) {
      rewardsPromoClaim(promoCode: $promoCode) {
        ... on PelagoSuccess {
          code
          additionalDetails
        }
        ... on PelagoError {
          code
          errorDetails
          errorMessage
          errorHeader
        }
      }
    }
  `,
  mutationName: 'rewardsPromoClaim',
}
