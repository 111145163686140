// this is just wrapper component to make use of useAuth hook
import React, { useEffect, useState } from 'react'

import { Toast } from 'components/toast'

import useAuth from 'lib/hooks/useAuth'
import useCustomTranslation from 'lib/hooks/useTranslation'

const AccountCreatedToast = () => {
  const [open, setOpen] = useState(false)
  const { accountCreatedToast } = useAuth()
  const { t, tp } = useCustomTranslation()

  // use effect state update is requied for making sure that the render will happen as expected
  // instead of just relying on accountCreatedToast.isOpen boolean value
  useEffect(() => setOpen(accountCreatedToast.isOpen), [accountCreatedToast.isOpen])

  return open ? (
    <Toast
      type="success"
      onClose={accountCreatedToast.close}
      message={`${t('traveller.msg.welcome')} ${tp('msg.yourBrandAccSuccessfullyCreatedAndVerifyied', {
        brand: t('brand', { ns: 'common' }),
      })}`}
    />
  ) : null
}

export { AccountCreatedToast }
