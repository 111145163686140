const localeInfo = require('../../locale-info.json')

export const LOCAL_STORAGE_KEY = 'pelago'
export const COOKIES_TOKEN = '__pelago-token__'
export const COOKIES_REFRESH_TOKEN = '__pelago-refresh-token__'
export const COOKIES_CURRENCY = '__pelago-currency__'
export const COOKIES_USER_LOCALE = '__pelago-user-locale__' // used for cloudfront locale redirection. DO NOT CHANGE OR REMOVE
export const COOKIES_RECENT_PDP_VISIT = '__pelag_pdp_visit__'
export const COOKIES_RECENT_PDP_VISIT_EXPIRY = 1000 * 60 * 60 * 24 * 365 // 365 days
export const ACTIVITY_LOG = '__activity-log__'
export const STATIC_FIELDS_LS_KEY = '__static_fields__'
export const STATIC_FIELDS_EXPIRY = 1000 * 60 * 60 * 24 * 30 // 30 days
export const ACTIVITY_LOG_EXPIRY = 1000 * 60 * 60 * 24 * 30 // 30 days
export const COOKIES_DS_USER_ID = 'dsUserId'
export const COOKIES_DS_SESSION_ID = '__pelago-ds-session-id__'
export const COOKIES_DS_REF = '__ds_ref__'
export const SESSION_BOOKING_COUNT = '__pelago-bc__'
export const EXIT_INTENT_LS_KEY = '__pelago-ei__'
export const SESSION_ENTRY_INTENT_KEY = '__pelago-entry_intent__'
export const COOKIES_PARTNER_SESSION_ID = '__pelago_partner_session_id__'
export const COOKIES_USER_LOCATION = '__u_l__'

export const PARTNER_SESSION_EXPIRY: Record<string, number> = {
  dev: 25 * 60, // 25 mins
  stage: 25 * 60, // 25 mins
  prod: 24 * 60 * 60 - 300, // 23 Hours 55 mins
}

export const UTM_STORAGE_KEY = '__utm__'
export const UTM_COOKIE_EXPIRATION = 30 * 24 * 60 * 60
// user come to pelago website from what place is called last click
export const COOKIES_LAST_CLICK = '__last_click_from__'
export const COOKIES_LAST_CLICK_EXPIRATION = UTM_COOKIE_EXPIRATION
// user came to pelago website first time from what place is called first click
export const COOKIES_FIRST_CLICK = '__first_click_from__'
export const COOKIES_FIRST_CLICK_EXPIRATION = UTM_COOKIE_EXPIRATION
export const REFERER_STORAGE_KEY = '__referer__'

// NOTE: if below expiration changes in future, please update the same in viewer-response-base.js file used for cloudfront
export const REFERER_COOKIE_EXPIRATION = UTM_COOKIE_EXPIRATION

export const COOKIES_SHOPBACK_TRANSACTION_ID = '__spbk_id__'
export const COOKIES_SHOPBACK_TRANSACTION_ID_EXPIRYATION = UTM_COOKIE_EXPIRATION

export const LOCAL_STORAGE_SEASONAL_CAMPAIGN = '__seasonal_campaign__'

// uncomment below if decided to go cookie based in future due to high api call rate
// export const COOKIE_FINGERPRINT_REQUEST_ID = '__fpr_id__'
// export const COOKIE_FINGERPRINT_REQUEST_ID_EXPIRY = 1000 * 60 * 60 * 24 * 1 // 1 day

export const HEADER_PAGE_VIEW_ID = 'pageViewId'
export const HEADER_USER_AGENT = 'user-agent'
export const HEADER_CLOUNDFRONT_IS_MOBILE_VIEWER = 'cloudfront-is-mobile-viewer'
export const HEADER_CLOUNDFRONT_IS_TABLET_VIEWER = 'cloudfront-is-tablet-viewer'

export const HEADER_REFERER = 'referer'

export const HEADER_CLOUDFRONT_COUNTRY = 'cloudfront-viewer-country'
export const HEADER_CLOUDFRONT_LATITUDE = 'cloudfront-viewer-latitude'
export const HEADER_CLOUDFRONT_LONGITUDE = 'cloudfront-viewer-longitude'
export const HEADER_CLOUDFRONT_CITY = 'cloudfront-viewer-city'

export const HEADER_CURRENCY = 'X-Currency-Id'

export const FALLBACK_CURRENCY = 'USD'
export const PARTNER_DEFAULT_COUNTRY_CODE = 'SG'
export const PARTNER_DEFAULT_CURRENCY = 'SGD'

export const CUSTOM_UNIT_NAME_ID = 'const_custom'

export const FALLBACK_LOCALE = localeInfo.fallbackLocale
export const SUPPORTED_LOCALES_WITH_LEGACY = localeInfo.supportedLocalesWithLegacy as string[]

export const LOCALE_EN_US = 'en-US'

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i

export const UNSAFE_URL_CHARACTERS = /(\s|"|<|>|&|#|%|~|`|\?|\(|\)|\{|\}|\||\\|\/|\^|\[|\])/g

export const INPUT_NUMBERS_SPECIAL_CHARACTERS_REGEX =
  /^[^0-9\!@#\$%\^&\*\(\)\_\=\+\{\}\[\]\|\\\;\:\"\,\.\<\>\/\?]*$/

export const IS_MOBILE_REGEX =
  /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Opera M(obi|ini)|WPDesktop/i
export const IS_TABLET_REGEX = /tablet|ipad|playbook/i

export const PASSWORD_MIN_LENGTH = 8

export const KF_NUMBER_LENGTH = 10

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const LOGIN_PASSWORD_ENCRYPTION_KEY = process.env.NEXT_PUBLIC_LOGIN_PASSWORD_ENCRYPTION_KEY!
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const LOCAL_STORAGE_ENCRYPTION_KEY = process.env.NEXT_PUBLIC_LOCAL_STORAGE_ENCRYPTION_KEY!

export const KRISFLYER_LINK = '//pages.pelago.co/en/krisflyer'
export const SIA_LINK = '//pages.pelago.com/sia-promo'
export const SCOOT_LINK = '//pages.pelago.com/scoot-promo'
export const HELP_AUTHORISATION_HOLD =
  'https://help.pelago.co/hc/en-us/articles/900003108743-What-is-an-authorization-hold-'
export const TERMS_OF_USE_LINK = '//pages.pelago.co/en/terms-of-use'
export const PELAGO_POLICY_LINK =
  '//help.pelago.co/hc/en-us/articles/900002296183-What-is-Pelago-s-Cancellation-Policy'
export const PRIVACY_POLICY_LINK = '//pages.pelago.co/en/privacy-policy'
export const DISNEY_CRUISE_POLICY_LINK = '//pages.pelago.com/disneycruise-bookingterms'
export const COOKIE_POLICY_LINK = '//pages.pelago.co/en/cookie-policy'
export const SUPPORT_REQUEST_FORM = '//help.pelago.co/hc/en-us/requests/new'
export const SUPPORT_LINK = 'https://pelagohelp.zendesk.com/hc/en-us/requests/new'
export const OTHER_BOOKING_RELATED_CONTACT_LINK =
  'https://help.pelago.co/hc/en-us/requests/new?ticket_form_id=900000059586'
export const BEST_PRICE_GUARANTEED_HELP_TICKET_LINK =
  'https://ask.pelago.co/hc/en-us/requests/new?ticket_form_id=17504372797849'
export const BEST_PRICE_GUARANTEED_PROMOTION_LINK = 'https://pages.pelago.co/p/promotions-bestprices'
export const PROMOTIONS_LINK = 'https://pages.pelago.co/en/promotions'
export const HELPCENTER_BASE = '//help.pelago.co/hc/'
export const HELPCENTER_LINK = '/helpcenter'
export const HELP_CENTER_QUERY = 'https://ask.pelago.co/hc/en-us/search?utf8=%E2%9C%93&query='
export const CAREERS_LINK = '//pages.pelago.co/en/careers'
export const ABOUT_US_LINK = '//pages.pelago.co/en/about-us'
export const BLOG_LINK = 'https://www.pelago.com/{LOCALE}/articles'
export const SUPPLY_PARTNER_LINK =
  'https://docs.google.com/forms/d/e/1FAIpQLSe1T04ifNkhaIW4hzTDqWy88EBjrS08cViFEGWldaRZ9BLThw/viewform'
export const AFFILIATE_PARTNER_LINK =
  'http://app.impact.com/campaign-campaign-info-v2/Pelago.brand?io=8u%2FnGqxJGo1xcjc9qn19Q8eFUZxj5v7VE%2FfpVdYVHYio937AbyR5dYY9k5G7KxVL'

export const MOBILE_APP_DOWNLOAD_LINK = 'https://pel.sg/getapp'
export const APP_STORE_LINK = 'https://pel.sg/get-ios-app'
export const GOOGLE_PLAYSTORE_LINK = 'https://pel.sg/get-android-app'

export const YELLOW_AI_EVENT_WHITELIST_DOMAIN = [
  'https://pelago.co',
  'https://www.pelago.co',
  'https://traveller.dev.pelago.co',
  'https://traveller.stage.pelago.co',
  'https://traveller.qa.pelago.co',
  'https://pelago.com',
  'https://www.pelago.com',
  'https://traveller.dev.pelago.com',
  'https://traveller.stage.pelago.com',
  'https://traveller.qa.pelago.com',
]

export const STORAGE_WISHLISTED_PRODUCT_ID = 'wishlist-product-id'
export const STORAGE_WISHLIST_SHOW_TOOLTIP = 'wishlist-show-tooltip'

export const NEW_ACCOUNT_CREATED_FLAG = 'new-account-created-flag'
export const NEW_USER_CONSENT_MODAL = 'new-user-consent-modal'

export const PAGE_SIZE = {
  AUTO_SUGGEST_DESTINATION: 2,
  AUTO_SUGGEST_COUNTRY: 2,
  AUTO_SUGGEST_PRODUCT: 4,
  SEARCH_RESULT_DESTINATION: 10,
  SEARCH_RESULT_PRODUCT: 10,
  PRODUCT_PAGE_SIZE: 6,
}

export const UNIT_PRICE_TYPE = {
  GROUP: 'GROUP',
  INDIVIDUAL: 'INDIVIDUAL',
}

export const CONFIRMATION_TYPE = {
  INSTANT: 'INSTANT',
  MANUAL: 'MANUAL',
}

export const PAYMENT_STATE = {
  FAILED: 'FAILED',
  CAPTURED: 'CAPTURED',
  AUTHORIZED: 'AUTHORIZED',
  VOIDED: 'VOIDED',
}

export const BOOKING_STATE = {
  INITIALIZED: 'INITIALIZED',
  FAILED: 'FAILED',
  PENDING: 'PENDING',
  CONFIRMED: 'CONFIRMED',
  REJECTED_BY_PROVIDER: 'REJECTED_BY_PROVIDER',
  REJECTED_BY_SYSTEM: 'REJECTED_BY_SYSTEM',
}

export const MOBILE_VALIDATION_CLASSNAME = 'mobile-validation'

export const PAYMENT_OPTION_KF_MILES = 'KF-MILES'

export const KEYS = {
  BACKSPACE: 8,
  ENTER: 13,
  ESCAPE: 27,
  SPACEBAR: 32,
  LEFT_ARROW: 37,
  UP_ARROW: 38,
  RIGHT_ARROW: 39,
  DOWN_ARROW: 40,
}

export const SCHEMA_FIELD = {
  TEXT: 'input',
  PHONE: 'phoneInput',
  NUMBER: 'numberInput',
  CHECKBOX: 'checkBoxInput',
  TEXTAREA: 'textAreaInput',
  PROMO: 'promoInput',
  PROMOTION: 'promotionRadio',
  PROMOTION_INPUT: 'promotionInput',
  SELECT: 'selectInput',
  FILE: 'fileInput',
  DATETIME: 'dateTimePickerInput',
  LABEL: 'label',
}

export const BOOKING_FIELDS = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  EMAIL: 'email',
  PHONE: 'contactNumber',
  EARN_KRISFLYER: 'krisflyerNumber',
  PROMOTION_OPTIONS: 'promotionOptions',
  PROMOCODE: 'promo',
  PROMO_WHITELISTING: 'promoWhitelisting',
  LAST_NAME_IN_PNR: 'pnrLastName',
  PNR: 'pnrRef',
  SIAPNR: 'siaPnr',
  SCOOTPNR: 'scootPnr',
  FLAG_SPECIAL_REQUEST: 'additionalNotesFlag',
  SPECIAL_REQUEST: 'additionalNotes',
  VERIFIED_KRISFLYER_NUMBER: 'verifiedKrisflyerNumber',
} as const

export const BOOKING_FIELDS_GROUP: Record<string, string[]> = {
  [BOOKING_FIELDS.PROMOCODE]: [BOOKING_FIELDS.PROMOTION_OPTIONS, BOOKING_FIELDS.PROMOCODE], // order is as per mockup grouping order
  [BOOKING_FIELDS.PNR]: [BOOKING_FIELDS.LAST_NAME_IN_PNR, BOOKING_FIELDS.PNR], // order is as per mockup grouping order
  [BOOKING_FIELDS.SPECIAL_REQUEST]: [BOOKING_FIELDS.FLAG_SPECIAL_REQUEST, BOOKING_FIELDS.SPECIAL_REQUEST], // order is as per mockup grouping order
}

export const PROMOTION = {
  PROMOCODE: 'promocode',
  PNR: 'pnr',
}

export const PAYMENT_OPTIONS: Record<string, 'stripe' | 'kf-burn' | 'zero'> = {
  STRIPE: 'stripe',
  KRISFLYER: 'kf-burn',
  ZERO_DOLLAR: 'zero',
}

export const PAYMENT_COMPONENT_STATE = {
  NONE: 0,
  IN_PROGRESS: 1,
  DONE: 2,
  ERROR: 3,
}

export const BUYER_DETAIL_SECTION_ID = 'buyerDetailsSection'
export const PROMO_SECTION_ID = 'promoSection'
export const KF_ACCOUNT_SECTION_ID = 'kfAccountSection'
export const TRAVELLER_DETAIL_SECTION_ID = 'travellerDetailsSection'

export const KF_REDEMPTION_FAIL = 405
export const KF_ACCOUNT_NAME_MISMATCH = 409

export const CANCELLATION_AND_CONFIRMATION_RULE_COMPONENT = 0
export const MARKETING_CONSENT_COMPONENT = 1

export const PROMO_NOT_APPLICABLE = 409
export const CAN_NOT_ACCEPT_BOOKING = 400

export const RETRY_OTP_MAX_ATTEMPTS_REACHED = 429
export const PROMPT_OTP_VERIFICATION = 428

export const UNIT_PRICE_CHANGED = 426
export const UNIT_FULLY_BOOKED = 424

export const EMAIL_SUGGESTION_STATUS_CODE = 400
export const INVALID_EMAIL_STATUS_CODE = 406
export const UNVERIFIED_KF_NUMBER_STATUS_CODE = 460

export const CLOSE_MODAL_CTA_ERROR = 1
export const BACK_TO_OPTIONS_CTA_ERROR = 2
export const VIEW_MORE_PRODUCTS_CTA_ERROR = 3

export const DATE_FILTER_ID = {
  TODAY: '1',
  TOMORROW: '2',
  THIS_WEEKEND: '3',
}

export const DATE_FILTER = {
  THIS_WEEKEND: 'this_weekend',
  TODAY: 'today',
  TOMORROW: 'tomorrow',
}

export const ACTIVITY_TYPES_MAPPING: Record<string, string> = {
  best_suited_for: 'bestSuitedForTags',
  characteristic: 'characteristicTags',
}

export const DATA_LAYER_EVENT = {
  REGISTRATION_COMPLETE: 'registrationComplete',
  LOGIN_COMPLETE: 'loginComplete',
  BOOKING_COMPLETE: 'bookingComplete',
  FIRST_BOOKING: 'firstBooking',
  PAYMENT_SCREEN: 'paymentScreen',
  PRODUCT_CHECKOUT: 'productCheckout',
  PROCEED_TO_PAY: 'proceedToPay',
  PAYMENT_BUTTON_CLICK: 'paymentButtonClick',
  VIEW_PRODUCT_DETAILS: 'viewProductDetails',
  SOCIAL_SHARE_CLICK: 'socialShareClick',
  SEARCH: 'search',
  ADD_TO_WISHLIST: 'addToWishList',
  // ga4 ecommerce keys
  ECOMMERCE: {
    ITEM_CATEGORY: 'item_category',
    ITEM_SUBCATEGORY: 'item_subcategory',
    ITEM_TAG: 'item_tag',
    SEARCH_TERM: 'search_term',
  },
}

export const MOBILE_VIEW_BREAKPOINT = 600
export const TABLET_VIEW_BREAKPOINT = 769

export const CANCELLATION_REASON_OTHERS = 'others'

// country code for phone input
export const DEFAULT_PHONE_INPUT_COUNTRY_CODE = 'sg'
export const PREFERRED_PHONE_INPUT_COUNTRY_CODE = ['sg', 'my', 'au', 'id', 'in', 'th', 'us', 'gb']

export const PARTNER_LIST = ['krisplus']

export const PNR_PARTNERS: Record<PnrPartnersType, any> = {
  SIA: { label: 'Singapore Airlines', placeholderName: 'SQ' },
  SCOOT: { label: 'Scoot', placeholderName: 'Scoot' },
}
export const DEFAULT_PNR_PARTNER = 'SIA'

export const SKIP_PLACEHOLDER_AS_DESC = ['checkBoxInput', 'fileInput', 'label']

// load failed - networkd error
// refresh token expired - refresh token expired which means force logout
// failed to fetch - javascript fetch api call in progress when user move away from page (track.js)
// not enough segments - invalid token - force logout
// evaluating 'e.getAttribute' - customer.io script error
// searchFilterProducts GraphQL Error - search filter error. Mostly due to longer response time. BE will debug.
// Script error - stripe.js script error
// Maximum call stack size exceeded - posthog script error
// Event `ErrorEvent` captured as exception with message `Script erro - 3rd party script CORS error
// Invariant: attempted to hard navigate to the same URL - Internal nextjs error, nothing we can do in code
// Can't find variable: gtag - gtag loading error, no users were affected
// Failed to load the JS script of the agent - fingerprint error due to some browser blocking 3rd party script
// FingerprintJS agent is not initialized - fingerprint error
// Cannot read properties of null (reading 'content') - Nextjs internal error when updating head
// Non-Error promise rejection captured with value: Timeout (u) - seems like recaptcha error src: https://github.com/getsentry/sentry-javascript/issues/2514
// The object can not be found here. - error for some safari plugins
export const SENTRY_ERROR_IGNORE_REGEX = [
  /Load failed/,
  /Refresh token has expired/,
  /Failed to fetch/,
  /Not enough segments/,
  /Cannot read properties of null \(reading \'getAttribute\'\)/,
  /routeChange aborted/,
  /e is null/,
  /evaluating 'e.getAttribute'/,
  /searchFilterProducts GraphQL Error/,
  /Script error/,
  /Maximum call stack size exceeded/,
  /Event `ErrorEvent` captured as exception with message `Script error.`/,
  /recommendedProductAndExplore GraphQL Error/,
  /Invariant: attempted to hard navigate to the same URL/,
  /Can't find variable: gtag/,
  /Failed to load the JS script of the agent/,
  /FingerprintJS agent is not initialized/,
  /Cannot read properties of null (reading 'content')/,
  /Non-Error promise rejection captured with value: Timeout (u)/,
  /The object can not be found here./,
]

export const APOLLO_CLIENT_VERSION = {
  CONTENTFUL_DELIVERY: 'contentfulDelivery',
  CONTENTFUL_PREVIEW: 'contentfulPreview',
  SEARCH: 'search',
  PELAGO_CORE: 'default',
} as const

export const CREATE_ITINERARY_TABS = {
  ASK_ANYTHING: 'askAnything',
  PERSONALISE_TRIP: 'personaliseTrip',
}

export const CREATE_ITINERARY_ERRORS = {
  MISSING_INFO: 1001,
  SOMETHING_WENT_WRONG: 1002,
  TOO_MANY_REQUESTS: 1003,
}

export const CREATE_ITINERARY_STATE = {
  PENDING: 'PENDING',
  FAILED: 'FAILED',
  UNPUBLISHED: 'UNPUBLISHED',
  PUBLISHED: 'PUBLISHED',
} as const

export const PDP_TAB_IDS = {
  hero: 'hero',
  productOptions: 'productOptions',
  imageHighlights: 'imageHighlights',
  customerReviews: 'customerReviews',
  additionalInfo: 'additionalInfo',
  location: 'location',
  faq: 'faq',
  recommendedProducts: 'recommendedProducts',
  itinerary: 'itinerary',
  features: 'features',
}

export const PDP_TABS = {
  [PDP_TAB_IDS.hero]: 'overview',
  [PDP_TAB_IDS.productOptions]: PDP_TAB_IDS.productOptions,
  [PDP_TAB_IDS.imageHighlights]: PDP_TAB_IDS.imageHighlights,
  [PDP_TAB_IDS.itinerary]: PDP_TAB_IDS.itinerary,
  [PDP_TAB_IDS.features]: PDP_TAB_IDS.features,
  [PDP_TAB_IDS.customerReviews]: PDP_TAB_IDS.customerReviews,
  [PDP_TAB_IDS.additionalInfo]: PDP_TAB_IDS.additionalInfo,
  [PDP_TAB_IDS.location]: PDP_TAB_IDS.location,
  [PDP_TAB_IDS.faq]: PDP_TAB_IDS.faq,
  [PDP_TAB_IDS.recommendedProducts]: PDP_TAB_IDS.recommendedProducts,
}

// this one is used when data need to pass from _app server to page server method
export const APP_TO_PAGE_SSR_DATA = {
  SSR_CURRENCY: 'X-SSR-Currency',
}

export const CAMPAIGN_TYPE = {
  SEASONAL_CAMPAIGN: 'SEASONAL_CAMPAIGN',
  UTM_CAMPAIGN: 'UTM_CAMPAIGN',
}
