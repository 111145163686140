import React, { FC, useEffect, useMemo, useRef } from 'react'
import cn from 'classnames'

import { Icon } from 'ui/icon'

import useLayoutEffect from 'lib/hooks/useLayoutEffect'

import { useGlobalContext } from 'lib/context/global-context'

import { ToastProps } from './types'

import s from './styles.module.scss'

const Toast: FC<ToastProps> = ({
  onClose,
  message,
  children,
  content,
  hideClose,
  autoClose = 5000,
  created,
  type = 'default',
}) => {
  const { isMobileView } = useGlobalContext()
  const elementRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (autoClose && onClose) {
      // created will be only available for the toast which triggered using toast.<method>
      const remainingTime = created ? created + autoClose - Date.now() : autoClose
      const toastId = setTimeout(onClose, remainingTime)

      return () => clearTimeout(toastId)
    }
  }, [autoClose, created, onClose])

  const iconName = useMemo(() => {
    if (type === 'success') return 'success'
    if (type === 'error') return 'warning'

    return undefined
  }, [type])

  // The useLayout effect mentioned below can be eliminated once the direct usage of the <Toast /> component is
  // discontinued and the toast.<success/error/notify> methods are adopted instead. Once all <Toast /> components
  // have been eliminated, the stacking can be accomplished through the ToastContainer, which will ultimately serve
  // as the authoritative source for all toasts.
  useLayoutEffect(() => {
    const stackToastWithMargin = () => {
      let totalHeight = 0
      const gap = 8
      document.body?.querySelectorAll?.('div[data-component="toast"]')?.forEach?.((ele) => {
        if (elementRef.current && ele === elementRef.current) {
          const transform = `translate(${isMobileView ? 0 : -50}%, ${totalHeight}px)`
          // once we find the element, transform and offset based on previous toasts total heights on the DOM
          elementRef.current.style.transform = transform
        } else {
          totalHeight += ele?.getBoundingClientRect?.()?.height + gap
        }
      })
    }
    stackToastWithMargin()

    // keep on checking while the element is active on DOM
    const intervalId = setInterval(stackToastWithMargin, 300)
    return () => clearInterval(intervalId)
  }, [isMobileView])

  return (
    <div
      ref={elementRef}
      data-component="toast"
      className={cn(s.toast, {
        [s.error]: type === 'error',
        [s.success]: type === 'success',
      })}
    >
      <div className={s.toastContainer}>
        {iconName && <Icon name={iconName} size="medium" className={s.toastIcon} />}

        {children || content || <span className="label-sm">{message}</span>}

        {!hideClose ? (
          <span className={cn(s.closeIcon, 'cursor-pointer')}>
            <Icon name="delete-x" size="small" onClick={() => onClose?.()} />
          </span>
        ) : null}
      </div>
    </div>
  )
}

export { Toast }
