/**
 * Singleton class to avoid re-initialize logger
 */

class Logger {
  static instance: any

  static getInstace() {
    if (!Logger.instance && typeof window === 'undefined') {
      const winstonLogger = require('./winston-logger')
      Logger.instance = winstonLogger
    }

    return Logger.instance
  }
}

export default Logger
