import { ReactNode, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import s from './styles.module.scss'

type ImageLazyLoadProps = {
  skipLazyLoad?: boolean
  edge?: 'top' | 'bottom'
  offset?: string
  children: ReactNode
}

const ImageLazyLoad = ({ edge = 'bottom', offset = '300px', skipLazyLoad, children }: ImageLazyLoadProps) => {
  const [hasLoaded, setHasLoaded] = useState(false)

  const rootMargin = edge === 'top' ? `${offset} 0px 0px 0px` : `0px 0px ${offset} 0px`
  const { ref, inView } = useInView({ rootMargin })

  useEffect(() => {
    if (inView) {
      setHasLoaded(true)
    }
  }, [inView])

  return (
    <div ref={ref} className={s.imageLazyLoadContainer}>
      {skipLazyLoad ? children : hasLoaded && children}
    </div>
  )
}

export { ImageLazyLoad }
