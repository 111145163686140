// import ActivityDateOutline from './activity-date-outline.svg'
import Airplane from './airplane.svg'
import AirplaneLanding from './airplane-landing.svg'
import AirplaneTilt from './airplane-tilt.svg'
// import ArrowDown from './arrow-down.svg'
import ArrowDownUp from './arrows-down-up.svg'
import ArrowLeft from './arrow-left.svg'
import ArrowRight from './arrow-right.svg'
// import ArrowsOut from './arrows-out.svg'
import ArrowUp from './arrow-up.svg'
// import Attractions from './attractions.svg'
import Baby from './baby.svg'
import Bird from './bird.svg'
import Boat from './boat.svg'
import BookingDateOutline from './booking-date-outline.svg'
// import BookingsOutline from './bookings-outline.svg'
import Books from './books.svg'
import Briefcase from './briefcase.svg'
import CalendarCancellation from './calendar-cancellation.svg'
import CalendarCancellationOutline from './calendar-cancellation-outline.svg'
import CalendarCheckOutline from './calendar-check-outline.svg'
import CalendarOutline from './calendar-outline.svg'
import Cancellation from './cancellation.svg'
import CancellationPolicyOutline from './cancellation-policy-outline.svg'
import Car from './car.svg'
import CaretDoubleUp from './caret-double-up.svg'
import CaretUp from './caret-up.svg'
import CaretDown from './caret-down.svg'
import CaretDownCircle from './caret-down-circle.svg'
import CaretRight from './caret-right.svg'
import CaretLeft from './caret-left.svg'
import Chats from './chats.svg'
import ChatSmile from './chat-smile.svg'
import ChatTearDropText from './chat-teardrop-text.svg'
import Check from './check.svg'
import CheckCircle from './check-circle.svg'
import CheckCircleOutline from './check-circle-outline.svg'
import CheckSquareOffset from './check-square-offset.svg'
import CircularProgress from './circular-progress.svg'
import CircleWavyCheck from './circle-wavy-check.svg'
// import ClockClockwise from './clock-clockwise.svg'
import Clock from './clock.svg'
// import Compass from './compass.svg'
// import Copy from './copy.svg'
import ColorPlate from './color-plate.svg'
import CopySimple from './copy-simple.svg'
// import Covid19SafeOutline from './covid-19-safe-outline.svg'
import CreditCardOutline from './credit-card-outline.svg'
import CurrencyCircle from './currency-circle.svg'
import CurrencyConverter from './currency-converter.svg'
import DeviceMobile from './device-mobile.svg'
import DotsThree from './dots-three.svg'
import DotsSix from './dots-six.svg'
import DownloadSimple from './download-simple.svg'
import Edit from './edit.svg'
import Elderly from './elderly.svg'
import Envelope from './envelop.svg'
import EyeSlash from './eye-slash.svg'
import Eye from './eye.svg'
// import FaceMask from './face-mask.svg'
import FacebookRound from './facebook-round.svg'
// import FilterSlider from './filter-slider.svg'
// import FilterSliderOutline from './filter-slider-outline.svg'
import Fire from './fire.svg'
import FlowerLotus from './flower-lotus.svg'
import ForkKnife from './fork-knife.svg'
import Fort from './fort.svg'
// import Funnel from './funnel.svg'
import Gem from './gem.svg'
import Gift from './gift.svg'
import Globe from './globe.svg'
import GlobeSimple from './globe-simple.svg'
// import Headset from './headset.svg'
import HandPointing from './hand-pointing.svg'
import HandWaving from './handwaving.svg'
import Hash from './hash.svg'
import Heart from './heart.svg'
import HeartFill from './heart-fill.svg'
// import Home from './home.svg'
// import HourglassMedium from './hourglass-medium.svg'
import IdentificationCardOutline from './identification-card-outline.svg'
import ImageOutline from './image-outline.svg'
import ImagesOutline from './images-outline.svg'
import InfoOutline from './info-outline.svg'
import Info from './info.svg'
import Information from './information.svg'
import InformationOutline from './information-outline.svg'
import InstagramLogo from './instagram-logo.svg'
import InstagramRound from './instagram-round.svg'
import KrisFlyerOutline from './krisflyer-outline.svg'
import Lightning from './lightning.svg'
import Link from './link.svg'
import LinkedinRound from './linkedin-round.svg'
import List from './list.svg'
// import Login from './log-in.svg'
import MargnifyingGlass from './magnifying-glass.svg'
import MapPinLine from './map-pin-line.svg'
import MapPin from './map-pin.svg'
import MapPinFilled from './map-pin-filled.svg'
import MapTrifold from './map-trifold.svg'
// import MegaphoneSimple from './megaphone-simple.svg'
// import MinusCircle from './minus-circle.svg'
import Minus from './minus.svg'
// import PaperclipOutline from './paperclip-outline.svg'
import NewsPaper from './newspaper.svg'
import Parachute from './parachute.svg'
import Pelago from './pelago.svg'
import PencilSimple from './pencil-simple.svg'
import CircleWeavyFilledPercent from './circle-wavy-filled-percent.svg'
import CircleWeavyFilledPlane from './circle-wavy-filled-plane.svg'
import Phone from './phone.svg'
import PlusCircle from './plus-circle.svg'
import Plus from './plus.svg'
import Processing from './processing.svg'
import Question from './question.svg'
// import Reset from './reset.svg'
import RocketLaunch from './rocket-launch.svg'
// import RewardOutline from './reward-outline.svg'
// import SecurityOutline from './security-outline.svg'
import Settings from './settings.svg'
// import ShoppingCart from './shopping-cart.svg'
import Share from './share.svg'
import ShareNetwork from './share-network.svg'
import ShootingStar from './shooting-star.svg'
import Signout from './sign-out.svg'
import ShieldCheck from './shield-check.svg'
import ShieldWarning from './shield-warning.svg'
import Sliders from './sliders.svg'
// import SortAscending from './sort-ascending.svg'
import Sparkle from './sparkle.svg'
import SparkleFilled from './sparkle-filled.svg'
// import Star from './star.svg'
import Success from './success.svg'
import Sun from './sun.svg'
import Tag from './tag.svg'
import TagFilled from './tag-filled.svg'
// import Target from './target.svg'
// import ThumbsDown from './thumbs-down.svg'
import ThumbsUp from './thumbs-up.svg'
import Ticket from './ticket.svg'
import TikTokRound from './tiktok-round.svg'
import TrainSimple from './train-simple.svg'
import Translate from './translate.svg'
import TranslateFilled from './translate-filled.svg'
// import TransportationOutline from './transportation-outline.svg'
import Trash from './trash.svg'
import TrashSimple from './trash-simple.svg'
import Tree from './tree.svg'
import TrendUp from './trend-up.svg'
import UserCircle from './user-circle.svg'
// import Upload from './upload.svg'
// import UserPlus from './user-plus.svg'
import User from './user.svg'
import Users from './users.svg'
import UserFour from './user-four.svg'
import UserSwitch from './user-switch.svg'
import Warning from './warning.svg'
import WifiHigh from './wifi-high.svg'
// import WarningOutline from './warning-outline.svg'
import XCircle from './x-circle.svg'
import DeleteX from './delete-x.svg'
import HandShake from './handshake.svg'
import CircleWavyPercent from './circle-wavy-percent.svg'

const iconMappings = {
  // 'activity-date-outline': ActivityDateOutline,
  airplane: Airplane,
  'airplane-landing': AirplaneLanding,
  'airplane-tilt': AirplaneTilt,
  // 'arrow-down': ArrowDown,
  'arrow-down-up': ArrowDownUp,
  'arrow-left': ArrowLeft,
  'arrow-right': ArrowRight,
  'arrow-up': ArrowUp,
  // 'arrows-out': ArrowsOut,
  // attractions: Attractions,
  baby: Baby,
  bird: Bird,
  boat: Boat,
  'booking-date-outline': BookingDateOutline,
  // 'bookings-outline': BookingsOutline,
  books: Books,
  briefcase: Briefcase,
  'calendar-cancellation': CalendarCancellation,
  'calendar-cancellation-outline': CalendarCancellationOutline,
  'calendar-check-outline': CalendarCheckOutline,
  'calendar-outline': CalendarOutline,
  cancellation: Cancellation,
  'cancellation-policy-outline': CancellationPolicyOutline,
  car: Car,
  'caret-double-up': CaretDoubleUp,
  'caret-down': CaretDown,
  'caret-down-circle': CaretDownCircle,
  'caret-left': CaretLeft,
  'caret-right': CaretRight,
  'caret-up': CaretUp,
  chats: Chats,
  'chat-smile': ChatSmile,
  'chat-teardrop-text': ChatTearDropText,
  check: Check,
  'check-circle': CheckCircle,
  'check-circle-outline': CheckCircleOutline,
  'check-square-offset': CheckSquareOffset,
  // 'clock-clockwise': ClockClockwise,
  clock: Clock,
  'color-plate': ColorPlate,
  // compass: Compass,
  // copy: Copy,
  'copy-simple': CopySimple,
  // 'covid-19-safe-outline': Covid19SafeOutline,
  'credit-card-outline': CreditCardOutline,
  'currency-circle': CurrencyCircle,
  'currency-converter': CurrencyConverter,
  'circular-progress': CircularProgress,
  'circle-wavy-check': CircleWavyCheck,
  'circle-wavy-percent': CircleWavyPercent,
  'device-mobile': DeviceMobile,
  'dots-three': DotsThree,
  'dots-six': DotsSix,
  'download-simple': DownloadSimple,
  edit: Edit,
  elderly: Elderly,
  eye: Eye,
  'eye-slash': EyeSlash,
  envelope: Envelope,
  // 'face-mask': FaceMask,
  'facebook-round': FacebookRound,
  // 'filter-slider': FilterSlider,
  // 'filter-slider-outline': FilterSliderOutline,
  fire: Fire,
  'flower-lotus': FlowerLotus,
  'fork-knife': ForkKnife,
  fort: Fort,
  // funnel: Funnel,
  gift: Gift,
  gem: Gem,
  globe: Globe,
  'globe-simple': GlobeSimple,
  // headset: Headset,
  'hand-pointing': HandPointing,
  handwaving: HandWaving,
  hash: Hash,
  heart: Heart,
  'heart-fill': HeartFill,
  // home: Home,
  // 'hourglass-medium': HourglassMedium,
  'identification-card-outline': IdentificationCardOutline,
  'image-outline': ImageOutline,
  'images-outline': ImagesOutline,
  'info-outline': InfoOutline,
  'information-outline': InformationOutline,
  information: Information,
  info: Info,
  'instagram-logo': InstagramLogo,
  'instagram-round': InstagramRound,
  'krisflyer-outline': KrisFlyerOutline,
  lightning: Lightning,
  link: Link,
  'linkedin-round': LinkedinRound,
  list: List,
  // 'log-in': Login,
  'magnifying-glass': MargnifyingGlass,
  'map-pin-line': MapPinLine,
  'map-pin': MapPin,
  'map-pin-filled': MapPinFilled,
  'map-trifold': MapTrifold,
  // 'megaphone-simple': MegaphoneSimple,
  // 'minus-circle': MinusCircle,
  minus: Minus,
  // 'paperclip-outline': PaperclipOutline,
  newspaper: NewsPaper,
  parachute: Parachute,
  pelago: Pelago,
  'circle-wavy-filled-percent': CircleWeavyFilledPercent,
  'circle-wavy-filled-plane': CircleWeavyFilledPlane,
  'pencil-simple': PencilSimple,
  phone: Phone,
  plus: Plus,
  'plus-circle': PlusCircle,
  processing: Processing,
  question: Question,
  // reset: Reset,
  // 'reward-outline': RewardOutline,
  'rocket-launch': RocketLaunch,
  // 'security-outline': SecurityOutline,
  settings: Settings,
  'shield-check': ShieldCheck,
  'shield-warning': ShieldWarning,
  'shooting-star': ShootingStar,
  'sign-out': Signout,
  sliders: Sliders,
  share: Share,
  'share-network': ShareNetwork,
  sparkle: Sparkle,
  'sparkle-filled': SparkleFilled,
  // 'shopping-cart': ShoppingCart,
  // 'sort-ascending': SortAscending,
  success: Success,
  sun: Sun,
  // star: Star,
  tag: Tag,
  tagFilled: TagFilled,
  // target: Target,
  // 'thumbs-down': ThumbsDown,
  'thumbs-up': ThumbsUp,
  ticket: Ticket,
  'tiktok-round': TikTokRound,
  'train-simple': TrainSimple,
  translate: Translate,
  'translate-filled': TranslateFilled,
  // 'transportation-outline': TransportationOutline,
  trash: Trash,
  'trash-simple': TrashSimple,
  'trend-up': TrendUp,
  tree: Tree,
  'user-circle': UserCircle,
  // 'user-plus': UserPlus,
  user: User,
  users: Users,
  'user-four': UserFour,
  'user-switch': UserSwitch,
  warning: Warning,
  'wifi-high': WifiHigh,
  // 'warning-outline': WarningOutline,
  'x-circle': XCircle,
  'delete-x': DeleteX,
  handshake: HandShake,
}

export type IconNames = keyof typeof iconMappings

export { iconMappings }
