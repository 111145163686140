import React, { useMemo, useEffect } from 'react'
import ReactDOM from 'react-dom'

import { isServer } from 'lib/utils'

interface PortalProps {
  open: boolean
}

const Portal: React.FC<PortalProps> = ({ open, children }) => {
  const rootElement = useMemo(() => (isServer ? null : document.createElement('div')), [])

  useEffect(() => {
    if (!open || !rootElement) return () => {} // eslint-disable-line

    const bodyElement = document.body
    bodyElement?.appendChild?.(rootElement)
    return () => bodyElement?.removeChild?.(rootElement)
  }, [open, rootElement])

  return open && rootElement ? ReactDOM.createPortal(children, rootElement) : null
}

export { Portal }
