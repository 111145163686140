import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'

import { useGlobalContext } from 'lib/context/global-context'
import { getUtmParams } from 'lib/utils'

const useWhiteLabel = (): [boolean, WhiteLabel | null] => {
  const { query } = useRouter()
  const { globalArgs } = useGlobalContext()
  const [checkWhiteLabel, setCheckWhiteLabel] = useState(false)

  useEffect(() => {
    setCheckWhiteLabel(true)
  }, [setCheckWhiteLabel])

  // return [hasWhiteLabelMatchingUtmSource, whiteLabel]
  return useMemo(() => {
    if (!checkWhiteLabel) return [false, null]

    const utmsFromCookie = getUtmParams() as { source: string }
    const utmSource = query.utm_source || utmsFromCookie?.source
    const hasUtmSource = !!utmSource
    const hasWhiteLabels = !!globalArgs.whitelabel

    if (hasUtmSource && hasWhiteLabels) {
      const whiteLabelCollection = globalArgs.whitelabel || {}
      const utmSourceVal = (Array.isArray(utmSource) ? utmSource[0] : utmSource) || ''
      const whiteLabel = whiteLabelCollection.find(
        (whiteLabelData) => whiteLabelData.partnerId === utmSourceVal
      )

      if (whiteLabel) return [true, whiteLabel]
    }

    return [false, null]
  }, [query, globalArgs.whitelabel, checkWhiteLabel])
}

export { useWhiteLabel }
