import { useMemo, useCallback, useState } from 'react'
import { useRouter } from 'next/router'

import { CountryDestInfo } from 'components/country-dest-filter/types'

import useRouteMatch from 'lib/hooks/useRouteMatch'
import { useDebounce } from 'lib/hooks/useDebounce'

import { buildPath, getDestinationIdFromSlug } from 'lib/utils'
import { useDestinationCountryContext } from 'lib/context/destination-country-context'

import {
  COUNTRY_ROUTE,
  COUNTRY_ROUTE_V1,
  DESTINATION_ROUTE,
  DESTINATION_ROUTE_V1,
} from 'lib/constants/routes'

export const useDestinationSwitcher = (props?: {
  defaultLocaleValues?: Record<string, string>
  debounceValue?: number
  defaultSearchQuery?: string
}) => {
  const { defaultLocaleValues } = props || {}
  const router = useRouter()
  const [searchText, setSearchText] = useState(props?.defaultSearchQuery || '')
  const { destinationId, countryId, productSlug } = router.query
  const countryIdQuery = countryId as string

  const debouncedSearchText = useDebounce(searchText, props?.debounceValue || 0)

  // Check currently routed page
  const isOldDestinationRoute = Boolean(useRouteMatch(DESTINATION_ROUTE))
  const isNewDestinationRoute = Boolean(useRouteMatch(DESTINATION_ROUTE_V1))
  const isDestinationRoute = isOldDestinationRoute || isNewDestinationRoute
  const isOldCountryRoute = Boolean(useRouteMatch(COUNTRY_ROUTE))
  const isNewCountryRoute = Boolean(useRouteMatch(COUNTRY_ROUTE_V1))
  const isCountryRoute = isOldCountryRoute || isNewCountryRoute

  const activeDestinationId = useMemo(() => {
    if (destinationId) return (destinationId as string) || '' // for search route get the active dest id from route param / query param
    /**
     * TODO: Need to remove `getDestinationIdFromSlug` util method and use a solution where `destinationId` is
     * coming from single source. It require a proper fix and testing all pages so can be fixed in separate ticket.
     * Discovered during this fix: https://github.com/V287/pelago-traveller-ssr/pull/151
     */
    if (productSlug) return getDestinationIdFromSlug(productSlug as string) || ''
  }, [destinationId, productSlug])

  const activeCountryId = useMemo(() => {
    return countryIdQuery || '' // for search route get the active country id from route param / query param
  }, [countryIdQuery])

  const {
    countries,
    countryWithDestinations,
    getDestinationInfo: baseGetDestinationInfo,
  } = useDestinationCountryContext()

  const getDestinationInfo = useCallback(
    (destId: string) => {
      return baseGetDestinationInfo(destId, defaultLocaleValues)
    },
    [baseGetDestinationInfo, defaultLocaleValues]
  )

  const getCountryInfo = useCallback(
    (_countryId: string = countryIdQuery) => {
      return countries?.[_countryId]
    },
    [countries, countryIdQuery]
  )

  const goToDestinationRoute = (destinationUri: string, destinationCode: string) => {
    router.push(buildPath(DESTINATION_ROUTE_V1, { destinationId: destinationUri, destinationCode }))
  }

  const goToCountryRoute = (_countryId: string, countryCode: string) => {
    router.push(buildPath(COUNTRY_ROUTE_V1, { countryId: _countryId, countryCode }))
  }

  const filteredCountryDestinations = useMemo(() => {
    if (!debouncedSearchText) return countryWithDestinations
    const _searchText = debouncedSearchText.toLowerCase().trim()

    return countryWithDestinations?.reduce?.(
      (acc: CountryDestInfo[], countryAndDestination: CountryDestInfo) => {
        const { countryName, destinations } = countryAndDestination

        if (countryName.toLowerCase().includes(_searchText)) {
          // show all destination and country if search text matched country name
          acc.push(countryAndDestination)
        } else {
          // show only matched destinations. if no destination macthed, do not show the country too
          const filteredDestinations = destinations?.filter?.(
            ({ destinationName }) => !!destinationName?.toLowerCase()?.includes(_searchText)
          )
          if (filteredDestinations.length) {
            acc.push({ ...countryAndDestination, destinations: filteredDestinations })
          }
        }
        return acc || []
      },
      []
    )
  }, [debouncedSearchText, countryWithDestinations])

  return {
    getDestinationInfo,
    activeDestinationId,
    destinationInfo: filteredCountryDestinations,
    goToDestinationRoute,
    getCountryInfo,
    activeCountryId,
    goToCountryRoute,
    isCountryRoute,
    isDestinationRoute,
    searchText,
    debouncedSearchText,
    searchCountryDestination: setSearchText,
  }
}
