import { useEffect } from 'react'

import useTranslation from 'lib/hooks/useTranslation'

import { useGlobalContext } from 'lib/context/global-context'

import { EVENTS } from 'lib/constants/events'

import MobileAppQrCode from 'brand-assets/illustrations/mobile-app-qrcode.svg'

import commonStyles from '../styles.module.scss'
import s from './styles.module.scss'

type DownloadAppDropdownProps = {
  trackEvent: TrackEventType
  isOpen: boolean
}

const DownloadAppDropdown = ({ trackEvent, isOpen }: DownloadAppDropdownProps) => {
  const {
    globalArgs: { appTouchpointsData },
  } = useGlobalContext()
  const { header } = appTouchpointsData
  const { t } = useTranslation('common')

  useEffect(() => {
    if (isOpen) {
      trackEvent({
        attributeId: EVENTS.DESKTOP_HEADER_MOBILE_APP,
        attributeType: EVENTS.ATTRIBUTES_TYPE.BANNER,
        eventType: EVENTS.TYPE.EXPOSURE,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, trackEvent])

  return (
    <div className={s.downloadAppDropdown}>
      <h4 className={s.title} dangerouslySetInnerHTML={{ __html: header }} />
      <div className={commonStyles.qrCode}>
        <MobileAppQrCode />
        <span>{t('t.scanToDownload')}</span>
      </div>
    </div>
  )
}

export { DownloadAppDropdown }
