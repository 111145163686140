import React, { FC } from 'react'
import cn from 'classnames'

import { Icon } from 'ui/icon'

import { Link } from 'components/link'

import useTranslation from 'lib/hooks/useTranslation'

import { useAppData } from 'lib/context/app-data-context'

import { EVENTS } from 'lib/constants/events'

import s from './styles.module.scss'

interface BreadcrumbsProps {
  levels?: Breadcrumb[]
  className?: string
  loading?: boolean
  noBackground?: boolean
  size?: 'small' | 'medium'
}

const Breadcrumbs: FC<BreadcrumbsProps> = ({
  levels = [],
  className,
  loading,
  noBackground,
  size = 'small',
}) => {
  const { t } = useTranslation()
  const { trackEvent } = useAppData()

  if (loading) {
    return <div className={cn(s.breadcrumbs, className, { [s.noBackground]: noBackground })} />
  }

  return (
    <div className={cn(s.breadcrumbs, className, { [s.noBackground]: noBackground })}>
      <div className={s.breadcrumbsInner}>
        {levels.map(({ type, link, label, translationLabel }: Breadcrumb, index) => {
          let _label = label
          if (translationLabel) {
            _label = t(translationLabel.key, { ns: translationLabel?.ns || 'common' })
          }
          const key = `${_label}_${index}`

          if (index === levels.length - 1) {
            return (
              <span key={key} className={cn(s.breadcrumbsLink, s[`size-${size}`])}>
                {_label}
              </span>
            )
          }

          return (
            <React.Fragment key={key}>
              <Link key={key} href={link || ''}>
                <a
                  className={cn(s.breadcrumbsLink, s[`size-${size}`])}
                  onClick={() => {
                    trackEvent.current?.({
                      attributeId: EVENTS.BREADCRUMBS,
                      attributeType: EVENTS.ATTRIBUTES_TYPE.LINK,
                      levelType: type,
                      levelId: link,
                      levelName: _label,
                    })
                  }}
                >
                  {_label}
                </a>
              </Link>
              <Icon name="caret-right" className={cn(s.separator, s[`size-${size}`])} />
            </React.Fragment>
          )
        })}
      </div>
    </div>
  )
}

export default Breadcrumbs
