import React from 'react'

import s from './styles.module.scss'

type AspectRatioProps = {
  ratio: number
  children: React.ReactNode
  width?: number | string
}

const AspectRatio = ({ ratio, children, width = '100%' }: AspectRatioProps) => {
  const styles: any = { '--aspect-ratio': 1 / ratio, width }

  return (
    <div className={s.ratioBox} style={styles}>
      {children}
    </div>
  )
}

export { AspectRatio }
