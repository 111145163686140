import React, { useCallback, useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'

import { Button } from 'ui/button'
import { Image } from 'ui/image'

import { Modal } from 'components/responsive-modal'
import { Link } from 'components/link'
import { RichText } from 'components/rich-content/RichText'

import { useToggle } from 'lib/hooks/useToggle'
import useCustomTranslation from 'lib/hooks/useTranslation'
import useAuth from 'lib/hooks/useAuth'

import { useAppData } from 'lib/context/app-data-context'
import { useGlobalContext } from 'lib/context/global-context'

import { EVENTS } from 'lib/constants/events'
import { CAMPAIGN_TYPE, LOCAL_STORAGE_SEASONAL_CAMPAIGN } from 'lib/constants'
import { LOGIN_ROUTE } from 'lib/constants/routes'

import s from './styles.module.scss'

const CampaignModal = () => {
  const [isOpen, { onClose, setIsOpen }] = useToggle()
  const { trackEvent, campaignData } = useAppData()
  const { isMobileView } = useGlobalContext()
  const { isLoggedIn } = useAuth()
  const { pathname: pathName } = useRouter()
  const { t } = useCustomTranslation('common')

  const ctaLink = useMemo(() => {
    if (!campaignData?.content?.button?.url || !isOpen) return null
    if (!isLoggedIn) return `${LOGIN_ROUTE}?redirectUrl=${campaignData?.content?.button?.url}`
    return campaignData?.content?.button?.url
  }, [isLoggedIn, isOpen, campaignData])

  useEffect(() => {
    if (campaignData?.campaignType === CAMPAIGN_TYPE.SEASONAL_CAMPAIGN) {
      const now = new Date()
      const today = now.toISOString().split('T')[0]
      // Check when the popup was last shown
      const lastShownDate = localStorage.getItem(LOCAL_STORAGE_SEASONAL_CAMPAIGN)
      if (lastShownDate === today) return

      setIsOpen(true)
      localStorage.setItem(LOCAL_STORAGE_SEASONAL_CAMPAIGN, today)
    }
  }, [campaignData, setIsOpen, pathName])

  useEffect(() => {
    if (!isOpen) return

    trackEvent?.current?.({
      attributeId: EVENTS.SEASONAL_CAMPAIGN.MODAL,
      attributeType: EVENTS.ATTRIBUTES_TYPE.POPUP,
      eventType: EVENTS.TYPE.EXPOSURE,
      attributeValue: {
        campaignId: campaignData?.campaignId,
      },
    })
  }, [isOpen, trackEvent, campaignData?.campaignId])

  const onModalClose = useCallback(() => {
    trackEvent?.current?.({
      attributeId: EVENTS.SEASONAL_CAMPAIGN.MODAL_CLOSE,
      attributeType: EVENTS.ATTRIBUTES_TYPE.BUTTON,
      eventType: EVENTS.TYPE.CLICK,
      attributeValue: {
        campaignId: campaignData?.campaignId,
      },
    })
    onClose()
  }, [onClose, trackEvent, campaignData?.campaignId])

  return (
    <Modal
      open={isOpen}
      topOffset={isMobileView ? '150px' : '200px'}
      hideCloseButton
      onEscKeyPress={onModalClose}
    >
      <div className={s.campaignModal}>
        <div className={s.title}>
          {t('emoji.party')} {campaignData?.content?.title}
        </div>
        <div className={s.description}>
          <RichText text={campaignData?.content?.description} />
        </div>
        <Image
          src={campaignData?.mediaData || {}}
          size="medium"
          alt={`campaign-${campaignData?.campaignId}`}
          aspectRatio={1.9}
        />
        <div className={s.closeButton}>
          <Button
            iconName="delete-x"
            shape="circle"
            variant="tertiary-outline"
            size={isMobileView ? 'medium' : 'large'}
            onClick={onModalClose}
          ></Button>
        </div>
        {campaignData?.content?.button && (
          <Link href={ctaLink} passHref>
            <Button
              variant="secondary"
              className="mt-1"
              onClick={() => {
                trackEvent?.current?.({
                  attributeId: EVENTS.SEASONAL_CAMPAIGN.MODAL,
                  attributeType: EVENTS.ATTRIBUTES_TYPE.BUTTON,
                  attributeValue: {
                    campaignId: campaignData?.campaignId,
                    link: campaignData?.content?.button?.url,
                  },
                })
                onClose()
              }}
            >
              {campaignData?.content?.button?.label}
            </Button>
          </Link>
        )}
      </div>
    </Modal>
  )
}

export { CampaignModal }
