import React, { FC, useRef, useEffect } from 'react'
import cn from 'classnames'
import { Icon } from 'ui/icon'
import s from './styles.module.scss'

const Checkbox: FC<CheckboxProps> = ({
  children,
  className,
  labelClassName,
  isIndeterminate,
  withLabel = true,
  size = 'medium',
  getRef,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const sizeMapping = { medium: 22, small: 14 } // 1px top & 1px bottom border hence it is 22 instead of 24
  let _size = size
  if (typeof size === 'string') {
    _size = sizeMapping[size]
  }

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.indeterminate = !!isIndeterminate
    }
  }, [isIndeterminate])

  return (
    <label className={cn(s.cb, 'cursor-pointer', s[`cb-${size}`], className)}>
      <input
        ref={(ref) => {
          inputRef.current = ref
          if (getRef) getRef(ref)
        }}
        type="checkbox"
        className="visually-hidden"
        {...props}
      />
      {withLabel && (
        <div className={s.cbIconContainer}>
          <Icon name={isIndeterminate ? 'minus' : 'check'} className={s.cbIcon} size={_size} />
        </div>
      )}
      <span className={cn({ [s.cbLabel]: withLabel }, labelClassName)}>{children}</span>
    </label>
  )
}

export { Checkbox }
