import { useEffect, useMemo, useState } from 'react'

import { useDestinationByRegionContext } from 'lib/context/destination-by-region-context'

import { EVENTS } from 'lib/constants/events'

import useCustomTranslation from './useTranslation'

type UseRegionWithDestinationProps = {
  componentEventId?: string
  trackEvent?: TrackEventType
  regionName?: string
}

const TOP_TRENDING_DESTINATIONS = 'topTrendingDestinations'

export const useRegionWithDestination = ({
  componentEventId,
  regionName,
  trackEvent,
}: UseRegionWithDestinationProps) => {
  const { t } = useCustomTranslation('common')
  const { regions, data: destinationsByRegion } = useDestinationByRegionContext()

  const allRegions = useMemo(() => {
    if (!regions) {
      return []
    }

    return [
      {
        regionId: TOP_TRENDING_DESTINATIONS,
        regionName: regionName || t('t.topTen', { ns: 'common' }),
        regionCode: 0,
        continentCode: TOP_TRENDING_DESTINATIONS,
      } as Region,
      ...regions,
    ]
  }, [regions, regionName, t])

  const [selectedRegionId, setSelectedRegionId] = useState(allRegions?.[0]?.regionId || '')

  useEffect(() => {
    if (allRegions.length > 0) {
      setSelectedRegionId(allRegions[0].regionId)
    }
  }, [allRegions])

  const onSelectRegion = (regionId: string) => () => {
    trackEvent?.({
      attributeId: componentEventId,
      attributeType: EVENTS.ATTRIBUTES_TYPE.CHIPS,
      eventType: EVENTS.TYPE.CLICK,
      attributeValue: {
        id: regionId,
      },
    })
    setSelectedRegionId(regionId)
  }

  const destinations = useMemo(() => {
    if (selectedRegionId === TOP_TRENDING_DESTINATIONS) {
      return destinationsByRegion?.topTrendingDestinations.destinations
    }

    const foundRegionGroup = destinationsByRegion?.groupedByRegion.find(
      (regionGroup) => regionGroup.region.regionId === selectedRegionId
    )

    return foundRegionGroup?.destinationList?.destinations || []
  }, [selectedRegionId, destinationsByRegion])

  return {
    destinations,
    selectedRegionId,
    regions: allRegions,
    onSelectRegion,
  }
}
