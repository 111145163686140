import React, { createContext, useState, useMemo, useRef } from 'react'

type Summary = { title: string; topics: string[] }

interface IBlogDataContext {
  summary: Summary
  summaryListRefs?: React.RefObject<HTMLHeadingElement[]> | null
  setSummary: (summary: Summary) => void
}

const BlogDataContext = createContext<IBlogDataContext>({
  summary: { title: '', topics: [] },
  summaryListRefs: null,
  setSummary: () => undefined,
})

const BlogDataProvider = (props: any) => {
  const [isSummaryDrawerOpen, setSummaryDrawerVisibility] = useState(false)
  const [summary, setSummary] = useState<Summary>({ title: '', topics: [] })
  const summaryListRefs = useRef<HTMLHeadingElement[] | null>([])

  const value = useMemo<IBlogDataContext>(
    () => ({
      isSummaryDrawerOpen,
      setSummaryDrawerVisibility,
      summary,
      setSummary,
      summaryListRefs,
    }),
    [isSummaryDrawerOpen, setSummaryDrawerVisibility, summary, setSummary]
  )

  return <BlogDataContext.Provider value={value} {...props} />
}

function useBlogData() {
  const context = React.useContext(BlogDataContext)
  if (context === undefined) {
    throw new Error('useBlogData must be used within a BlogDataProvider')
  }
  return context
}

export { BlogDataProvider, useBlogData }
