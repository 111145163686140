import { gql } from '@apollo/client'

import { PelagoError } from 'gql/fragments'

export const authorFields = `
  sys {
    id
  }
  __typename
  name
  slug
  description
  avatarUrls
  avatar {
    url
    width
    height
  }
`

export const taxonomyFields = `
  sys {
    id
  }
  __typename
  name
  slug
  description
  type
  parent {
    sys {
      id
    }
  }
`

export const bodyEmbedFields = `
  sys {
    id
  }
  __typename
  ... on Product {
    slug
    productId
  }
  ... on Button {
    buttonText
    link
  }
  ... on SocialEmbed {
    code
  }
  ... on OffersAndPromos {
    offerPromoTitle
    details {
      json
      links {
        entries {
          __typename
        }
        assets {
          __typename
        }
      }
    }
  }
`
export const imageFields = `
  sys {
    id
  }
  __typename
  title
  description
  url
  width
  height
  contentType
`

export const postDetailFields = `
  sys {
    id
    publishedAt
    firstPublishedAt
  }
  __typename
  title
  slug
  averageReadingTime
  featuredImage {
    ${imageFields}
  }
  author {
    ${authorFields}
  }
  type
  taxonomyCollection {
    items {
        ${taxonomyFields}
    }
  }
  seoTitle
  seoDescription
  noIndex
  socialShareTitle
  socialShareDescription
  body {
    json
    links {
        entries {
            block {
                ${bodyEmbedFields}
            }
            inline {
                ${bodyEmbedFields}
            }
            hyperlink {
                ${bodyEmbedFields}
            }
        }
        assets {
            block {
                ${imageFields}
            }
            hyperlink {
                sys {
                    id
                }
                title
                description
                url
                width
                height
            }
        
        }
    }
  }
`

export const postOverviewFields = `
  sys {
    id
    publishedAt
    firstPublishedAt
  }
  __typename
  title
  slug
  averageReadingTime
  featuredImage {
    ${imageFields}
  }
`

export const GET_POST_DETAILS_BY_SLUG_QUERY = {
  query: gql`
    query postCollection($where: PostFilter, $preview: Boolean) {
        postCollection(where: $where, preview: $preview, limit: 1) {
        ...on PostCollection {
          items {
            ${postDetailFields}
          }
        }
      }
    }
  `,
  queryName: 'postCollection',
}

export const RECENT_FIVE_POSTS_SLUG_QUERY = {
  query: gql`
    query postCollection($limit: Int, $order: [PostOrder]) {
      postCollection(limit: $limit, order: $order) {
        ... on PostCollection {
          items {
            slug
          }
        }
      }
    }
  `,
  queryName: 'postCollection',
}

export const GET_SYS_ID_BY_SLUG_QUERY = {
  query: gql`
    query postIdCollection($where: PostFilter, $preview: Boolean) {
      postCollection(where: $where, preview: $preview, limit: 1) {
        ... on PostCollection {
          items {
            __typename
            sys {
              id
            }
          }
        }
      }
    }
  `,
  queryName: 'postCollection',
}

const GET_POPULAR_POST_QUERY_BODY = `
  popularPostCollection(where: $popularPostWhere, limit: $popularPostLimit) {
    ... on PopularPostCollection {
      items {
        __typename
        postsCollection(limit: $popularPostCollectionLimit) {
          items {
            ${postOverviewFields}
            taxonomyCollection {
              items {
                  ${taxonomyFields}
              }
            }
          }
        }
        sys {
          id
        }
      }
    }
  }
`

const GET_POSTS_OVERVIEW_QUERY_BODY = `
  postCollection(skip: $postOverviewSkip, limit: $postOverviewLimit, order: $postOverviewOrder) {
    ... on PostCollection {
      total
      items {
        ${postOverviewFields}
        taxonomyCollection @skip(if: $skipTaxonomyData){
          items {
              ${taxonomyFields}
          }
        }
      }
    }
  }
`

const GET_POPULAR_DESTINATION_QUERY_BODY = `
  popularDestinationsCollection(limit: 6, order: rank_ASC) {
    ... on PopularDestinationsCollection {
      items {
        rank
        destination {
          slug
          name
          featuredImage {
            ${imageFields}
          }
        }
      }
    }
  }
`

const GET_DESTINATIONS_QUERY_BODY = `
  destinations: taxonomyCollection(where: {type: "location"}, order: name_ASC) {
    ... on TaxonomyCollection {
      items {
        __typename
        name
        slug
      }
    }
  }
`

export const GET_POSTS_OVERVIEW_QUERY = {
  query: gql`
    query postCollection($skipTaxonomyData: Boolean!, $postOverviewSkip: Int, $postOverviewLimit: Int, $postOverviewOrder: [PostOrder]) {
      ${GET_POSTS_OVERVIEW_QUERY_BODY}
    }
  `,
  queryName: 'postCollection',
}

export const GET_PRODUCT_PRICE_AND_CURRENCY = {
  query: gql`
    query recentlyViewed($productIds: [String]!, $pageSize: Int) {
      recentlyViewed(productIds: $productIds, pageSize: $pageSize) {
        ... on ESProductCards {
          productCount
          products {
            currency
            priceRangeFrom
            productId
          }
        }
        ${PelagoError}
      }
    }
  `,
  queryName: 'recentlyViewed',
}

export const HOME_PAGE_QUERY = {
  query: gql`
    query homePage($skipTaxonomyData: Boolean!, $popularPostWhere: PopularPostFilter, $popularPostLimit: Int, $popularPostCollectionLimit: Int, $postOverviewSkip: Int, $postOverviewLimit: Int, $postOverviewOrder: [PostOrder]) {
      ${GET_POPULAR_POST_QUERY_BODY}
      ${GET_POSTS_OVERVIEW_QUERY_BODY}
      ${GET_POPULAR_DESTINATION_QUERY_BODY}
      ${GET_DESTINATIONS_QUERY_BODY}
    }
  `,
  queryName: {
    POPULAR_POSTS: 'popularPostCollection',
    LATEST_POSTS: 'postCollection',
    POPULAR_DESTINATIONS: 'popularDestinationsCollection',
    DESTINATIONS: 'destinations',
  },
}

const GET_POSTS_OVERVIEW_BY_DESTINATION_QUERY_BODY = `
  taxonomyCollection(where: $destination, limit: 1) {
    items {
      linkedFrom {
        ${GET_POSTS_OVERVIEW_QUERY_BODY}
      }
      featuredImage {
        ${imageFields}
      }
    }
  }
`

export const GET_POSTS_BY_DESTINATION_QUERY = {
  query: gql`
    query taxonomyCollection($destination: TaxonomyFilter, $skipTaxonomyData: Boolean!, $postOverviewSkip: Int, $postOverviewLimit: Int, $postOverviewOrder: [TaxonomyLinkingCollectionsPostCollectionOrder]) {
      ${GET_POSTS_OVERVIEW_BY_DESTINATION_QUERY_BODY}
    }
  `,
  queryName: 'taxonomyCollection',
}
export const ARTICLE_DESTINATION_PAGE_QUERY = {
  query: gql`
    query destinationPage($destination: TaxonomyFilter, $skipTaxonomyData: Boolean!, $postOverviewSkip: Int, $postOverviewLimit: Int, $postOverviewOrder: [TaxonomyLinkingCollectionsPostCollectionOrder]) {
      ${GET_POSTS_OVERVIEW_BY_DESTINATION_QUERY_BODY}
      ${GET_POPULAR_DESTINATION_QUERY_BODY}
      ${GET_DESTINATIONS_QUERY_BODY}
    }
  `,
  queryName: {
    DESTINATION_POST: 'taxonomyCollection',
    POPULAR_DESTINATIONS: 'popularDestinationsCollection',
    DESTINATIONS: 'destinations',
  },
}
