import Link, { LinkProps } from 'next/link'
import { PropsWithChildren } from 'react'

// This component is wrapper around next/link component as we are doing
// prefetch by default value false which is opposite of nextjs default value true
// nextjs not offering to change default value
const _Link = (props: PropsWithChildren<LinkProps>) => {
  return <Link {...props} prefetch={props.prefetch ?? false} />
}

export { _Link as Link }
