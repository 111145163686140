import { useCallback, useEffect } from 'react'
import { useRouter } from 'next/router'
import debounce from 'lodash-es/debounce'

import { getBrowserCookie, setBrowserCookie, createActivitySession } from 'lib/utils'

import { COOKIES_DS_REF } from 'lib/constants'

function useEventSessionAndRef() {
  const { query } = useRouter()

  // ref key - used to track page reference within pelago eco system
  const manageRef = useCallback(() => {
    const existingRefId = getBrowserCookie(COOKIES_DS_REF)
    const ref = query.ref || existingRefId
    if (!ref) return
    else setBrowserCookie(COOKIES_DS_REF, ref as string, 30 * 60) // 30 min TTL
  }, [query.ref])

  useEffect(() => {
    if (!query.ref) return
    manageRef()
  }, [manageRef, query])

  const rolloverActivitySession = debounce(() => {
    createActivitySession()
    manageRef()
  }, 500)

  useEffect(() => {
    createActivitySession() // creates session before track event trigger bcz this hook put in app at relavant position
    manageRef() // track page reference within pelago eco system
    document?.body?.addEventListener('mousedown', rolloverActivitySession)
    document?.body?.addEventListener('mousemove', rolloverActivitySession)
    document?.body?.addEventListener('keydown', rolloverActivitySession)
    window?.addEventListener('scroll', rolloverActivitySession)
    window?.addEventListener('touchstart', rolloverActivitySession)

    return () => {
      document?.body?.removeEventListener('mousedown', rolloverActivitySession)
      document?.body?.removeEventListener('mousemove', rolloverActivitySession)
      document?.body?.removeEventListener('keydown', rolloverActivitySession)
      window?.removeEventListener('scroll', rolloverActivitySession)
      window?.removeEventListener('touchstart', rolloverActivitySession)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useEventSessionAndRef
