import React from 'react'

import { AuthContext } from 'lib/context/auth-context'

function useAuth() {
  const context = React.useContext(AuthContext)

  if (context === undefined) {
    throw new Error(`${AuthContext.displayName} is missing`)
  }

  return context
}

export default useAuth
