import { useCallback, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'

import useAuth from 'lib/hooks/useAuth'
import useUserPreference from 'lib/hooks/useUserPreference'
import useTranslation from 'lib/hooks/useTranslation'

import { useGlobalContext } from 'lib/context/global-context'
import { buildPath, buildSearchParams, setCookieCurrency, setCookieUserLocale } from 'lib/utils'

import { EVENTS } from 'lib/constants/events'

const EN = 'en'

// en-ID => en, id-ID => ID
export const getSelectedLangId = (locale?: string) => {
  if (!locale) return ''
  if (locale.includes(EN)) return EN
  return locale
}

const useLangAndCurrency = ({
  trackEvent,
  currLocaleId,
  currCurrencyId,
}: {
  trackEvent: TrackEventType
  currLocaleId: string
  currCurrencyId: string
}) => {
  const router = useRouter()
  const { t } = useTranslation()

  const [selectedOption, setSelectedOption] = useState<{ langId: string; currencyId: string }>(() => ({
    langId: getSelectedLangId(currLocaleId),
    currencyId: currCurrencyId,
  }))

  const { user } = useAuth()
  const { updateUserPreference, getDerivedLocale } = useUserPreference()
  const { globalArgs: { currencies, locales } = {}, currentCurrency } = useGlobalContext()

  const [langDrawerActive, setLangDrawerActive] = useState(false)
  const [currencyDrawerActive, setCurrencyDrawerActive] = useState(false)

  useEffect(() => {
    setSelectedOption((curr) => ({
      ...curr,
      langId: getSelectedLangId(currLocaleId),
      currencyId: currCurrencyId,
    }))
  }, [currCurrencyId, currLocaleId])

  const setSelectedLangId = (langId: string) => {
    setSelectedOption((curr) => ({ ...curr, langId: getSelectedLangId(langId) }))
  }

  const setSelectedCurrencyId = (currencyId: string) => {
    setSelectedOption((curr) => ({ ...curr, currencyId: currencyId }))
  }

  const openCurrencyDrawer = useCallback(() => {
    setCurrencyDrawerActive(true)
  }, [])

  const closeCurrencyDrawer = useCallback(() => {
    setCurrencyDrawerActive(false)
  }, [])

  const openLangDrawer = useCallback(() => {
    setLangDrawerActive(true)
  }, [])

  const closeLangDrawer = useCallback(() => {
    setLangDrawerActive(false)
  }, [])

  const handleCurrencySwitch = async (currencyId: string, isThisIsrPage?: boolean) => {
    trackEvent?.({
      attributeId: EVENTS.CURRENCY,
      attributeType: EVENTS.ATTRIBUTES_TYPE.OPTION,
      attributeValue: { currencyId },
    })
    setSelectedCurrencyId(currencyId)

    if (user && user?.currency !== currencyId) await updateUserPreference({ currency: currencyId })
    if (currencyId === currentCurrency) {
      closeCurrencyDrawer()
      return
    }
    setCookieCurrency(currencyId)
    // Remove `priceRangeFrom` queryParam from URL before reloading
    const [pathNameWithoutQuery] = router.asPath.split('?')
    const { priceRangeFrom, ...restQueryParams } = router.query

    if (priceRangeFrom) {
      window.location.href = `${origin}${pathNameWithoutQuery}${buildSearchParams(restQueryParams)}`
    } else {
      if (isThisIsrPage) {
        // ISR page reload won't hard refresh the page actually as it is cached version
        // to hard refresh, we have to replace the page with the same URL and use currency as query params so request goes to server
        router.replace(buildPath(pathNameWithoutQuery, {}, { cr: currencyId }))
        closeCurrencyDrawer()
      } else window.location.reload()
    }
  }

  const getSelectedLangLabel = useCallback(
    (langId?: string) => {
      if (!langId) return t('t.select', { ns: 'common' })

      const locale = getDerivedLocale(langId)
      const languageName = locales?.find((loc) => loc.localeLabel === locale)?.languageName
      return languageName || t('t.select', { ns: 'common' })
    },
    [getDerivedLocale, locales, t]
  )

  const getCurrencySymbol = useCallback(
    (currencyId?: string) => {
      if (!currencies) return ''
      const currencyInfo = currencies.find((currency) => currency.currencyId === currencyId)

      if (currencyInfo) {
        return currencyInfo.currencySymbol
      }
      return ''
    },
    [currencies]
  )

  const handleLanguageSwitch = async (langOrLocaleId: string) => {
    setSelectedLangId(langOrLocaleId)
    const newLocale = getDerivedLocale(langOrLocaleId)

    trackEvent?.({
      attributeId: EVENTS.LANGUAGE,
      attributeType: EVENTS.ATTRIBUTES_TYPE.OPTION,
      attributeValue: { locale: newLocale },
    })
    if (user && user?.locale !== newLocale) await updateUserPreference({ locale: newLocale })
    if (router.locale === newLocale) {
      closeLangDrawer()
      return
    }
    setCookieUserLocale(newLocale)
    window.location.replace(`/${newLocale}${router.asPath}`)
  }

  const languageOptions = useMemo(() => {
    const uniqueLanguages = (locales || []).reduce(
      (acc: Record<string, string>, { languageLabel, languageName, localeLabel }) => {
        // dont display all english versions. just one version which has lang id of en
        const id = languageLabel === EN ? languageLabel : localeLabel

        if (!acc[id]) acc[id] = languageName
        return acc
      },
      {}
    )

    return Object.keys(uniqueLanguages).length
      ? Object.entries(uniqueLanguages).map(([id, name]) => ({
          id,
          name,
          label: name,
        }))
      : []
  }, [locales])

  const currencyOptions = useMemo(
    () =>
      currencies?.map((currecy) => ({
        id: currecy.currencyId,
        label: currecy.currencyId,
        name: currecy.currencyName,
      })) || [],
    [currencies]
  )

  const showLanguageSelector = languageOptions?.length > 1

  const langCurrencyState = useMemo(
    () => ({
      langId: selectedOption.langId || '',
      langLabel: getSelectedLangLabel(selectedOption.langId),
      currencyId: selectedOption.currencyId || '',
      currencySymbol: getCurrencySymbol(selectedOption.currencyId),
    }),
    [getCurrencySymbol, getSelectedLangLabel, selectedOption.currencyId, selectedOption.langId]
  )

  return {
    showLanguageSelector,
    langDrawerActive,
    currencyDrawerActive,
    languageOptions,
    currencyOptions,
    openLangDrawer,
    closeLangDrawer,
    openCurrencyDrawer,
    closeCurrencyDrawer,
    handleCurrencySwitch,
    handleLanguageSwitch,
    langCurrencyState,
  }
}

export { useLangAndCurrency }
