import { useCallback, useEffect, useRef, useState } from 'react'

import { useGlobalContext } from 'lib/context/global-context'

const SCROLL_OFFSET = 200

type Props = {
  defaultIsEndPosition?: boolean
  detectOnMobile?: boolean
}

const POSITION_OFFSET = 1 // sometimes if the offset width has decimal value, it may not add up correctly, this is to mitigate that issue

export function useHorizontalScroll(props?: Props) {
  const { defaultIsEndPosition = true, detectOnMobile = false } = props || {}
  const ref = useRef<HTMLDivElement | null>(null)

  const { isMobileView } = useGlobalContext()
  const [scrollPosition, setScrollPosition] = useState<number>(0)

  const scrollToRight = () => {
    const parent = ref.current

    parent?.scrollTo({ left: parent.offsetWidth + parent.scrollLeft - SCROLL_OFFSET, behavior: 'smooth' })
  }

  const scrollToLeft = () => {
    const parent = ref.current

    parent?.scrollTo({ left: parent.scrollLeft - parent.offsetWidth + SCROLL_OFFSET, behavior: 'smooth' })
  }

  const onScroll = useCallback(() => {
    if (!ref.current || (isMobileView && !detectOnMobile)) return

    setScrollPosition(Math.ceil(ref.current.scrollLeft))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, isMobileView, detectOnMobile])

  useEffect(() => {
    const parent = ref.current
    if (!parent) return

    // Safari doesn't support scrollend event, use scroll instead
    parent?.addEventListener('scroll', onScroll)
    return () => parent.removeEventListener('scroll', onScroll)
  }, [onScroll])

  const offsetWidth = ref.current?.offsetWidth || 0
  const scrollWidth = ref.current?.scrollWidth || 0

  return {
    ref,
    scrollPosition,
    isStartPosition: scrollPosition === 0,
    isEndPosition: offsetWidth
      ? scrollPosition + POSITION_OFFSET >= scrollWidth - offsetWidth
      : defaultIsEndPosition,
    scrollToLeft,
    scrollToRight,
  }
}
