import { gql } from '@apollo/client'
import { PelagoError } from '../fragments'

export const CREATE_WISHLIST_MUTATION = {
  mutation: gql`
    mutation createWishlist($wishlistName: String!, $productId: String, $isProductReset: Boolean) {
      createWishlist(details: { wishlistName: $wishlistName, productId: $productId, isProductReset: $isProductReset  }) {
        ... on Wishlist {
          wishlistName
          wishlistId
          thumbnail
        }
        ${PelagoError}
      }
    }
  `,
  mutationName: 'createWishlist',
}

export const SAVE_TO_WISHLIST_MUTATION = {
  mutation: gql`
    mutation saveItemToWishlist($productId: String!, $wishlistId: String) {
      saveItemToWishlist(details: { productId: $productId, wishlistId: $wishlistId }) {
        ... on SaveItemToWishlistAction {
          toCreate
        }
        ... on Wishlist {
          wishlistId
          wishlistName
          itemsCount
          isProductSaved
          thumbnail
          isDeleted
        }
        ${PelagoError}
      }
    }
  `,
  mutationName: 'saveItemToWishlist',
}

export const UPDATE_ITEM_IN_WISHLISTS_MUTATION = {
  mutation: gql`
    mutation updateItemInWishlists($productId: String!, $wishlistIds: [String]!) {
      updateItemInWishlists(details: { productId: $productId, wishlistIds: $wishlistIds }) {
        ... on UpdateItemInWishlistsOutput {
          addedWishlists {
            wishlistId
            wishlistName
          }
          removedWishlists {
            wishlistId
            wishlistName
          }
        }
        ${PelagoError}
      }
    }
  `,
  mutationName: 'updateItemInWishlists',
}

export const EDIT_WISHLIST_MUTATION = {
  mutation: gql`
    mutation editWishlist($wishlistId: String!, $wishlistName: String, $isDeleted: Boolean) {
      editWishlist(
        details: {
            wishlistId: $wishlistId
            wishlistName: $wishlistName
            isDeleted: $isDeleted
        }
      ) {
        ... on Wishlist {
            wishlistId
            wishlistName
            itemsCount
            isProductSaved
            thumbnail
            isDeleted
        }
        ${PelagoError}
      }
    }
  `,
  mutationName: 'editWishlist',
}

export const REMOVE_ITEM_FROM_WISHLIST_MUTATION = {
  mutation: gql`
  mutation removeItemFromWishlist($productId: String!, $wishlistId: String!) {
    removeItemFromWishlist(
        details: { productId: $productId, wishlistId: $wishlistId }
    ) {
      ... on Wishlist {
        wishlistId
        wishlistName
        itemsCount
        isProductSaved
        thumbnail
        isDeleted
    }
        ${PelagoError}
    }
}
  `,
  mutationName: 'removeItemFromWishlist',
}
