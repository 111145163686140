import React, { ReactNode } from 'react'
import { InView } from 'react-intersection-observer'

import s from './styles.module.scss'

interface InfiniteScrollerProps {
  hasMore?: boolean
  isLoading?: boolean
  results?: ReactNode
  showLoader?: boolean
  onFetchMoreCallback: () => void
}

const ROOT_MARGIN = '400px'

const InfiniteScroller: React.FC<InfiniteScrollerProps> = ({
  results,
  hasMore = false,
  isLoading = true,
  showLoader = false,
  onFetchMoreCallback,
}) => {
  return (
    <>
      {results}
      {showLoader && isLoading && (
        <div className={s.spinnerContainer}>
          <div className={s.spinner} />
        </div>
      )}
      {!isLoading && hasMore && (
        <InView
          onChange={(inView) => {
            if (inView && !isLoading) {
              onFetchMoreCallback?.()
            }
          }}
          rootMargin={ROOT_MARGIN}
          initialInView={false}
          skip={isLoading}
        >
          {({ ref: inViewRef }) => <div ref={inViewRef}></div>}
        </InView>
      )}
    </>
  )
}

export { InfiniteScroller }
