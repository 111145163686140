import { Trans } from 'next-i18next'
import { useEffect } from 'react'

import useTranslation from 'lib/hooks/useTranslation'

import { useAppData } from 'lib/context/app-data-context'

import { EVENTS } from 'lib/constants/events'

import MobileAppQrCode from 'brand-assets/illustrations/mobile-app-qrcode.svg'

import s from './styles.module.scss'

type SettingsAppBannerProps = {
  trackEvent: TrackEventType
}

const SettingsAppBanner = ({}: SettingsAppBannerProps) => {
  const { t } = useTranslation()
  const { trackEvent } = useAppData()

  useEffect(() => {
    trackEvent.current({
      attributeId: EVENTS.SETTINGS_MOBILE_APP,
      attributeType: EVENTS.ATTRIBUTES_TYPE.BANNER,
      eventType: EVENTS.TYPE.EXPOSURE,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackEvent.current])

  return (
    <div className={s.settingsAppBanner}>
      <p className={s.content}>
        <Trans
          ns="common"
          i18nKey="t.getPelagoAppToAccessYourBookingEasily"
          components={{
            span: <span />,
          }}
        />
      </p>
      <div className={s.qrCode}>
        <MobileAppQrCode />
        <span className={s.scanText}>{t('t.scanToDownload', { ns: 'common' })}</span>
      </div>
    </div>
  )
}

export { SettingsAppBanner }
