import { useEffect, useMemo, useRef, useState } from 'react'

const useHandleOverflowingPlaceholder = (
  showPlaceholderAsDescription: boolean,
  placeholder?: string,
  errorOrDescription?: string
) => {
  const [isPlaceholderOverflowing, setIsPlaceholderOverflowing] = useState(false)
  const inputRef = useRef<HTMLParagraphElement | null>(null)

  const descriptionText = useMemo(() => {
    const placeholderOverflow = placeholder && isPlaceholderOverflowing

    if (showPlaceholderAsDescription && placeholderOverflow) {
      const placeholderWithFullstop = placeholder?.charAt(placeholder?.length - 1) === '.'

      return [placeholder, errorOrDescription].join(placeholderWithFullstop ? ' ' : '. ')
    }

    return errorOrDescription
  }, [errorOrDescription, isPlaceholderOverflowing, placeholder, showPlaceholderAsDescription])

  useEffect(() => {
    if (!showPlaceholderAsDescription) return

    if (inputRef.current?.scrollWidth && inputRef.current?.offsetWidth) {
      setIsPlaceholderOverflowing(inputRef.current.scrollWidth > inputRef.current.offsetWidth)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { descriptionText, inputRef }
}

export default useHandleOverflowingPlaceholder
