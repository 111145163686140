import React, { FC } from 'react'

import { replacePseudoLinks } from 'lib/utils'

export interface RichTextProps {
  text?: string
  className?: string
}

const RichText: FC<RichTextProps> = ({ text, className }) => {
  if (!text) return null

  const _data = replacePseudoLinks(text)

  if (!_data) return null

  return (
    <div
      dangerouslySetInnerHTML={{ __html: _data }} // eslint-disable-line react/no-danger
      className={className}
    />
  )
}

export { RichText }
