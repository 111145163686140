import React from 'react'
import cn from 'classnames'

import { Icon } from 'ui/icon'

import { noop } from 'lib/utils'

import s from './styles.module.scss'

const ModalOverlay: React.FC<ModalOverlayProps> = ({
  isActive = false,
  content,
  withCloseBtn = false,
  closeOnOverlayClick = true,
  closeOnEsc = true,
  appearFrom,
  blur = false,
  backgroundWhite = false,
  padding = true,
  ignorePageScroll = false,
  onClose = noop,
}) => {
  const overlayRef = React.useRef<HTMLDivElement | null>(null)

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (closeOnOverlayClick && e.target === overlayRef.current) {
      onClose()
    }
  }

  React.useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (isActive && e.key === 'Escape' && closeOnEsc) {
        onClose()
      }
    }

    if (!ignorePageScroll) document.body.classList.toggle('overflow-hidden', isActive)

    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [closeOnEsc, isActive, ignorePageScroll, onClose])

  return (
    <div
      ref={overlayRef}
      className={cn(s.modalOverlay, 'flex-center', {
        [s._active]: isActive,
        [s[`_${appearFrom}`]]: !!appearFrom,
        [s.blur]: blur,
        [s._white]: backgroundWhite,
        [s._padding]: padding,
      })}
      onClick={handleOverlayClick}
    >
      {content}
      {withCloseBtn && (
        <Icon name="delete-x" size="large" className={s.modalOverlayClose} onClick={onClose} />
      )}
    </div>
  )
}

export { ModalOverlay }
