import s from './styles.module.scss'

const regionChips = new Array(5).fill(null)
const destinationByRegions = new Array(10).fill(null)

export function RegionChipsLoading() {
  return (
    <div className={s.regionChips}>
      <div className={s.chipContainer}>
        {regionChips.map((_, index) => (
          <div key={index} className={s.regionChipLoading} />
        ))}
      </div>
    </div>
  )
}

export function DestinationsByRegionLoading() {
  return (
    <div className={s.destinationContainer}>
      {destinationByRegions.map((_, index) => (
        <div key={index} className={s.destinationLoading}>
          <div className={s.image} />
          <div className={s.body}>
            <div className={s.title} />
            <div className={s.content} />
          </div>
        </div>
      ))}
    </div>
  )
}
