import {
  aesDecrypt,
  stringifyDate,
  stringifyTime,
  dateStringToLocalDate,
  dateTimeStringToLocalDate,
} from 'lib/utils'

import {
  SCHEMA_FIELD,
  STATIC_FIELDS_EXPIRY,
  STATIC_FIELDS_LS_KEY,
  LOCAL_STORAGE_ENCRYPTION_KEY,
} from 'lib/constants'

import { getStorageItem, removeStorageItem } from './web-storage'

export const toServerValue = (
  {
    type,
    name,
    showTimeSelect,
    showTimeSelectOnly,
    texts,
  }: FormField & { showTimeSelect?: boolean; showTimeSelectOnly?: boolean },
  item: Record<string, any>
) => {
  const placeholder = (texts?.placeholder || '') as string

  let value
  switch (type) {
    case SCHEMA_FIELD.CHECKBOX:
      value = texts?.[item?.[name]?.toString()]
      break
    case SCHEMA_FIELD.DATETIME:
      if (item?.[name]) {
        if (!showTimeSelect) {
          value = stringifyDate(item?.[name])
        } else if (showTimeSelectOnly) {
          value = stringifyTime(item?.[name])?.split('.')?.[0]
        } else if (showTimeSelect) {
          value = `${stringifyDate(item?.[name])} ${stringifyTime(item?.[name])?.split('.')?.[0]}`
        }
      }
      break

    default: {
      value = item?.[name]
      break
    }
  }
  return { name, type, value, placeholder }
}

export const toReadableValue = (
  type: string,
  value: any,
  { formatDate }: { formatDate: (date: Date, options: Intl.DateTimeFormatOptions) => string }
) => {
  switch (type) {
    case SCHEMA_FIELD.SELECT:
      return value?.map((item: Record<string, any>) => item.label).join(', ')
    case SCHEMA_FIELD.DATETIME:
      const dateString = typeof value === 'string' ? value : stringifyDate(value)
      const toDate =
        dateString.split(' ').length > 1
          ? dateTimeStringToLocalDate(dateString)
          : dateStringToLocalDate(dateString)
      return value
        ? formatDate(
            toDate,
            (typeof value === 'string' ? value : stringifyDate(value))?.split(' ').length > 1
              ? { hour: 'numeric', minute: 'numeric' }
              : {}
          )
        : '-'
    case SCHEMA_FIELD.FILE:
      return value?.fileName
    default:
      return value
  }
}

export const removeExpiredStaticFields = () => {
  try {
    const encryptedPayload = getStorageItem(STATIC_FIELDS_LS_KEY)
    const decryptedPayload = encryptedPayload && aesDecrypt(encryptedPayload, LOCAL_STORAGE_ENCRYPTION_KEY)

    const payload = decryptedPayload && JSON.parse(decryptedPayload)

    if (payload?.created) {
      const expired = new Date().getTime() - new Date(payload.created).getTime() > STATIC_FIELDS_EXPIRY

      if (expired) removeStorageItem(STATIC_FIELDS_LS_KEY)
    }
  } catch {}
}
