// #region Public routes
export const BOOKING_DETAILS_ROUTE = '/activity/[productSlug]/checkout/[intentId]/'
export const BOOKING_PAYMENT_ROUTE = '/activity/[productSlug]/booking/[bookingId]/'
export const BOOKING_STATUS_ROUTE = '/booking/status/[bookingId]/'
export const BOOKING_STATUS_LITE_ROUTE = '/booking/status/lite/[bookingId]/'
export const PRODUCT_ROUTE = '/activity/[productSlug]/'
export const ITINERARY_ROUTE = '/itinerary/[slug]-i[itineraryCode]/'
export const ITINERARY_SLUG_ROUTE = '/itinerary/[slug]/'
export const TRIP_PLANNER_ROUTE = '/trip-planner'
export const COUNTRY_ROUTE = '/country/[countryId]/'
export const COUNTRY_ROUTE_V1 = '/[countryId]-things-to-do-c[countryCode]/'
export const SUPPORT_CHAT_ROUTE = '/support/'
export const PRODUCT_LISTING_ROUTE = '/city/[destinationId]-activities/'
export const DESTINATION_PRODUCT_LISTING_ROUTE_V1 = '/[destinationSlug]-activities-d[destinationId]/'
export const DESTINATION_PRODUCT_LISTING_BASE_ROUTE_V2 = '/[destinationSlug]-things-to-do-d[destinationId]'
export const DESTINATION_PRODUCT_LISTING_ROUTE_V2 = `${DESTINATION_PRODUCT_LISTING_BASE_ROUTE_V2}/all-activities/`
export const PRODUCT_LISTNG_BY_TRAVELLER_TYPE_ROUTE = `${DESTINATION_PRODUCT_LISTING_BASE_ROUTE_V2}/[tagId]-tt[tagCode]`
export const PRODUCT_LISTNG_BY_ACTIVITY_TYPE_ROUTE = `${DESTINATION_PRODUCT_LISTING_BASE_ROUTE_V2}/[tagId]-at[tagCode]`
export const PRODUCT_LISTNG_BY_CATEGORY_ROUTE = `${DESTINATION_PRODUCT_LISTING_BASE_ROUTE_V2}/[subCategory]-sc[subCategoryId]`
export const COUNTRY_PRODUCT_LISTING_ROUTE_V1 = '/[countrySlug]-activities-c[countryId]/'
export const COUNTRY_PRODUCT_LISTING_ROUTE_V2 = '/[countrySlug]-things-to-do-c[countryId]/all-activities/'
export const POINT_OF_INTEREST_ROUTE = '/poi/[collectionSlug]-c[collectionCode]'
export const POINT_OF_INTEREST_ROUTE_V1 = '/[destinationSlug]/[collectionSlug]-c[collectionCode]'
export const THEME_ROUTE = '/theme/[collectionSlug]-c[collectionCode]'
export const COLLECTION_ROUTE = '/[destinationSlug]/[collectionSlug]-c[collectionId]'
export const ARTICLE_LISTING_ROUTE = '/city/[destinationId]-articles/'
export const DESTINATION_ROUTE = '/city/[destinationId]-things-to-do/'
export const DESTINATION_ROUTE_V1 = '/[destinationId]-things-to-do-d[destinationCode]/'
export const DESTINATION_ROUTE_V2 = '/city/[destinationId]/'
export const FORGOT_PASSWORD_ROUTE = '/forgot-password/'
export const RESET_PASSWORD_ROUTE = '/reset-password/:token/'
export const SOCIAL_AUTH_REDIRECT_ROUTE = '/:oauthProvider/callback/'
export const LOGIN_ROUTE = '/login/'
export const SIGNUP_ROUTE = '/signup/'
export const SEARCH_ROUTE = '/search/'
export const HOME_ROUTE = '/'
export const SESSION_EXPIRED_ROUTE = '/session-expired/'
export const ARTICLE_DETAIL_ROUTE = '/articles/[slug]/'
export const ARTICLE_HOME_ROUTE = '/articles/'
export const ARTICLE_DESTINATION_ROUTE = '/articles/city/[slug]/'
export const ARTICLE_COUNTRY_ROUTE = '/articles/country/[slug]/'
export const REDEEM_PAGE = '/redeem/'
export const REDEEM_STATUS_PAGE = '/redeem/status/[bookingId]/'
// #endregion

// #region Private routes

export const ACCOUNT_PROFILE_ROUTE = '/account/profile/'
export const ACCOUNT_BOOKINGS_ROUTE = '/account/bookings/'
export const ACCOUNT_REWARDS_ROUTE = '/account/rewards/'
export const ACCOUNT_REVIEWS_ROUTE = '/account/reviews/'
export const ACCOUNT_SETTINGS_ROUTE = '/account/settings/'
export const WISHLIST_ROUTE = '/wishlist/'
export const WISHLIST_ITEMS_ROUTE = '/wishlist/[wishlistId]/'

export const PRIVATE_ROUTES: string[] = [
  ACCOUNT_PROFILE_ROUTE,
  ACCOUNT_BOOKINGS_ROUTE,
  ACCOUNT_REVIEWS_ROUTE,
  ACCOUNT_SETTINGS_ROUTE,
  ACCOUNT_REWARDS_ROUTE,
  WISHLIST_ROUTE,
]
// #endregion

export const STATIC_ROUTES: string[] = ['/404', '/500', '/notifications/unsubscribe']

export const NOT_FOUND_ROUTE = '*'
