/* eslint-disable @next/next/no-img-element */
import React, { FC, useState, useRef, useEffect, useMemo, Fragment } from 'react'
import cn from 'classnames'

import { AspectRatio } from 'components/aspect-ratio'

import { getProductPlaceholderImages } from 'page-modules/product/utils'

import { MediaItem as MediaItemType } from 'lib/@Types/media'
import { getImageUrl, getIsAbsoluteUrl, isBrowser } from 'lib/utils'

import { ImageLazyLoad } from './image-lazy-load'

import s from './styles.module.scss'

type LayoutType = 'fill'

type ImageSize = {
  width: number | string
  height: number | string
  aspectRatio?: never
  layout?: never
  smartFill?: never
}

type ImageAspectRatio = {
  aspectRatio: number
  smartFill?: boolean
  width?: never
  height?: never
  layout?: never
}

type Layout = {
  layout: LayoutType
  width?: never
  height?: never
  aspectRatio?: never
  smartFill?: never
}

// either height width or aspect ratio or layout (this or that type)
type ImageSizeAspectRatioLayout = ImageSize | ImageAspectRatio | Layout

export type Sizes = 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge'

interface ImageProps {
  src: MediaItemType
  className?: string
  params?: any
  size?: Sizes
  useUrlOverPlaceholder?: boolean
  lazyLoad?: boolean
  lazyLoadOffset?: string
  nativeLazyLoad?: boolean
  placeHolder?: any
  progressiveLoad?: boolean
  delayLoad?: number
  alt?: string
  priority?: boolean
  onClick?: (e: any) => void
}

// size mapping from pelago-image-resize (repo -> lambda)
// note: if any change in image size in lambda function, same goes here
const sizeMapping: Record<Sizes, number> = {
  xsmall: 180,
  small: 320,
  medium: 640,
  large: 1200,
  xlarge: 1920,
}

// we hard code the sorting so for each image on page it avoids sorting loop
// sorting complexity if we go by looping
// 5(n) sizes per image * 2 image types
// complexity: O(n^2) * 2
const sortedSize = [
  { key: 'webpXsmall', mediaSize: sizeMapping.xsmall },
  { key: 'webpSmall', mediaSize: sizeMapping.small },
  { key: 'webpMedium', mediaSize: sizeMapping.medium },
  { key: 'webpLarge', mediaSize: sizeMapping.large },
  { key: 'webpXlarge', mediaSize: sizeMapping.xlarge },
  { key: 'xsmall', mediaSize: sizeMapping.xsmall },
  { key: 'small', mediaSize: sizeMapping.small },
  { key: 'medium', mediaSize: sizeMapping.medium },
  { key: 'large', mediaSize: sizeMapping.large },
  { key: 'xlarge', mediaSize: sizeMapping.xlarge },
]

const Image: FC<ImageProps & ImageSizeAspectRatioLayout> = ({
  src,
  width,
  height,
  aspectRatio,
  smartFill,
  layout,
  className,
  size, // override auto pickup size
  lazyLoad = true,
  useUrlOverPlaceholder = true,
  lazyLoadOffset,
  nativeLazyLoad = false,
  placeHolder,
  children,
  progressiveLoad,
  delayLoad,
  priority,
  onClick,
  ...other
}) => {
  // image lazy load support
  const isNativeLazyLoadAvailable = isBrowser && 'loading' in HTMLImageElement.prototype
  const { name, url, sizes, caption, description } = src ?? {}
  const [imageSize, setImageSize] = useState(size)
  const [hydrated, setHydrated] = useState(false)
  const [imageSrcSizes, setImageSrcSizes] = useState(sizes)
  const [imageRetryCount, setImageRetryCount] = useState(0)

  const [imageLoaded, setImageLoaded] = useState(false)
  const [isTimeout, setIsTimeout] = useState<boolean>(!delayLoad)

  // read: https://stackoverflow.com/questions/39777833/image-onload-event-in-isomorphic-universal-react-register-event-after-image-is
  const ref = useRef<HTMLImageElement | null>()
  const bgRef = useRef<HTMLDivElement | null>()

  useEffect(() => setHydrated(true), [])

  // Update source's srcSset to provided url, either original url or placeholder image
  // so we can update the loaded image
  const onUpdateImageSrc = (e: React.ChangeEvent<HTMLImageElement>, url = '') => {
    e.currentTarget.onerror = null
    e.currentTarget.src = url
    setImageSrcSizes((currentImageSizes) => {
      if (!currentImageSizes) return {}

      return Object.keys(currentImageSizes).reduce((accum, imageSizeKey) => {
        accum[imageSizeKey] = url
        return accum
      }, {} as Record<string, string>)
    })
    setImageRetryCount((prevCount) => prevCount + 1)
  }

  // if image variant is used and can't be loaded, try again with normal `url`
  const onImageError = (e: React.ChangeEvent<HTMLImageElement>) => {
    // Prevent infinite errors on local
    if (imageRetryCount >= 3) {
      return
    }

    const placeholderImage = getProductPlaceholderImages().sizes[imageSize || 'medium']
    const imageUrl = getImageUrl(url)

    // this condition is met when there are something wrong with loading placeholder image, so we'll stop update image src
    if (e.currentTarget.currentSrc === placeholderImage) {
      return
    }

    const isOriginalUrlFailed = e.currentTarget.currentSrc === imageUrl
    onUpdateImageSrc(e, isOriginalUrlFailed ? placeholderImage : imageUrl)
  }

  const onImageLoaded = () => {
    // progressively load only once
    if (progressiveLoad && imageSize === size) {
      const currentSizeIndex = Object.keys(sizeMapping).findIndex((s) => s === imageSize)
      const nextSize = Object.keys(sizeMapping)[currentSizeIndex + 1] as Sizes
      if (nextSize) setImageSize(nextSize)
    }
    setImageLoaded(true)
  }

  // read: https://stackoverflow.com/questions/39777833/image-onload-event-in-isomorphic-universal-react-register-event-after-image-is
  useEffect(() => {
    if (ref.current && ref.current.complete) {
      if (bgRef.current)
        bgRef.current.style.setProperty('--background-image-url', `url(${ref.current.currentSrc})`)

      onImageLoaded()
    }
  })

  const getImageFullUrl = (imageSrc: string) => {
    if (process.env.NODE_ENV === 'development') {
      return getIsAbsoluteUrl(imageSrc) ? imageSrc : `https://traveller.dev.pelago.com${imageSrc}`
    }

    return imageSrc
  }

  const [_aspectRatio, sourceElement] = useMemo(() => {
    const _aspectRatio =
      aspectRatio || (typeof width === 'number' && typeof height === 'number' && width / height) || 1

    if (delayLoad && !isTimeout) return [_aspectRatio, []]

    // image sources -> webp -> jpeg -> correct size pickup
    let sourceElement: any = []
    if (imageSrcSizes) {
      sourceElement = sortedSize
        .filter(({ key }) =>
          imageSize // size already choosen by props
            ? key.replace('webp', '').toLowerCase() === imageSize.toLocaleLowerCase() && imageSrcSizes[key]
            : // else let browser decide what size to choose
              !!imageSrcSizes[key]
        )
        .map(({ key, mediaSize }) => {
          const otherProps = imageSize ? {} : { media: `(max-width: ${mediaSize}px)` }

          return (
            <source
              key={key}
              // There could be difference in srcSet if image failed to load, adding this will suppress the hydration warning in console
              suppressHydrationWarning
              // read uri issue (encode uri): https://github.com/spatie/laravel-medialibrary/issues/1437
              // we have images with whitespace - name of destination e.g. hong kong we add in image path
              srcSet={getImageFullUrl(encodeURI(imageSrcSizes[key]))}
              type={`image/${imageSrcSizes[key].split('.')[imageSrcSizes[key].split('.').length - 1]}`}
              {...otherProps}
            />
          )
        })
    }

    return [_aspectRatio, sourceElement]
  }, [width, height, aspectRatio, imageSize, imageSrcSizes, delayLoad, isTimeout])

  useEffect(() => {
    if (!delayLoad || !hydrated) return

    const timeout = setTimeout(() => {
      setIsTimeout(true)
    }, delayLoad)

    return () => clearTimeout(timeout)
  }, [delayLoad, hydrated, url, setIsTimeout])

  const withBackgroundBlur = useMemo(() => {
    // use bg blur only if the image cant reach atleast 70% of the requested aspect ratio
    if (smartFill && imageLoaded && ref.current && aspectRatio) {
      return aspectRatio - ref.current.clientWidth / ref.current.clientHeight > 0.3
    }
    return false
  }, [aspectRatio, smartFill, imageLoaded])

  const coveredImage = useMemo(() => smartFill && !withBackgroundBlur, [smartFill, withBackgroundBlur])

  const getImageSrc = useMemo(() => {
    // Show resized image if present else show original image only if present && useUrlOverPlaceholder is set true.
    // Otherwise show placeholder image
    const imageSrc =
      Object.keys(sizes || {})?.length && imageSize && sizes?.[imageSize]
        ? sizes?.[imageSize]
        : useUrlOverPlaceholder && url
        ? url
        : getProductPlaceholderImages().sizes[imageSize || 'medium']

    return getImageFullUrl(imageSrc)
  }, [imageSize, sizes, url, useUrlOverPlaceholder])

  const pictureElement = (
    <picture key="picture">
      {sourceElement}
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        {...other}
        /** ******************
        Even though we have implemented our custom lazy load instead of relying on native browser lazy load support
        we are still using browser native lazy load along with custom lazy load
        Why?
        If we ommit below loading (browser native lazy load), safari browser(bug) not working to pick up correct image type and it
        downloads both webp & jpeg(fallback)
        Firefox(bug) also does same but it cancels jpeg request the moment webp request starts
        Putting below loading makes all browser pickup correct image type without duplicate request
        bug link: https://bugs.webkit.org/show_bug.cgi?id=190031
         ******************************    **/
        loading={(isNativeLazyLoadAvailable && 'lazy') || undefined}
        fetchpriority={priority ? 'high' : undefined}
        src={!isTimeout ? undefined : getImageSrc}
        alt={caption || description || name || other.alt || ''}
        width="100%"
        height="100%"
        // read: https://stackoverflow.com/questions/39777833/image-onload-event-in-isomorphic-universal-react-register-event-after-image-is
        ref={(_ref) => (ref.current = _ref)}
        // do not support classname if smart fill is enabled because image element will not be the root element anymore
        className={cn(
          s.img,
          {
            [s.smartFill]: smartFill,
            [s.coveredImage]: coveredImage,
          },
          !smartFill && className
        )}
        onClick={onClick}
        onLoad={onImageLoaded}
        onError={onImageError}
        suppressHydrationWarning // since we need to check for browser native lazy load, initially the server will mark the loading as null and then in the browser it will update to 'lazy' again, this is to suppress hydration warning in console
      />
    </picture>
  )

  // image placeholder when image is not loaded
  const placeHolderElement = <Fragment key="placeholder">{placeHolder}</Fragment> || (
    <div
      className={cn('loadingElement', {
        [s.loadingElement]: true,
        [s.loaded]: imageLoaded,
      })}
      key="placeholder"
    />
  )

  const backgroundImageElement = withBackgroundBlur ? (
    <div key="background" ref={(_ref) => (bgRef.current = _ref)} className={cn(s.backgroundImage)} />
  ) : null

  const lazyLoadElement = (
    <ImageLazyLoad
      key="imageLazyLoad"
      edge="bottom"
      offset={lazyLoadOffset || '300px'}
      skipLazyLoad={(isNativeLazyLoadAvailable && nativeLazyLoad) || !lazyLoad}
    >
      {hydrated ? pictureElement : <img alt={caption || description || name || other.alt || ''} />}
    </ImageLazyLoad>
  )

  let renderingElements = []

  if (lazyLoad) {
    renderingElements.push(
      <div
        key="imageLazyLoadContainer"
        className={cn(s.imageContainer, { [s.hasBackgroundImage]: withBackgroundBlur })}
      >
        {layout === 'fill' ? lazyLoadElement : <div className={s.body}>{lazyLoadElement}</div>}
      </div>
    )
  } else {
    renderingElements.push(pictureElement)
  }

  renderingElements.push([placeHolderElement, children])

  if (withBackgroundBlur) renderingElements = [backgroundImageElement, ...renderingElements]

  const layoutBasedRenderingElements =
    layout === 'fill' ? (
      <>{renderingElements}</>
    ) : (
      <AspectRatio ratio={_aspectRatio} width={width}>
        {renderingElements}
      </AspectRatio>
    )
  return layoutBasedRenderingElements
}

export { Image }
