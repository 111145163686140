import React from 'react'
import cn from 'classnames'

import s from './styles.module.scss'

interface ContainerProps {
  children: React.ReactNode
  className?: string
}

const Container = React.forwardRef<HTMLDivElement, ContainerProps>(({ children, className }, ref) => {
  return (
    <div className={cn(s.container, className)} ref={ref}>
      {children}
    </div>
  )
})
Container.displayName = 'Container'

export { Container }
