import { ChangeEventHandler, FC } from 'react'

import { Input } from 'ui/text'
import type { InputProps } from 'ui/text/types'

import s from './styles.module.scss'

interface SearchInputProps extends Omit<InputProps, 'onChange'> {
  value: string
  onClear?: () => void
  onChange?: (value: string) => void
}

const SearchInput: FC<SearchInputProps> = ({
  value,
  placeholder,
  onChange,
  onClear,
  onClick,
  ...otherProps
}) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => onChange?.(event.target.value)

  return (
    <Input
      value={value}
      leftIconName="magnifying-glass"
      leftIconClassName={s.icon}
      rightIconName={value ? 'delete-x' : undefined}
      rightIconAsButton
      onClickRightIcon={onClear}
      placeholder={placeholder}
      onClick={onClick}
      onChange={handleChange}
      noFloat
      noDescription
      contClassName={s.input}
      {...otherProps}
    />
  )
}

export { SearchInput }
