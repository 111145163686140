import { useCallback } from 'react'
import cn from 'classnames'

import { Icon } from 'ui/icon'

import { Link } from 'components/link'

import { buildPath } from 'lib/utils'

import { COUNTRY_ROUTE_V1, DESTINATION_ROUTE_V1 } from 'lib/constants/routes'

import { ItemClickFn } from '../types'

import s from './styles.module.scss'

type DestinationItemProps = {
  name: string
  countryName?: string
  uri: string
  id?: string
  code: string
  countryCode?: string
  isCountry?: boolean
  source: string
  onClick: ItemClickFn
  isActive?: boolean
  shouldRedirectAfterSelect?: boolean
}

const DestinationItem = ({
  name,
  countryName,
  uri,
  id,
  code,
  countryCode,
  isCountry = false,
  isActive,
  source,
  shouldRedirectAfterSelect,
  onClick,
}: DestinationItemProps) => {
  const displayName = countryName && name && countryName !== name ? `${name}, ${countryName}` : name

  const handleClick = useCallback(() => {
    onClick(uri, { isCountry, source, name, parentCountryName: countryName, code, countryCode, id })
  }, [isCountry, source, uri, countryName, name, code, countryCode, onClick, id])

  const renderButton = useCallback(() => {
    return (
      <button type="button" onClick={handleClick} className={cn(s.destinationItem, { [s.active]: isActive })}>
        <div className={s.destinationItemContent}>
          <div className={s.destinationItemIcon}>
            <Icon name="map-pin" size="small" />
          </div>
          <p className={s.destinationItemText}>{displayName}</p>
        </div>
        <div className={s.destinationItemArrowIcon}>
          <Icon name="arrow-right" />
        </div>
      </button>
    )
  }, [displayName, handleClick, isActive])

  const handleRedirectRoute = () => {
    const route = isCountry ? COUNTRY_ROUTE_V1 : DESTINATION_ROUTE_V1
    const routeParams = isCountry
      ? { countryId: uri, countryCode: code }
      : { destinationId: uri, destinationCode: code }

    return buildPath(route, routeParams)
  }

  return !shouldRedirectAfterSelect ? (
    renderButton()
  ) : (
    <Link href={handleRedirectRoute()}>{renderButton()}</Link>
  )
}

export { DestinationItem }
