import { useMemo } from 'react'
import { useRouter } from 'next/router'

import { getCookieCurrency } from 'lib/utils'
import { isIsrPageRequest } from 'lib/utils/middleware'

import { PRODUCT_ROUTE } from 'lib/constants/routes'

import useRouteMatch from './useRouteMatch'

// For ISR page & Cloudfront cached page(i.e. PDP), currency is stored along with cached page hence we need to take care about user's preference currency
// instead of cached currency
function useCurrencyForCachedPage({ serverCurrency }: { serverCurrency: string | undefined }) {
  const router = useRouter()
  const isPdpPage = useRouteMatch(PRODUCT_ROUTE)

  const currencyForIsrPage = useMemo(() => {
    const isIsrPage = isIsrPageRequest(router.asPath)

    if (!isIsrPage && !isPdpPage) return serverCurrency

    const currencyCookie = getCookieCurrency()
    // For ISR & cached page server currency will be cached cookie so we take the user browser currency instead of server side
    if (currencyCookie && serverCurrency !== currencyCookie) {
      return currencyCookie
    }
    return serverCurrency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath, serverCurrency, isPdpPage])

  return { currency: currencyForIsrPage }
}

export { useCurrencyForCachedPage }
