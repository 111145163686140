import { gql } from '@apollo/client'

import { DYNAMIC_COMPONENTS_DATA_QUERY_BODY } from './common'
import * as FIELDS from '../fragments'

export const BOOKING_QUERY = {
  query: gql`
    query booking($bookingId: String!) {
      booking(bookingId: $bookingId) {
        ...on Booking {
          ${FIELDS.bookingFields}
        }
        ${FIELDS.PelagoError}
      }
    }
  `,
  queryName: 'booking',
}

export const BOOKING_INTENT_QUERY = {
  query: gql`
    query bookingIntent($intentId: String!) {
      bookingIntent(intentId: $intentId) {
        ...on BookingIntent {
          ${FIELDS.bookingIntentFields}
        }
        ${FIELDS.PelagoError}
      }
    }
  `,
  queryName: 'bookingIntent',
}

export const PRODUCT_CARDS_QUERY = {
  query: gql`
    query productCards($filters: ProductFilterInput!, $componentType:String, $pageSize: Int, $pageType: String) {
      productCards(filters: $filters, componentType: $componentType, pageSize: $pageSize, pageType: $pageType) {
        ... on ProductCards {
          products {
            ${FIELDS.productCardFields}
          }
          productCount
        }
        ${FIELDS.PelagoError}
      }
    }
  `,
  queryName: 'productCards',
}

export const AVAILABLE_PRODUCT_OPTIONS_QUERY = {
  query: gql`
    query availableProductOptionsByDate($productId: String!, $date: String!) {
      availableProductOptionsByDate(productId: $productId, date: $date) {
        ... on ProductOptionAvailabilityList {
          productOptionsAvailability {
            optionId
            priceRangeFrom
            strikeOffDiscountType
            strikeOffDiscountValue
          }
        }
        ${FIELDS.PelagoError}
      }
    }
  `,
  queryName: 'availableProductOptionsByDate',
}

export const PRODUCT_OPTIONS_AVAILABLE_DATES_QUERY = {
  query: gql`
    query productOptionsAvailableDates($optionId: String!, $productId: String!) {
      productOptionsAvailableDates(optionId: $optionId, productId: $productId) {
        ... on ProductOptionAvailableDates {
          availableDates
        }
        ${FIELDS.PelagoError}
      }
    }
  `,
  queryName: 'availableProductOptionsByDate',
}

export const RECOMMENDED_PRODUCTS_QUERY = {
  query: gql`
    query recommendedForYou($popularity: Boolean) {
      recommendedForYou(popularity: $popularity) {
        ... on ESProductCards {
          productCount
          products {
            ${FIELDS.productCardFields}
          }
        }
        ${FIELDS.PelagoError}
      }
    }
  `,
  queryName: 'recommendedForYou',
}

// This is the new query for `recommendedForYou` for backwards compatibility
export const DS_API_RECOMMENDED_QUERY = {
  query: gql`
    query dsApiRecommended($searchInput: DSAPIRecommendedInput!) {
      dsApiRecommended(searchInput: $searchInput) {
        ... on ESProductCards {
          productCount
          products {
            ${FIELDS.productCardFields}
          }
        }
        ${FIELDS.PelagoError}
      }
    }
  `,
  queryName: 'dsApiRecommended',
}

export const USER_PROFILE_RECOMMENDED_QUERY = {
  query: gql`
     query userProfileRecommended($searchInput: UserProfileRecommendedInput!) {
      userProfileRecommended(searchInput: $searchInput) {
        ... on ESProductCards {
          productCount
          products {
            ${FIELDS.productCardFields}
          }
        }
        ${FIELDS.PelagoError}
      }
    }
  `,
  queryName: 'userProfileRecommended',
}

export const RECENTLY_VIEWED_PRODUCTS_QUERY = {
  query: gql`
    query recentlyViewed($productIds: [String]!, $pageSize: Int) {
      recentlyViewed(productIds: $productIds, pageSize: $pageSize) {
        ... on ESProductCards {
          productCount
          products {
            ${FIELDS.productCardFields}
          }
        }
        ${FIELDS.PelagoError}
      }
    }
  `,
  queryName: 'recentlyViewed',
}

export const YOU_MAY_ALSO_LIKE_PRODUCTS_QUERY = {
  query: gql`
    query youMayAlsoLike(
      $productId: String!,
      $isBooked: Boolean,
      $page: Int = 1,
      $pageSize: Int = 5,
    ) {
      youMayAlsoLike(productId: $productId, isBooked: $isBooked, page: $page, pageSize: $pageSize) {
        ...on ESProductCards {
          products {
            ${FIELDS.productCardFields}
          }
          productCount
        }
        ${FIELDS.PelagoError}
      }
    }`,
  queryName: 'youMayAlsoLike',
}

const PRODUCT_QUERY_BODY = `
  product(productId: $productId, lang: $lang) {
    ... on Product {
      ${FIELDS.productFields}
      metaTags
      faqs
    }
    ${FIELDS.PelagoError}
  }
`

const RELATED_PRODUCTS_QUERY_BODY = `
  relatedProducts(productId: $productId, details: $relatedProductDetails) {
    ... on ESProductCards {
      productCount
      products {
        ${FIELDS.productCardFields}
      }
    }
    ${FIELDS.PelagoError}
  }
`

const PRODUCT_OTPIONS_QUERY_BODY = `
  productOptions(productId: $productId, activityDate: $activityDate, lang: $lang, pdpVisibilityTag: $pdpVisibilityTag) {
    ... on ProductOption {
      optionId
      optionName
      optionRank
      bestSeller
      content
      minimumQuantity
      maximumQuantity
      contentLang
      allSelectors
      selectorMetaData
      nextAvailabilityData {
        nextAvailableDate
        priceRangeFrom
        strikeOffDiscountValue
        strikeOffDiscountType
      }
      paymentBreakdown {
        depositType
        depositValue  
        finalPaymentDueDays
      }
      confirmationTypeText
      cancellationType
      confirmationType
      cancellationPolicy {
        callout
        cancellationWindows {
          cancelBy
          refundPercentage
        }
      }
    }
    ${FIELDS.PelagoListError}
  }
`

export const PRODUCT_OPTION_GROUPS_QUERY_BODY = `
  optionGroups(productId: $productId) {
    ... on OptionGroupsOutput {
      optionGroups {
        groupId
        groupName
        optionIds
      }
    }
    ${FIELDS.PelagoError}
  }
`

export const PRODUCT_REVIEW_TAGS_QUERY_BODY = `
  productReviewTags(productId: $productId) {
    ... on ProductReviewTags {
      productReviewTags {
        reviewCount
        positiveReviewCount
        reviewTag {
          reviewTagId
          reviewTagName
        }
      }
    }
    ${FIELDS.PelagoError}
  }
`

export const PRODUCT_QUERY = {
  query: gql`
    query product($productId: String!, $activityDate: String, $lang: String, $pdpVisibilityTag: String) {
      ${PRODUCT_QUERY_BODY}
      ${PRODUCT_OTPIONS_QUERY_BODY}
    }
  `,
  queryName: 'product',
}

export const EXIT_INTENT_PROMO_QUERY = {
  query: gql`
    query exitIntentPromo($details: ExitIntentPromoInput!) {
      exitIntentPromo(details: $details) {
        ... on ExitIntentPromoOutput {
          showPromo
          isNewUser
        }
        ${FIELDS.PelagoError}
      }
    }
  `,
  queryName: 'exitIntentPromo',
}

const PRODUCT_REVIEWS_QUERY_BODY = `
  productReviewsDetails(page: $productReviewsPage, pageSize: $productReviewsPageSize, productId: $productId, sortBy: $sortBy, externalSource: $externalSource, reviewFilter: $reviewFilter){
    ...on ReviewDataHistory{
      reviews{
        activityDate
        rating
        reviewId
        reviewDate
        comment
        status
        travellerType
        firstName
        lastName
        externalSource
        externalReviewId
      }
      reviewCount
    }
    ${FIELDS.PelagoError}
  }
`

export const PRODUCT_PAGE_QUERY = {
  query: gql`
    query product($productId: String!, $activityDate: String, $lang: String, $productReviewsPage: Int, $productReviewsPageSize: Int, $sortBy: ReviewSortByEnum, $relatedProductDetails: RelatedProductsInput!, $externalSource: String, $reviewFilter: ProductReviewDetailsFilter, $dynamicComponentsInput: DynamicComponentDetailsInput!, $pdpVisibilityTag: String) {
      ${PRODUCT_QUERY_BODY}
      ${PRODUCT_OTPIONS_QUERY_BODY}
      ${PRODUCT_REVIEWS_QUERY_BODY}
      ${DYNAMIC_COMPONENTS_DATA_QUERY_BODY}
      ${RELATED_PRODUCTS_QUERY_BODY}
      ${PRODUCT_OPTION_GROUPS_QUERY_BODY}
      ${PRODUCT_REVIEW_TAGS_QUERY_BODY}
    }
  `,
  queryName: 'product',
}

export const PRODUCT_PRICE_OPTION_AND_AVAILABILITY_ONLY_QUERY = {
  query: gql`
    query product($productId: String!, $activityDate: String, $lang: String, $pdpVisibilityTag: String) {
      product(productId: $productId, lang: $lang) {
        ... on Product {
          productId
          currency
          priceRangeFrom
          priceRangeTo
          availabilityStartDate
          availabilityEndDate
          availableDates
          strikeOffDiscountValue
          strikeOffDiscountType
          isPromoEnabled
          nextAvailableDate
          paymentBreakdown {
            depositType
            depositValue
            finalPaymentDueDays
          }
          status
          kfmilesBurnValue
          kfmilesMinimumValue
          hideKfMiles
        }
        ${FIELDS.PelagoError}
      }
      productOptions(productId: $productId, activityDate: $activityDate, lang: $lang, pdpVisibilityTag: $pdpVisibilityTag) {
        ... on ProductOption {
          optionId
          optionName
          optionRank
          bestSeller
          minimumQuantity
          maximumQuantity
          nextAvailabilityData {
            nextAvailableDate
            priceRangeFrom
            strikeOffDiscountValue
            strikeOffDiscountType
          }
          paymentBreakdown {
            depositType
            depositValue  
            finalPaymentDueDays
          }
        }
        ${FIELDS.PelagoListError}
      }
    }
  `,
  queryName: 'product',
}

export const PRODUCT_UNITS_DATA_QUERY = {
  query: gql`
    query productUnitsData($details: ProductUnitsInput!) {
      productUnitsData(details: $details) {
        ... on ProductUnitsData {
          timeSlots {
            timeSlotId
            timeSlotValue
            maximumQuantity
            units {
              content
              dependantOn
              unitId
              unitPriceType
              numberOfParticipants
              unitName
              unitNameId
              unitRank
              unitMinAge
              unitMaxAge
              unitCurrency
              sellingPrice
              primaryUnit
              optionId
              unitMetaData
              loyaltyRewards
              kfmilesBurnValue
              kfmilesBurnRate
              kfmilesMinimumValue
              minimumQuantity
              maximumQuantity
              isAvailable
              isRequired
              netPrice
              strikeOffDiscountValue
              strikeOffDiscountType
            }
            addOnUnits {
              content
              dependantOn
              unitId
              unitPriceType
              numberOfParticipants
              unitName
              unitNameId
              unitRank
              unitMinAge
              unitMaxAge
              unitCurrency
              sellingPrice
              primaryUnit
              optionId
              unitMetaData
              loyaltyRewards
              kfmilesBurnValue
              kfmilesBurnRate
              kfmilesMinimumValue
              minimumQuantity
              maximumQuantity
              isAvailable
              isRequired
              netPrice
              strikeOffDiscountValue
              strikeOffDiscountType
            }
          }
          isTimeSlotsVisible
          timeSlotsLabel
        }
        ${FIELDS.PelagoError}
      }
    }
  `,
  queryName: 'productUnitsData',
}

export const RECOMMENDED_AND_RECENTLY_PRODUCTS_AND_EXPLORE_QUERY = {
  query: gql`
    query recommendedProductAndExplore($productId: String!, $destinationId: String!, $isRecentlyViewed: Boolean!, $productIds: [String]!) {
      youMayAlsoLike(productId: $productId) {
        ...on ESProductCards {
          products {
            ${FIELDS.productCardFields}
          }
        }
        ${FIELDS.PelagoError}
      }
      destinationGroupedTags(destinationId: $destinationId) {
        ...on TagTree {
          tree
        }
        ${FIELDS.PelagoError}
      }
      recentlyViewed(productIds: $productIds) @include(if: $isRecentlyViewed) {
        ... on ESProductCards {
          productCount
          products {
            ${FIELDS.productCardFields}
          }
        }
        ${FIELDS.PelagoError}
      }
    }
  `,
  queryName: 'recommendedProductAndExplore',
}

export const PRODUCT_REVIEWS_QUERY = {
  query: gql`
    query productReviewsDetails($productReviewsPage: Int, $productReviewsPageSize: Int, $productId: String!, $sortBy: ReviewSortByEnum, $externalSource: String, $reviewFilter: ProductReviewDetailsFilter) {
      ${PRODUCT_REVIEWS_QUERY_BODY}
    }
  `,
  queryName: 'productReviewsDetails',
}

export const RANKED_PRODUCT_CARDS_QUERY = {
  query: gql`
    query rankedProductCards(
      $destinationId: String,
      $countryId: String,
      $componentType: String,
      $page: Int,
      $pageSize: Int,
      $pageType: String
    ) {
      rankedProductCards(
        destinationId: $destinationId,
        countryId: $countryId,
        componentType: $componentType,
        pageSize: $pageSize,
        page: $page,
        pageType: $pageType
      ) {
        ... on ProductCards {
          products {
            ${FIELDS.productCardFields}
            countryId
          }
          productCount
        }
        ${FIELDS.PelagoError}
      }
    }
  `,
  queryName: 'rankedProductCards',
}

export const CUSTOMER_PENDING_REVIEW_QUERY = {
  query: gql`
    query customerPendingReviews($page: Int, $pageSize: Int) {
      customerPendingReviews(page: $page, pageSize: $pageSize) {
        ...on PendingReviewBookings {
          bookings {
            bookingId
            productId
            productName
            activityDate
            productUri
            productHeroImage
            reviewPromoEligible
          }
          bookingCount
        }
        ${FIELDS.PelagoError}
      }
    }
  `,
  queryName: 'customerPendingReviews',
}

export const CUSTOMER_REVIEW_QUERY = {
  query: gql`
    query customerReviewsDetails($page: Int, $pageSize: Int) {
      customerReviewsDetails(page: $page, pageSize: $pageSize){
        ...on ReviewDataHistory{
          reviews{
            comment
            reviewDate
            activityDate
            productId
            productUri
            productName
            productHeroImage
            rating
            reviewId
            status
            travellerType
          }
          reviewCount
        }
        ${FIELDS.PelagoError}
      }
    }
  `,
  queryName: 'customerReviewsDetails',
}

export const CUSTOMER_REVIEW_PRODUCT_QUERY = {
  query: gql`
    query reviewPageProductDetails($bookingId: String!){
      reviewPageProductDetails(bookingId: $bookingId) {
        ...on BookingItemProductData {
          bookingItem{
            bookingId
            productId
            productUri
            productName
            productHeroImage
            optionName
            activityDate
          }
          productUnits
        }
        ${FIELDS.PelagoError}
      }
    }
  `,
  queryName: 'reviewPageProductDetails',
}

export const PINNED_PRODUCT_REVIEWS = {
  query: gql`
    query pelagoReviews($page: PageTypeEnum, $entityId: String, $pageSize: Int){
      pelagoReviews(page: $page, entityId: $entityId, pageSize: $pageSize) {
        ...on ReviewDataHistory {
          reviews{
            reviewId
            firstName
            travellerType
            comment
            rating
            productName
            productId
            productUri
          }
        }
        ${FIELDS.PelagoError}
      }
    }
  `,
  queryName: 'pelagoReviews',
}

export const THEME_COLLECTION_FILTER_PRODUCTS_QUERY = {
  query: gql`
    query themeCollectionFilterProducts($searchInput: ThemeCollectionFilterProductsInput!) {
      themeCollectionFilterProducts(searchInput: $searchInput) {
        ... on ESFilterProducts {
            products {
              ${FIELDS.productCardFields}
              searchScore
              searchMeta
            }
            productCount
        }
        ${FIELDS.PelagoError}
      }
    }
  `,
  queryName: 'themeCollectionFilterProducts',
}
