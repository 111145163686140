import useExitIntent from 'lib/hooks/useExitIntent'

// This component is used to track focus event for exit intent
const ExitIntentEvents = () => {
  useExitIntent({ onFocus: true })

  return null
}

export { ExitIntentEvents }
