import { gql } from '@apollo/client'

import { PelagoError, productCardFields } from 'gql/fragments'

export const ITINERARY_QUERY = {
  query: gql`
    query itinerary($itineraryCode: Int!) {
      itinerary(itineraryCode: $itineraryCode) {
        ... on Itinerary {
          itineraryId
          itineraryName
          itineraryUri
          itineraryState
          itineraryTextPrompt
          itineraryPrompt
          itineraryCode
          metaTags
          itineraryHeroImage {
            url
            caption
            description
            name
            sizes
          }
          itineraryContent {
            header
            tabs {
              title
              header
              sections {
                header
                description
                products {
                  ${productCardFields}
                }
              }
            }
          }
        }
        ${PelagoError}
      }
    }
  `,
  queryName: 'itinerary',
}

export const ITINERARY_CONFIG_QUERY = {
  query: gql`
    query itineraryCreateConfig {
      itineraryCreateConfig {
        ... on ItineraryCreateConfig {
          maxDayCount
          samplePrompts
          travellerTypeCards {
            label
            icon
          }
          characteristicTypeCards {
            label
            icon
          }
        }
        ${PelagoError}
      }
    }
  `,
  queryName: 'itineraryCreateConfig',
}
