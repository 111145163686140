import { useEffect, useState } from 'react'
import cn from 'classnames'

import { Chip } from 'ui/chip'
import { WishlistCard } from '../card'

import useTranslation from 'lib/hooks/useTranslation'

import s from './styles.module.scss'
import { useQuery } from '@apollo/client'
import { WISHLIST_LIST } from 'gql'
import { Button } from 'ui/button'

const PAGE_SIZE = 10

const WishlistChecklist: React.FC<{
  productId?: string
  selectedIds?: string[]
  onSelectionChange: (val: string[]) => void
  onAddNewWishlistClick?: (val: any) => void
}> = ({ onSelectionChange, productId, selectedIds = [], onAddNewWishlistClick }) => {
  const { t, tsmart } = useTranslation()
  const { data } = useQuery(WISHLIST_LIST.query, { variables: { productId } })
  const { wishlists = [] } = data?.[WISHLIST_LIST.queryName] || {}

  const [page, setPage] = useState(1)
  const totalPages = Math.ceil(wishlists?.length / PAGE_SIZE)

  useEffect(() => {
    const selectedWishlists = wishlists.filter((wishlist: any) => {
      return wishlist.isProductSaved
    })

    if (selectedWishlists.length === 0) return

    const selectedWishlistIds = selectedWishlists.map((wishlist: any) => wishlist.wishlistId)

    onSelectionChange(selectedWishlistIds)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wishlists])

  return (
    <div className={s.container}>
      {onAddNewWishlistClick && (
        <Chip
          iconName="plus"
          label={tsmart('action.newWishlist', { ns: 'common' })}
          size="large"
          variant="light"
          onClick={onAddNewWishlistClick}
        />
      )}

      {wishlists.length > 0 && (
        <>
          <ul className={cn(s.list, { [s.lastPage]: page >= totalPages })}>
            {wishlists
              .slice(0, page * PAGE_SIZE)
              .map(({ wishlistName, wishlistId, itemsCount, thumbnail }: any) => (
                <li key={wishlistId} className={s.listItem}>
                  <WishlistCard
                    id={wishlistId}
                    variant="checklist"
                    checked={selectedIds?.includes(wishlistId)}
                    name={wishlistName}
                    thumbnail={thumbnail}
                    count={itemsCount}
                    onClick={(id: string) => {
                      let _selectedIds = [...selectedIds]

                      if (_selectedIds.includes(id)) {
                        _selectedIds = selectedIds.filter((_id) => _id !== id)
                      } else {
                        _selectedIds.push(id)
                      }

                      onSelectionChange(_selectedIds)
                    }}
                  />
                </li>
              ))}
          </ul>

          {page < totalPages && (
            <Button
              variant="trasparent"
              iconName="caret-down"
              iconPosition="right"
              onClick={() => setPage((_page) => _page + 1)}
              fluid
              className="mb-4"
            >
              {t('action.loadMore', { ns: 'common' })}
            </Button>
          )}
        </>
      )}
    </div>
  )
}

export { WishlistChecklist }
