import { FC, useRef } from 'react'
import cn from 'classnames'

import useTranslation from 'lib/hooks/useTranslation'
import useClickOutside from 'lib/hooks/useClickOutside'

import s from './styles.module.scss'

const LangCurrencyDropdown: FC<DropdownProps> = ({
  activeTab,
  attached,
  currencyOptions,
  languageOptions,
  border,
  selectedCurrencyId,
  selectedLangId,
  onCurrencySwitch,
  onLanguageSwitch,
  onClickOutside,
}) => {
  const { t } = useTranslation()
  const dropdownRef = useRef<HTMLDivElement | null>(null)
  useClickOutside(dropdownRef, () => onClickOutside?.())

  const currencyColumns = currencyOptions && Math.ceil(currencyOptions?.length / 2)
  const languageColumns = (languageOptions && Math.ceil(languageOptions?.length / 2)) || 0

  const gridStyle: any = {
    '--items-per-row': currencyColumns || Math.max(languageColumns, 6),
  }

  const isLanguageTabActive = activeTab === 'language'
  const isCurrencyTabActive = activeTab === 'currency'

  return (
    <div
      className={cn(s.dropdown, {
        [s.borderLess]: border === 'none',
        [s.attachedBottom]: attached === 'bottom',
      })}
    >
      <div className={s.header}>
        {isCurrencyTabActive && t('t.currency', { ns: 'common' })}
        {isLanguageTabActive && t('t.language', { ns: 'common' })}
      </div>

      <div className={s.content} style={gridStyle}>
        {isCurrencyTabActive && (
          <ul className={s.currencies}>
            {currencyOptions?.map((c) => (
              <li
                key={c.id}
                className={cn({ [s.selected]: c.id === selectedCurrencyId })}
                onClick={() => onCurrencySwitch?.(c.id)}
              >
                <span className={cn('mr-4', 'bold')}>{c.id}</span>
                <span>{c.name}</span>
              </li>
            ))}
          </ul>
        )}

        {isLanguageTabActive && (
          <ul className={s.languages}>
            {languageOptions?.map(({ label, id }) => (
              <li
                key={id}
                className={cn({ [s.selected]: id === selectedLangId })}
                onClick={() => onLanguageSwitch?.(id)}
              >
                {label}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}

export { LangCurrencyDropdown }
