import React, { useMemo } from 'react'
import cn from 'classnames'

import { Button } from 'ui/button'

import { LayoutButtonProps } from './types'

import s from './styles.module.scss'

const LayoutButton = React.forwardRef<HTMLButtonElement, LayoutButtonProps>(
  (
    {
      variant = 'standard',
      size = 'medium',
      shape = 'round',
      labelResponsive,
      labelResponsiveClassname,
      children,
      className,
      ...rest
    },
    ref
  ) => {
    const responsiveChildren = useMemo(() => {
      if (labelResponsive)
        return <span className={labelResponsiveClassname || s['responsive-label']}>{children}</span>

      return children
    }, [children, labelResponsive, labelResponsiveClassname])

    return (
      <Button
        {...rest}
        variant="none"
        size={size}
        shape={shape}
        className={cn(s.btn, s[`btn-${variant}`], s[`btn-${size}`], className)}
        ref={ref}
      >
        {responsiveChildren}
      </Button>
    )
  }
)

LayoutButton.displayName = 'LayoutButton'

export { LayoutButton }
