import type { MutableRefObject } from 'react'

import { Icon } from 'ui/icon'

import useCustomTranslation from 'lib/hooks/useTranslation'

import { SearchInput } from './search-input'

import s from './styles.module.scss'

type DestinationModalHeaderProps = {
  searchText: string
  inputRef: MutableRefObject<HTMLInputElement | undefined>
  onSearchChange: (value: string) => void
  closeModal: () => void
}

const DestinationModalHeader = ({
  inputRef,
  searchText,
  onSearchChange,
  closeModal,
}: DestinationModalHeaderProps) => {
  const { t } = useCustomTranslation(['common'])

  const onClearSearch = () => onSearchChange('')

  return (
    <div className={s.modalHeader}>
      <div className={s.modalHeaderSearch}>
        <button type="button" onClick={closeModal} className={s.backButton}>
          <Icon name="caret-left" />
        </button>
        <SearchInput
          value={searchText}
          onChange={onSearchChange}
          onClear={onClearSearch}
          getRef={(args) => (inputRef.current = args)}
          placeholder={t('t.searchForDestination', { ns: 'common' })}
        />
        <div className={s.closeButtonPlaceholder} />
      </div>
    </div>
  )
}

export { DestinationModalHeader }
