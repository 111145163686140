import { DependencyList, EffectCallback, useEffect, useLayoutEffect } from 'react'

import { isBrowser } from 'lib/utils'

// note: as this is SSR app, using useLayoutEffect shows warning below
// This will lead to a mismatch between the initial, non-hydrated UI and the intended UI. To avoid this, useLayoutEffect should only be used in components that render exclusively on the client.
// hence whenever need useLayoutEffect, use this hook to take care about above warning
// fix doc link: https://gist.github.com/gaearon/e7d97cdf38a2907924ea12e4ebdf3c85
const _useLayoutEffect = (fn: EffectCallback, deps?: DependencyList) => {
  const useIsomorphicLayoutEffect = isBrowser ? useLayoutEffect : useEffect
  useIsomorphicLayoutEffect(fn, deps)
}

export default _useLayoutEffect
