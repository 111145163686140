import { useQuery, useLazyQuery } from '@apollo/client'
import type {
  DocumentNode,
  QueryHookOptions,
  TypedDocumentNode,
  OperationVariables,
  QueryResult,
  LazyQueryHookOptions,
  LazyQueryResultTuple,
  NoInfer,
} from '@apollo/client'

import { APOLLO_CLIENT_VERSION } from 'lib/constants'

type ApolloQuery<TData, TVariables extends OperationVariables = OperationVariables> =
  | DocumentNode
  | TypedDocumentNode<TData, TVariables>

type ApolloQueryFn = <TData = any, TVariables extends OperationVariables = OperationVariables>(
  query: ApolloQuery<TData, TVariables>,
  options?: QueryHookOptions<NoInfer<TData>, NoInfer<TVariables>>
) => QueryResult<TData, TVariables>

type ApolloLazyQueryFn = <TData = any, TVariables extends OperationVariables = OperationVariables>(
  query: ApolloQuery<TData, TVariables>,
  options?: LazyQueryHookOptions<NoInfer<TData>, NoInfer<TVariables>>
) => LazyQueryResultTuple<TData, TVariables>

// ========== SEARCH ==========
export const useSearchQuery: ApolloQueryFn = (query, options) => {
  return useQuery(query, {
    ...options,
    context: {
      ...options?.context,
      version: APOLLO_CLIENT_VERSION.SEARCH,
    },
  })
}

export const useSearchLazyQuery: ApolloLazyQueryFn = (query, options) => {
  return useLazyQuery(query, {
    ...options,
    context: {
      ...options?.context,
      version: APOLLO_CLIENT_VERSION.SEARCH,
    },
  })
}
// ========== SEARCH End ==========
