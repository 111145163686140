import { useEffect, useRef } from 'react'
import { useQuery } from '@apollo/client'
import { WISHLIST_NAME_SUGGESTION } from 'gql'

import { getError, logError } from 'lib/utils'
import { Input } from 'ui/text'
import useCustomTranslation from 'lib/hooks/useTranslation'

const NameInput: React.FC<any> = ({
  onValueChange,
  initialValue,
  value,
  placeholder,
  autoSuggestName = false,
  destinationId,
  errorMessage,
  editMode = false,
  onPressEnter,
}) => {
  const { tp, t } = useCustomTranslation('common')
  const nameInputRef = useRef<HTMLInputElement>()

  const { loading } = useQuery(WISHLIST_NAME_SUGGESTION.query, {
    variables: { destinationId },
    skip: !autoSuggestName,
    onCompleted: (data) => {
      const autoSuggestedName = getError(data[WISHLIST_NAME_SUGGESTION.queryName]) || {}

      if (autoSuggestedName?.errorMessage) {
        return logError(new Error(autoSuggestedName?.errorMessage))
      }

      onValueChange(autoSuggestedName?.output)
    },
    onError: logError,
  })

  useEffect(() => {
    onValueChange(initialValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue])

  useEffect(() => {
    if (nameInputRef?.current && !loading) {
      // Initial load works without `setTimeout` as expected.
      // However, `setTimeout` required for 2nd time onwards
      setTimeout(() => {
        if (nameInputRef.current) {
          nameInputRef.current?.focus()
        }
      }, 0)
    }
  }, [nameInputRef, loading])

  useEffect(() => {
    // Blur the activeElement when its Inputs/Button before focusing on other Inputs
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur()
    }

    return () => {
      if (document.activeElement instanceof HTMLElement && document.activeElement === nameInputRef.current) {
        nameInputRef.current.blur()
      }
    }
  }, [])

  return (
    <div>
      {!editMode && (
        <div className="body-sm pb-2">{tp('t.easilyAccessAllYourSavedActivitiesInOnePlace')}</div>
      )}

      <Input
        getRef={(inputRef) => (nameInputRef.current = inputRef)}
        onChange={(e) => onValueChange(e.target.value)}
        errorMessage={errorMessage}
        value={value}
        disabled={loading}
        placeholder={placeholder || t('t.wishlistName')}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            onPressEnter(e)
          }
        }}
      />
    </div>
  )
}

export { NameInput }
