import React, { createContext, useCallback, useEffect, useMemo, useRef } from 'react'
import FingerprintJS, { defaultEndpoint, defaultScriptUrlPattern } from '@fingerprintjs/fingerprintjs-pro'

// uncomment below if decided to go cookie based in future due to high api call rate
// import { getBrowserCookie, setBrowserCookie } from 'lib/utils'
import { getDsUserId } from 'lib/utils/auth'

// uncomment below if decided to go cookie based in future due to high api call rate
// import { COOKIE_FINGERPRINT_REQUEST_ID, COOKIE_FINGERPRINT_REQUEST_ID_EXPIRY } from 'lib/constants'

type FingerprintAgentType = ReturnType<typeof FingerprintJS.load>

interface FingerprintContextType {
  getRequestId: () => Promise<string | null>
}

const FingerprintContext = createContext<FingerprintContextType>({
  getRequestId: () => new Promise((resolve) => resolve(null)),
})
FingerprintContext.displayName = 'FingerprintContext'

const FingerprintContextProvider = (props: any) => {
  const fingerprintAgent = useRef<FingerprintAgentType | null>(null)

  const initializeFingerprintAgent = useCallback(() => {
    if (!!process.env.NEXT_PUBLIC_FINGERPRINT_API_KEY) {
      ;(async () => {
        fingerprintAgent.current = FingerprintJS.load({
          apiKey: process.env.NEXT_PUBLIC_FINGERPRINT_API_KEY || '',
          endpoint: [process.env.NEXT_PUBLIC_FINGERPRINT_ENDPOINT || defaultEndpoint, defaultEndpoint],
          scriptUrlPattern: [
            `${process.env.NEXT_PUBLIC_FINGERPRINT_ENDPOINT}/web/v<version>/<apiKey>/loader_v<loaderVersion>.js`,
            defaultScriptUrlPattern,
          ],
          region: 'ap',
        })
      })()
    } else throw Error('fingerprint api key is missing')
  }, [])

  useEffect(() => {
    const windowLoadHandler = () => {
      if (fingerprintAgent.current) return

      // initialize fingerprint agent during browser idle time
      // setTimeout will help to achieve lazyOnload strategoy(browser idle time) to initialize finterprint agent
      setTimeout(() => {
        try {
          initializeFingerprintAgent()
        } catch (e) {
          // do nothing
        }
      }, 1)
    }

    const isWindowLoadedAlready = document.readyState === 'complete'
    if (isWindowLoadedAlready) {
      windowLoadHandler()
      return
    }

    window?.addEventListener?.('load', windowLoadHandler)
    return () => window?.removeEventListener?.('load', windowLoadHandler)
  }, [initializeFingerprintAgent])

  const getRequestId = useCallback(
    () =>
      new Promise<string | null>(async (resolve, reject) => {
        if (!fingerprintAgent.current) {
          reject('FingerprintJS agent is not initialized')
          return
        }

        // uncomment below if decided to go cookie based in future due to high api call rate
        // const existingRequestId = getBrowserCookie(COOKIE_FINGERPRINT_REQUEST_ID)
        // if (existingRequestId) {
        //   resolve(existingRequestId)
        //   return
        // }
        try {
          const fingerprintRequest = await fingerprintAgent.current
          const result = await fingerprintRequest.get({ linkedId: getDsUserId() })
          const requestId = result.requestId
          // uncomment below if decided to go cookie based in future due to high api call rate
          // setBrowserCookie(COOKIE_FINGERPRINT_REQUEST_ID, requestId, COOKIE_FINGERPRINT_REQUEST_ID_EXPIRY)

          resolve(requestId)
        } catch (e) {
          reject(e)
        }
      }),
    []
  )

  const value = useMemo<FingerprintContextType>(() => {
    return {
      getRequestId,
    }
  }, [getRequestId])

  return <FingerprintContext.Provider value={value} {...props} />
}

const useFingerPrintContext = () => {
  const context = React.useContext<FingerprintContextType>(FingerprintContext)

  if (context === undefined) {
    throw new Error('useFingerPrintContext must be used within a FingerprintContextProvider')
  }
  return context
}

export { useFingerPrintContext, FingerprintContextProvider }
