import cn from 'classnames'
import { forwardRef, useContext } from 'react'

import { Icon } from 'ui/icon'

import HighlightText from 'components/highlight-text'

import { AutoCompleteContext, AutocompleteContextProps } from '..'

import s from './styles.module.scss'

type QueryCardProps = {
  className?: string
  onClick?: () => void
  query: string
}

const QueryCard = forwardRef<HTMLDivElement, QueryCardProps>(({ query, className, onClick }, ref) => {
  const { value } = useContext<AutocompleteContextProps>(AutoCompleteContext)

  return (
    <div ref={ref} className={cn(s.card, s.destinationCard, className)} onClick={onClick} tabIndex={-1}>
      <div className={s.destination}>
        <Icon name="magnifying-glass" size="semi-medium" />
      </div>

      <div className={s.body}>
        <div className={s.content}>
          <h4 className={s.title}>{value ? <HighlightText text={query} highlight={value} /> : query}</h4>
        </div>
        <Icon name="arrow-right" size="medium" className={s.hoverIcon} />
      </div>
    </div>
  )
})

QueryCard.displayName = 'QueryCard'

export { QueryCard }
