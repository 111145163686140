import React, { FC, useCallback } from 'react'
import { Trans } from 'next-i18next'

import { Button } from 'ui/button'

import { WishlistChecklist } from 'page-modules/wishlist/card-checklist'
import { NameInput } from 'page-modules/wishlist/name-input'

import useAuth from 'lib/hooks/useAuth'
import useTranslation from 'lib/hooks/useTranslation'
import { useGlobalContext } from 'lib/context/global-context'
import { useWishlistData } from 'lib/context/wishlist-context'
import { logError } from 'lib/utils'
import { useAppData } from 'lib/context/app-data-context'
import { EVENTS } from 'lib/constants/events'
import { DrawerDialog } from 'ui/drawer-dialog'

const WishlistModal: FC = () => {
  const { t, tsmart } = useTranslation('common')
  const { isLoggedIn } = useAuth()
  const { toast, trackEvent } = useAppData()
  const wishlist = useWishlistData()
  const { isMobileView } = useGlobalContext()

  const { productId, destinationId, onComplete } = wishlist.modal.data

  const closeModal = useCallback(() => wishlist.modal.setActive('none'), [wishlist.modal])

  const showToastMessage = useCallback(
    (i18nKey, values) =>
      toast.success({
        content: (
          <span>
            <Trans
              i18nKey={i18nKey}
              values={values}
              components={{
                bold: <span className="bold" />,
              }}
            />
          </span>
        ),
        id: 'wishlist-toast',
      }),
    [toast]
  )

  const handleCreateWishlist = useCallback(() => {
    const { wishlistName, productId, nextAction, onProductAddedToWishlist } = wishlist?.modal?.data || {}
    const _wishlistName = wishlistName?.trim()

    trackEvent.current({
      attributeId: EVENTS.CREATE_WISHLIST,
      attributeType: EVENTS.ATTRIBUTES_TYPE.BUTTON,
    })

    if (!_wishlistName) return

    wishlist.createWishlistBucket({
      wishlistName: _wishlistName,
      productId,
      isProductReset: nextAction === 'none',
      onSuccess: (data) => {
        const { product, nextAction, selectedIds } = wishlist?.modal?.data || {}

        if (product && productId && nextAction === 'update') {
          if (onProductAddedToWishlist) {
            onProductAddedToWishlist(true)
          } else {
            wishlist.onProductAddedToWishlist(product)
          }
        }

        if (nextAction !== 'redirect') {
          // Item is added to newly created Wishlist from non-account page,
          // So show "savedTo" toast message
          showToastMessage('msg.savedTo', {
            count: 1,
            wishlistName: _wishlistName,
          })
        }

        wishlist.modal.setActive('none')

        onComplete?.({ ...data, selectedIds })
      },
      onError: (e) => {
        wishlist.modal.updateData((curr: any) => ({ ...curr, errorMessage: e.message }))
        logError(e)
      },
    })
  }, [onComplete, showToastMessage, trackEvent, wishlist])

  const handleSaveToWishlist = useCallback(() => {
    const { selectedIds: selectedWishlists = [] } = wishlist?.modal?.data || {}

    trackEvent.current({
      attributeId: EVENTS.SAVE_WISHLIST,
      attributeType: EVENTS.ATTRIBUTES_TYPE.BUTTON,
    })

    // UpdateItemInWishlists
    wishlist.updateItem({
      productId,
      wishlistIds: selectedWishlists,
      onSuccess: (data) => {
        const { addedWishlists = [], removedWishlists = [] } = data || {}

        if (!addedWishlists.length && !removedWishlists.length) return

        showToastMessage(addedWishlists?.length ? 'msg.savedTo' : 'msg.removedFrom', {
          count: addedWishlists?.length || removedWishlists?.length,
          wishlistName: (addedWishlists?.length ? addedWishlists : removedWishlists)?.[0]?.wishlistName,
        })

        onComplete?.(data)
      },
      onError: (e) => logError(e),
    })

    wishlist.modal.setActive('none')
  }, [onComplete, productId, showToastMessage, trackEvent, wishlist])

  if (!isLoggedIn || wishlist.modal.active === 'none') return null

  return (
    <>
      <DrawerDialog
        variant="small"
        asBottomSheet={isMobileView}
        {...(isMobileView && { animation: 'slideUp' })}
        header={t('t.createNewWishlist')}
        open={wishlist.modal.active === 'create'}
        onClose={closeModal}
        onClickOutside={closeModal}
        onEscKeyPress={closeModal}
        bringToFront
        customFooter={
          <Button
            fluid
            disabled={!wishlist?.modal?.data?.wishlistName?.trim()}
            onClick={handleCreateWishlist}
          >
            {t('t.createWishlist')}
          </Button>
        }
      >
        <NameInput
          value={wishlist.modal.data.wishlistName}
          errorMessage={wishlist.modal.data.errorMessage}
          autoSuggestName={wishlist.modal.data.autoSuggestName}
          onValueChange={(val: any) => {
            wishlist.modal.updateData((curr: any) => ({ ...curr, wishlistName: val }))
          }}
          destinationId={destinationId}
          onPressEnter={handleCreateWishlist}
        />
      </DrawerDialog>

      <DrawerDialog
        variant={isMobileView ? 'large' : 'small'}
        asBottomSheet={isMobileView}
        {...(isMobileView && { animation: 'slideUp' })}
        header={tsmart('msg.saveToWishlists')}
        open={wishlist.modal.active === 'update'}
        onClose={closeModal}
        onClickOutside={closeModal}
        onEscKeyPress={closeModal}
        bringToFront
        customFooter={
          <Button fluid onClick={handleSaveToWishlist}>
            {t('action.save')}
          </Button>
        }
      >
        <WishlistChecklist
          onSelectionChange={(selectedIds) => {
            wishlist.modal.updateData((curr: any) => ({ ...curr, selectedIds }))
          }}
          selectedIds={wishlist.modal?.data?.selectedIds || []}
          productId={productId}
          onAddNewWishlistClick={() => {
            trackEvent.current({
              attributeId: EVENTS.NEW_WISHLIST,
              attributeType: EVENTS.ATTRIBUTES_TYPE.BUTTON,
            })

            wishlist.modal.updateData((curr: any) => ({
              ...curr,
              nextAction: curr.nextAction ?? 'update',
            }))
            wishlist.modal.setActive('create')
          }}
        />
      </DrawerDialog>
    </>
  )
}

export { WishlistModal }
