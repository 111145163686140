import { getBrowserCookie, logError } from 'lib/utils'

import { PRODUCT_ROUTE } from 'lib/constants/routes'
import { COOKIES_USER_LOCATION } from 'lib/constants'

import useRouteMatch from './useRouteMatch'

const useUserLocation = ({ ssrGeoLocation }: { ssrGeoLocation: GeoLocation }) => {
  // pdp is cached page
  const isPdpPage = useRouteMatch(PRODUCT_ROUTE)
  // chached cloudfront page response will have user location in cookie
  const userLocationFromCookie = getBrowserCookie(COOKIES_USER_LOCATION)

  try {
    const parsedUserLocation = userLocationFromCookie?.trim?.() ? JSON.parse(userLocationFromCookie) : {}

    if (isPdpPage && Object.keys(parsedUserLocation || {}).length) {
      return {
        countryCode: parsedUserLocation.countryCode,
        latitude: parsedUserLocation.latitude,
        longitude: parsedUserLocation.longitude,
        cityName: parsedUserLocation.cityName,
      } as GeoLocation
    } else if (Object.keys(ssrGeoLocation || {}).length) return ssrGeoLocation
  } catch (e) {
    logError(e)
    if (Object.keys(ssrGeoLocation || {}).length) return ssrGeoLocation
  }

  return null
}

export { useUserLocation }
