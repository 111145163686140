import { useEffect, useRef, useMemo, useContext } from 'react'
import { useRouter } from 'next/router'
import { useQuery } from '@apollo/client'
import throttle from 'lodash-es/throttle'
import cn from 'classnames'

import { Icon } from 'ui/icon'

import useTranslation from 'lib/hooks/useTranslation'
import { useSearchQuery } from 'lib/hooks/apollo/useCustomQuery'

import { buildPath, getIdFromSlug } from 'lib/utils'
import { ActivityLog, useAppData } from 'lib/context/app-data-context'

import { SEARCH_ROUTE } from 'lib/constants/routes'

import { IconNames } from 'brand-assets/icon-lib'
import { TRENDING_PRODUCTS, TRENDING_SEARCH } from 'gql/queries/search'

import { AutoCompleteContext, AutocompleteContextProps, SearchAutocompleteResults } from '..'
import { useSearchTrackEvent } from '../hooks'

import s from './styles.module.scss'

const MAX_RECENT_PRODUCTS_DESTINATIONS = 3

const getSortedRecentlyViewedItems = (activityLog: ActivityLog) => {
  const { products, destinations } = activityLog
  if (!products.length && !destinations.length) return []

  const productsDestinationsLogs = [...products, ...destinations]
    .sort((a, b) => {
      return new Date(b.created).getTime() - new Date(a.created).getTime()
    })
    .slice(0, MAX_RECENT_PRODUCTS_DESTINATIONS)
  const productDestinationsData = productsDestinationsLogs.map((logData) => logData.info)

  return productDestinationsData
}

const DropdownHeader: React.FC<any> = ({ children, hidden }) => {
  if (hidden) return null

  return <div className={s.dropdownHeader}>{children}</div>
}

const Tags = ({
  iconName,
  items,
  onClick,
  recommendType,
}: {
  iconName: IconNames
  items: string[]
  onClick?: (keyword: string) => void
  recommendType: 'recent' | 'trending' | 'auto_suggest'
}) => {
  const { variant, value, trackEvent } = useContext<AutocompleteContextProps>(AutoCompleteContext)
  const tagRefs = useRef<HTMLButtonElement[]>([])
  const tagParentRef = useRef<HTMLDivElement>(null)
  const router = useRouter()

  const tagOptions = useMemo(
    () =>
      items?.map?.((text: string, index: number) => ({
        id: text,
        recommendType,
        cardIndex: index,
        cardType: 'query' as const,
      })),
    [items, recommendType]
  )

  const { trackExposure, trackClick } = useSearchTrackEvent({
    query: value,
    recommendType,
    options: tagOptions,
    elementRefs: tagRefs,
    parentRef: tagParentRef,
    trackEvent: trackEvent,
    isCompact: variant === 'header',
  })

  useEffect(() => {
    // give some delay to open the dropdown
    const timeoutId = setTimeout(() => trackExposure('horizontal'), 100)

    return () => clearTimeout(timeoutId)
  }, [trackExposure])

  useEffect(() => {
    const _tagParentRef = tagParentRef.current
    if (!_tagParentRef) return

    const onScroll = throttle(() => trackExposure('horizontal'), 50)
    _tagParentRef?.addEventListener?.('scroll', onScroll)

    return () => _tagParentRef?.removeEventListener?.('scroll', onScroll)
  }, [trackExposure])

  if (!items.length) return null

  const handleClick = (keyword: string) => {
    const index = tagOptions.findIndex((item) => item.id === keyword)
    trackClick(index)
    router.push(buildPath(SEARCH_ROUTE, {}, { keyword: keyword }))
    onClick?.(keyword)
  }

  return (
    <div ref={tagParentRef} className={s.tags}>
      {items?.map?.((tag, index: number) => (
        <button
          ref={(ref: any) => {
            tagRefs.current[index] = ref
          }}
          key={tag}
          className={s.tag}
          onClick={() => handleClick(tag)}
        >
          <Icon name={iconName} size="small" />
          <span className="ml-1">{tag}</span>
        </button>
      ))}
    </div>
  )
}

// attaching this for ease of use
// can move this to parent if there are instances where search autocomplete doesnt show recent searches from parent componet
const RecentSearches = ({ onSelect }: { onSelect?: (item?: any, type?: string) => void }) => {
  const { activityLog } = useAppData()
  const { t } = useTranslation()
  const router = useRouter()
  const { productSlug } = router.query

  const recentlyViewedItems = useMemo(() => {
    const sortedRecentlyViewedItem = getSortedRecentlyViewedItems(activityLog.logs)

    if (!productSlug) return sortedRecentlyViewedItem

    const productId = getIdFromSlug(productSlug as string)
    return sortedRecentlyViewedItem?.filter?.((item) => {
      if ('productId' in item) return item.productId !== productId
      return true
    })
  }, [activityLog, productSlug])

  const emptyLogs = !activityLog.keywords.length && !recentlyViewedItems?.length
  // since recent keywords and activities always show on top, assuming that it is always visibile and trigger track event

  if (emptyLogs) return null

  return (
    <div className={s.recentSearches}>
      <span>
        <DropdownHeader>{t('t.recentActivity', { ns: 'common' })}</DropdownHeader>
      </span>
      <Tags
        recommendType="recent"
        items={activityLog.keywords}
        iconName="magnifying-glass"
        onClick={(keyword: string) => onSelect?.(keyword, 'query')}
      />
      {recentlyViewedItems?.length ? (
        <div className={cn('mb-4', { 'mt-2': !activityLog.keywords.length })}>
          <SearchAutocompleteResults
            sortedOptions={recentlyViewedItems}
            recommendType="recent"
            onOptionClick={onSelect}
          />
        </div>
      ) : null}
    </div>
  )
}

const TrendingSearches = ({
  onSelect,
  destinationId,
  countryId,
}: {
  onSelect?: (item?: any, type?: string) => void
  destinationId?: string
  countryId?: string
}) => {
  const { activityLog } = useAppData()
  const { t } = useTranslation()

  const { data } = useSearchQuery(TRENDING_SEARCH.query, {
    variables: {
      searchInput: {
        destinationIds: destinationId ? [destinationId] : [],
        countryIds: countryId ? [countryId] : [],
      },
    },
  })

  const { destinations = [], products = [], queries = [] } = data?.[TRENDING_SEARCH.queryName] || {}

  const tagItems = useMemo(() => {
    if (!queries.length) return []

    return queries.map(({ query }: { query: string }) => query)
  }, [queries])

  const recentlyViewedItems = useMemo(() => {
    return getSortedRecentlyViewedItems(activityLog.logs)
  }, [activityLog.logs])

  if (!destinations.length && !products.length && !queries.length) return null

  return (
    <div className={s.trendingSearches}>
      <span>
        <DropdownHeader>{t('t.mostPopular', { ns: 'common' })}</DropdownHeader>
      </span>

      {queries.length ? (
        <Tags
          recommendType="trending"
          items={tagItems}
          iconName="trend-up"
          onClick={(keyword: string) => onSelect?.(keyword, 'query')}
        />
      ) : null}

      {products?.length || destinations.length ? (
        <div className={s.results}>
          <SearchAutocompleteResults
            cardRefStartIndex={recentlyViewedItems.length}
            options={{ destinations, products }}
            recommendType="trending"
            onOptionClick={onSelect}
          />
        </div>
      ) : null}
    </div>
  )
}

const TrendingProducts = ({ onSelect }: { onSelect?: (item?: any, type?: string) => void }) => {
  const { activityLog } = useAppData()
  const { t } = useTranslation()

  const { data } = useQuery(TRENDING_PRODUCTS.query)

  const { products = [] } = data?.[TRENDING_PRODUCTS.queryName] || {}

  const recentlyViewedItems = useMemo(() => {
    return getSortedRecentlyViewedItems(activityLog.logs)
  }, [activityLog.logs])

  if (!products.length) return null

  return (
    <div className={s.trendingSearches}>
      <span>
        <DropdownHeader>{t('t.mostPopular', { ns: 'common' })}</DropdownHeader>
      </span>

      {products?.length ? (
        <div className={s.results}>
          <SearchAutocompleteResults
            cardRefStartIndex={recentlyViewedItems.length}
            options={{ products }}
            recommendType="trending"
            onOptionClick={onSelect}
          />
        </div>
      ) : null}
    </div>
  )
}

const Component: React.FC<any> = ({ children }) => {
  return <div className={s.initiDropdown}>{children}</div>
}

interface CompoundedComponentProps extends React.FC<any> {
  Tags: typeof Tags
  RecentSearches: typeof RecentSearches
  TrendingSearches: typeof TrendingSearches
  TrendingProducts: typeof TrendingProducts
  Header: typeof DropdownHeader
  Results: typeof SearchAutocompleteResults
}

const InitDropdown = Component as CompoundedComponentProps

InitDropdown.Tags = Tags
InitDropdown.TrendingSearches = TrendingSearches
InitDropdown.RecentSearches = RecentSearches
InitDropdown.TrendingProducts = TrendingProducts
InitDropdown.Header = DropdownHeader

export { InitDropdown }
