import { Fragment } from 'react'

import { CountryDestInfo } from 'components/country-dest-filter/types'

import useCustomTranslation from 'lib/hooks/useTranslation'

import NotFoundImg from 'brand-assets/illustrations/page-not-found.svg'

import { DestinationItem } from '../destination-item'
import type { ItemClickFn } from '../types'

import s from './styles.module.scss'

type SearchResultsProps = {
  countryAndDestinations: CountryDestInfo[]
  onItemClick: ItemClickFn
  shouldRedirectAfterSelect?: boolean
  excludeCountries?: boolean
}

const SearchResults = ({
  countryAndDestinations,
  onItemClick,
  shouldRedirectAfterSelect,
  excludeCountries,
}: SearchResultsProps) => {
  const { t, tp } = useCustomTranslation('common')

  if (countryAndDestinations.length === 0) {
    return (
      <div className={s.notFound}>
        <NotFoundImg />
        <div className="label-sm bold text-grey-2 mb-1">{tp('msg.noMatchingDestination')}</div>
        <div className="text-sm text-grey-2">{t('exclm.stayTunedWillBeAddingMoreDestinationSoon')} </div>
      </div>
    )
  }

  return (
    <div>
      {countryAndDestinations.map((countryInfo) => {
        const isCountryHasOnlyOneDestination = countryInfo.destinations.length === 1

        return (
          <Fragment key={countryInfo.countryId}>
            {!isCountryHasOnlyOneDestination && !excludeCountries && (
              <DestinationItem
                name={countryInfo.countryName}
                uri={!shouldRedirectAfterSelect ? countryInfo.countryCode : countryInfo.countryId}
                id={countryInfo.countryId}
                code={`${countryInfo.countryNumericCode}`}
                countryCode={countryInfo.countryCode}
                onClick={onItemClick}
                isCountry
                source="search_results"
                shouldRedirectAfterSelect={shouldRedirectAfterSelect}
              />
            )}
            {countryInfo.destinations.map((destination, index) => {
              return (
                <DestinationItem
                  key={`${destination.destinationName}-${index}`}
                  name={destination.destinationName}
                  countryName={countryInfo.countryName}
                  uri={destination.destinationUri}
                  code={`${destination.destinationCode}`}
                  onClick={onItemClick}
                  source="search_results"
                  shouldRedirectAfterSelect={shouldRedirectAfterSelect}
                />
              )
            })}
          </Fragment>
        )
      })}
    </div>
  )
}

export { SearchResults }
