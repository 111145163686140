import React, { useEffect, useState } from 'react'

import { Toast } from 'components/toast'

import useAuth from 'lib/hooks/useAuth'
import useRouteMatch from 'lib/hooks/useRouteMatch'
import usePrevious from 'lib/hooks/usePrevious'
import { useSocialOnboard } from 'lib/hooks/socialAuth'

import { googleOneTap, promptGoolgeOneTap, dismissGoogleOneTap } from 'lib/google-one-tap'

import {
  BOOKING_PAYMENT_ROUTE,
  BOOKING_STATUS_ROUTE,
  REDEEM_PAGE,
  REDEEM_STATUS_PAGE,
} from 'lib/constants/routes'

const GoogleOneTap = () => {
  const { onboardUser, error: onBoardUserError } = useSocialOnboard(() => false)
  const { user } = useAuth()
  const [isError, setError] = useState<string>()
  const isPaymentRoute = useRouteMatch(BOOKING_PAYMENT_ROUTE)
  const isBookingStatusRoute = useRouteMatch(BOOKING_STATUS_ROUTE)
  const isRedeemRoute = useRouteMatch(REDEEM_PAGE)
  const isRedeemStatusRoute = useRouteMatch(REDEEM_STATUS_PAGE)
  const blockedRoutes =
    Boolean(isPaymentRoute) ||
    Boolean(isBookingStatusRoute) ||
    Boolean(isRedeemRoute) ||
    Boolean(isRedeemStatusRoute)
  const blockedRoutesPreviously = usePrevious(blockedRoutes)

  useEffect(() => {
    if (user || blockedRoutes) {
      dismissGoogleOneTap()
      return
    }
    googleOneTap(
      {
        clientId: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID as string,
        clientUrl: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_URL as string,
      },
      (response) => {
        const oneTapToken = response.credential
        onboardUser({ provider: 'GOOGLE_ONETAP', oneTapToken })
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    if (blockedRoutes) {
      dismissGoogleOneTap()
    } else if (blockedRoutesPreviously && !user) {
      promptGoolgeOneTap()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockedRoutes, blockedRoutesPreviously])

  useEffect(() => {
    if (user) return
    if (onBoardUserError) {
      setError(onBoardUserError)
    }
  }, [onBoardUserError, user, setError])

  if (isError)
    return (
      <Toast type="error" onClose={() => setError(undefined)}>
        {onBoardUserError}
      </Toast>
    )
  return null
}

export { GoogleOneTap }
