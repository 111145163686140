import { RefObject, useCallback, useMemo, useRef } from 'react'

import { getProductMerchandiseLabel } from 'page-modules/product/utils'

import usePrevious from 'lib/hooks/usePrevious'

import { isFullyVisible } from 'lib/utils'

import { EVENTS } from 'lib/constants/events'

interface SearchTrackParams {
  query?: string
  trackEvent?: TrackEventType
  recommendType?: SearchRecommendTypes
  isCompact?: boolean
  options: (KeywordTags | ResultCardDestinationOption | ResultCardProductOption | ResultCardQueryOption)[]
  parentRef?: RefObject<any>
  elementRefs?: RefObject<any>
  elementRefStartIndex?: number
}

interface KeywordTags {
  cardIndex: number
  id: string
  cardType: 'query'
  recommendType: SearchRecommendTypes
  destinationId?: never
}

interface TrackPayload {
  id: string
  cardIndex: number
  destinationId?: string
  cardType: 'query' | 'product' | 'destination' | 'query-suggestion'
  searchScore?: number
  searchMeta?: any
  recommendType?: string
  query?: string
  merchandiseLabel?: string
}

type ClickAttributeValue = TrackPayload & {
  results: TrackPayload[]
}

type ExposureAttributValue = Partial<TrackPayload> & {
  results: TrackPayload[]
}

const formatPayload = (option: ResultCardOption | KeywordTags, recommendType?: SearchRecommendTypes) => {
  const payload: TrackPayload = {
    id: option.id,
    cardIndex: option.cardIndex,
    cardType: option.cardType,
  }
  const productMerchandiseLabel =
    option.cardType === 'product' ? getProductMerchandiseLabel(option.productTags) : undefined
  if (recommendType) payload.recommendType = recommendType
  if (option.cardType === 'product' && option.searchScore) payload.searchScore = option.searchScore
  if (option.cardType === 'product' && option.searchMeta) payload.searchMeta = option.searchMeta
  if (option.cardType === 'product' && productMerchandiseLabel)
    payload.merchandiseLabel = productMerchandiseLabel.merchandiseLabel
  if (option.destinationId) payload.destinationId = option.destinationId
  if (option.cardType === 'query-suggestion' && option.query) payload.query = option.query

  return payload
}

const useSearchTrackEvent = ({
  trackEvent,
  options,
  query,
  recommendType,
  isCompact,
  parentRef,
  elementRefs,
  elementRefStartIndex = 0,
}: SearchTrackParams) => {
  const exposedCards = useRef<Record<string, boolean>>({})
  const optionIds = useMemo(() => options?.map?.((item) => item.id)?.join(''), [options])
  const previousOptionIds = usePrevious(optionIds)

  // reset exposed cards state once the data is updated
  if (optionIds !== previousOptionIds) {
    exposedCards.current = {}
    options?.forEach?.(({ cardIndex }) => (exposedCards.current[cardIndex] = false))
  }

  let attributeId = EVENTS.AUTO_SUGGEST_SEARCH_INPUT
  if (recommendType === 'recent') attributeId = EVENTS.SEARCH_RECENT
  if (recommendType === 'trending') attributeId = EVENTS.SEARCH_TRENDING
  if (recommendType === 'auto_suggest') attributeId = EVENTS.AUTO_SUGGEST

  const attributeType = recommendType ? EVENTS.ATTRIBUTES_TYPE.LIST : EVENTS.ATTRIBUTES_TYPE.RESULT

  const trackClick = (index: number, additionalParams?: Record<string, string>) => {
    const attributeValue: ClickAttributeValue = {
      query,
      ...formatPayload(options[index], recommendType),
      results: options?.map((option) => formatPayload(option, recommendType)),
    }

    trackEvent?.({
      eventType: EVENTS.TYPE.CLICK,
      attributeId,
      isCompact,
      attributeType,
      attributeValue,
      ...additionalParams,
    })
  }

  const trackExposure = useCallback(
    (elementDirection: 'horizontal' | 'vertical' = 'vertical') => {
      if (!parentRef?.current || !elementRefs) return

      if (Object.values(exposedCards?.current).every?.(Boolean)) return

      const results = options
        ?.filter?.(({ cardIndex }) => {
          const elementRef = elementRefs.current[cardIndex + elementRefStartIndex]
          if (!elementRef) return false

          const isElementVisible = isFullyVisible(elementRef, parentRef.current, elementDirection)

          const expose = isElementVisible && !exposedCards.current[cardIndex]
          if (expose) exposedCards.current[cardIndex] = true

          return expose
        })
        ?.map?.((option) => formatPayload(option, recommendType))

      // no exposure event should be triggered if the event is empty
      if (!results.length) return

      const attributeValue: ExposureAttributValue = {
        query,
        results: results || [],
      }
      trackEvent?.({
        eventType: EVENTS.TYPE.EXPOSURE,
        attributeId,
        isCompact,
        attributeType,
        attributeValue,
      })
    },
    [
      attributeId,
      attributeType,
      elementRefStartIndex,
      elementRefs,
      isCompact,
      options,
      parentRef,
      query,
      recommendType,
      trackEvent,
    ]
  )

  return {
    trackClick,
    trackExposure,
  }
}

export { useSearchTrackEvent }
