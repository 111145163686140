import { gql } from '@apollo/client'

import * as FIELDS from '../fragments'

export const WISHLIST_NAME_SUGGESTION = {
  query: gql`
    query wishlistNameSuggestion($destinationId: String!) {
      wishlistNameSuggestion(destinationId: $destinationId) {
        ... on PelagoOutput {
          output
        }
        ${FIELDS.PelagoError}
      }
    }
  `,
  queryName: 'wishlistNameSuggestion',
}

export const WISHLIST_LIST = {
  query: gql`
    query wishlistList($productId: String) {
      wishlistList(productId: $productId) {
        ... on WishlistList {
          wishlists {
            wishlistId
            wishlistName
            itemsCount
            isProductSaved
            thumbnail
            isDeleted
          }
        }
        ${FIELDS.PelagoError}
      }
    }
  `,
  queryName: 'wishlistList',
}

export const WISHLIST_DETAIL = {
  query: gql`
  query wishlistDetail($wishlistId: String!) {
    wishlistDetail(wishlistId: $wishlistId) {
        ... on WishlistDetail {
          wishlist {
            wishlistId
            wishlistName
            itemsCount
            isProductSaved
            thumbnail
            isDeleted
            items {
                wishlistItemId
                itemReferenceId
                itemReferenceType
                destinationId
                wishlistedDate
                dateCreated
                dateModified
                productId
                product {
                  ${FIELDS.productCardFields}
                }
            }
        }
        metaData {
            firstName
            isEditable
        }
        }
        ${FIELDS.PelagoError}
      }
    }
  `,
  queryName: 'wishlistDetail',
}

export const WISHLISTED_PRODUCTS = {
  query: gql`
    query wishlistedProducts {
      wishlistedProducts {
        ... on WishlistedProductsOutput {
          productIds
        }
        ${FIELDS.PelagoError}
      }
    }
  `,
  queryName: 'wishlistedProducts',
}

export const WISHLIST_RECOMMENEDED_PRODUCTS = {
  query: gql`
    query wishlistRecommendedProducts($wishlistId: UUID!, $page: Int!, $pageSize: Int!) {
      wishlistRecommendedProducts(wishlistId: $wishlistId, page: $page, pageSize: $pageSize) {
        ... on WishlistRecommendedProducts {
          products {
            productId
            productName
            productUri
            thumbnail
            destinationId
            destinationName
            currency
            rating
            trackMeta
          }
          hasNextPage
        }
        ${FIELDS.PelagoError}
      }
    }
  `,
  queryName: 'wishlistRecommendedProducts',
}
