import React, { createContext, useState, useMemo } from 'react'

interface IHeaderDataContext {
  isTransparent: boolean
  setTransparent: (isTransparent: boolean) => void
  hasSearch: boolean
  setSearchVisibility: (shouldShowSearch: boolean) => void
}

const HeaderDataContext = createContext<IHeaderDataContext>({
  isTransparent: false,
  setTransparent: () => undefined,
  hasSearch: false,
  setSearchVisibility: () => undefined,
})

const HeaderDataProvider = (props: any) => {
  const [isTransparentHeader, setTransparentHeader] = useState(false)
  const [isSearchVisible, setSearchVisibility] = useState(false)

  const value = useMemo<IHeaderDataContext>(
    () => ({
      isTransparent: isTransparentHeader,
      setTransparent: setTransparentHeader,
      hasSearch: isSearchVisible,
      setSearchVisibility: setSearchVisibility,
    }),
    [isTransparentHeader, isSearchVisible]
  )

  return <HeaderDataContext.Provider value={value} {...props} />
}

function useHeaderData() {
  const context = React.useContext(HeaderDataContext)
  if (context === undefined) {
    throw new Error('useHeaderData must be used within a HeaderDataProvider')
  }
  return context
}

export { HeaderDataProvider, useHeaderData }
