import { useRouter } from 'next/router'

export function isRouteMatch(currentPath: string, pathToMatchWith: string) {
  const currPathname = currentPath.substr(-1) !== '/' ? `${currentPath}/` : currentPath
  const routePathname = pathToMatchWith.substr(-1) !== '/' ? `${pathToMatchWith}/` : pathToMatchWith

  return currPathname === routePathname
}

// pathname =>  from route path const file
const useRouteMatch = (pathname: string) => {
  const router = useRouter()

  return isRouteMatch(router.pathname, pathname)
}

export default useRouteMatch
