import cn from 'classnames'

import { Icon } from 'ui/icon'

import { ExternalLink } from 'components/external-link'
import { LayoutButton as Button } from 'components/layout/button'
import Settings from 'components/settings'

import useTranslation from 'lib/hooks/useTranslation'

import { useGlobalContext } from 'lib/context/global-context'

import { HELPCENTER_LINK } from 'lib/constants'
import { EVENTS } from 'lib/constants/events'

import { DropdownButton } from '../dropdown-button'

import s from './styles.module.scss'

const HelpCenter = ({ variant, trackEvent, transparentBg = false }: any) => {
  const { t } = useTranslation('common')
  const { yellowAi } = useGlobalContext()

  if (variant === 'only-help-link') {
    return (
      <div className={cn(s.headerNavItem, s.accountDropdown)}>
        <ExternalLink href={HELPCENTER_LINK}>
          <Button
            iconName="question"
            labelResponsive
            className={cn(s.helpcenterIcon, { [s.transparentBtn]: transparentBg })}
          >
            {t('t.support', { ns: 'common' })}
          </Button>
        </ExternalLink>
      </div>
    )
  }

  return (
    <>
      <DropdownButton
        iconName="question"
        labelResponsive
        className={cn(s.headerNavItem, s.accountDropdown)}
        dropdownContainerClassName={s.helpContent}
        dropdown={
          <Settings custom>
            <div
              key="virtual-assistant"
              onClick={() => {
                yellowAi.showAndToggle()

                trackEvent.current?.({
                  attributeId: EVENTS.HEADER,
                  attributeType: EVENTS.ATTRIBUTES_TYPE.CHATBOT,
                })
              }}
            >
              <Icon name="chat-smile" size="small" />
              {t('t.virtualAssistant', { ns: 'common' })}
            </div>
            <ExternalLink
              key="help"
              href={HELPCENTER_LINK}
              onClick={() => {
                trackEvent.current?.({
                  link: HELPCENTER_LINK,
                  attributeId: EVENTS.HEADER_HELP,
                  attributeType: EVENTS.ATTRIBUTES_TYPE.LINK,
                })
              }}
            >
              <Icon name="information-outline" size="small" />
              {t('t.helpCenter', { ns: 'common' })}
            </ExternalLink>
          </Settings>
        }
      >
        {t('t.support', { ns: 'common' })}
      </DropdownButton>
    </>
  )
}

export default HelpCenter
