import cn from 'classnames'

import s from './styles.module.scss'

const Loader = ({ variant }: { variant?: 'first-fold' | 'default' | 'icon' } = { variant: 'default' }) => {
  return (
    <div
      className={cn(s.loaderContainer, {
        [s.isFirstFold]: variant === 'first-fold',
        [s.isIcon]: variant === 'icon',
      })}
    >
      <div className={s.loader}></div>
    </div>
  )
}

export { Loader }
