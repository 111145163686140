import React, { FC } from 'react'
import cn from 'classnames'

import { Button } from 'ui/button'
import { Icon } from 'ui/icon'

import { Loader } from '../loader'

import s from './styles.module.scss'

interface HeaderInputProps {
  placeholder?: string
  value: string
  inputRef?: React.Ref<HTMLInputElement>
  asIcon?: boolean
  loading?: boolean
  onClear: (e: React.MouseEvent<HTMLButtonElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
}

const HeaderInput: FC<HeaderInputProps> = ({
  value,
  onChange,
  onClear,
  onClick,
  inputRef,
  placeholder,
  asIcon,
  loading,
}) => {
  if (asIcon)
    return (
      <div className={s.asSearchIconContainer} onClick={onClick}>
        {!loading ? <Icon size="medium" name="magnifying-glass" /> : <Loader variant="icon" />}
      </div>
    )

  const className = cn(s.headerInputContainer, {
    [s['with-value']]: !!value,
  })

  return (
    <div className={className} onClick={onClick}>
      {!loading ? <Icon size="medium" name="magnifying-glass" className={s.searchIcon} /> : <Loader />}
      <input ref={inputRef} value={value} placeholder={placeholder} onChange={onChange} />
      {/* eslint-disable jsx-a11y/control-has-associated-label */}
      {value && (
        <Button
          iconName="delete-x"
          shape="circle"
          variant="tertiary-grey"
          className={s.clearButton}
          onClick={onClear}
        />
      )}
    </div>
  )
}

export { HeaderInput }
