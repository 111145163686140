import { isServer, logError } from 'lib/utils'

// TODO: Sachin/Akarsh - Strategy in case localStorage is not supported

export const getStorageItem = (key: string) => {
  if (isServer) return null

  let item
  try {
    item = localStorage?.getItem?.(key)
  } catch (e) {
    logError(e)
  }
  return item
}

export const setStorageItem = (key: string, value: any) => {
  if (isServer) return

  try {
    localStorage?.setItem?.(key, value)
  } catch (e) {
    logError(e)
  }
}

export const removeStorageItem = (key: string) => {
  if (isServer) return

  try {
    localStorage?.removeItem?.(key)
  } catch (e) {
    logError(e)
  }
}

export const getSession = (key: string) => {
  if (isServer) return null

  try {
    return sessionStorage.getItem(key)
  } catch (e) {
    logError(e)
  }
}

export const setSession = (key: string, value: any) => {
  if (isServer) return null

  try {
    return sessionStorage.setItem(key, value)
  } catch (e) {
    logError(e)
  }
}

export const removeSession = (key: string) => {
  if (isServer) return null

  try {
    return sessionStorage.removeItem(key)
  } catch (e) {
    logError(e)
  }
}
