import cn from 'classnames'

import { Image } from 'ui/image'

import { ExternalLink } from 'components/external-link'

import s from './styles.module.scss'

const PartnerLogo = ({
  isWhiteLabel,
  whiteLabelPartner,
  visible,
}: {
  isWhiteLabel: boolean
  whiteLabelPartner: WhiteLabel | null
  visible?: boolean
}) => {
  if (isWhiteLabel && whiteLabelPartner) {
    return (
      <div
        className={cn(s.isPartner, {
          [s.hide]: visible,
        })}
      >
        <ExternalLink href={whiteLabelPartner.partnerWebsiteLink}>
          <Image
            src={{ url: whiteLabelPartner.imageUrl }}
            layout="fill"
            lazyLoad={false}
            alt={whiteLabelPartner.partnerName}
            placeHolder={<span />}
          />
        </ExternalLink>
      </div>
    )
  }
  return null
}

export { PartnerLogo }
