import { Portal } from 'components/portal'
import { Toast } from 'components/toast'

import { ToastItem } from 'lib/@Types'
import { useAppData } from 'lib/context/app-data-context'

const ToastContainer = () => {
  const { toast } = useAppData()

  const handleClose = (item: ToastItem) => {
    item.onClose?.()
    toast.remove(item.id)
  }

  return (
    <Portal open={!!toast.items.length}>
      {toast?.items?.map((item) => (
        <Toast key={item.id} {...item} onClose={() => handleClose(item)} />
      ))}
    </Portal>
  )
}

export { ToastContainer }
