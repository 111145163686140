import { gql } from '@apollo/client'
import { PelagoError } from 'gql/fragments'

export const RESET_PASSWORD_REQUEST_MUTATION = {
  mutation: gql`
    mutation resetPasswordRequest(
      $email: String!
    ) {
      resetPasswordRequest(
        email: $email
      ) {
        ...on PelagoSuccess {
          code
        }
        ${PelagoError}
      }
    }
  `,
  mutationName: 'resetPasswordRequest',
}
