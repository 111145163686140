import React, { FC } from 'react'
import cn from 'classnames'

import { Button } from 'ui/button'

import { ExternalLink } from 'components/external-link'

import useTranslation from 'lib/hooks/useTranslation'
import useAuth from 'lib/hooks/useAuth'

import { useAppData } from 'lib/context/app-data-context'

import { EVENTS } from 'lib/constants/events'
import { PROMOTIONS_LINK } from 'lib/constants'

import Gift from 'brand-assets/illustrations/icons/gift.svg'

import s from './styles.module.scss'

interface AuthenticateCtaSectionProps {
  onClick?: () => void
}

const AuthenticateCtaSection: FC<AuthenticateCtaSectionProps> = ({ onClick }) => {
  const { trackEvent } = useAppData()
  const { t } = useTranslation('common')
  const { authModal } = useAuth()

  return (
    <div className={cn(s.container)}>
      <Gift className={s.image} />

      <div className={s.contentRight}>
        <p className={s.text}>
          {t('msg.signUpToEnjoyDiscountOffYourFirstBookings', { ns: 'common', multiply: 2, discount: 10 })}
        </p>
        <p className={s.tncLink}>
          <ExternalLink href={PROMOTIONS_LINK}>{t('t.tAndCApply', { ns: 'common' })}</ExternalLink>
        </p>

        <div className={s.ctaContainer}>
          <Button
            variant="primary"
            size="small"
            className={s.signUpCta}
            onClick={() => {
              trackEvent.current?.({
                attributeId: EVENTS.FROM_SETTINGS_SIGNUP,
                attributeType: EVENTS.ATTRIBUTES_TYPE.LINK,
              })
              authModal.open({ screen: 'signup' })
              onClick?.()
            }}
          >
            {t('t.signUp')}
          </Button>
          <Button
            variant="secondary"
            size="small"
            onClick={() => {
              trackEvent.current?.({
                attributeId: EVENTS.FROM_SETTINGS_LOGIN,
                attributeType: EVENTS.ATTRIBUTES_TYPE.LINK,
              })
              authModal.open({ screen: 'login' })
              onClick?.()
            }}
          >
            {t('t.logIn')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export { AuthenticateCtaSection }
