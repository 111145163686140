import { useCallback } from 'react'
import { useRouter } from 'next/router'
import { useMutation } from '@apollo/client'

import { useGlobalContext } from 'lib/context/global-context'
import { getCookieCurrency, getError, logError, setCookieCurrency, setCookieUserLocale } from 'lib/utils'

import { BOOKING_DETAILS_ROUTE } from 'lib/constants/routes'
import { FALLBACK_LOCALE } from 'lib/constants'

import { PROFILE_MUTATION } from 'gql/mutations/profile'

import localeInfo from '../../locale-info.json'
import useRouteMatch from './useRouteMatch'
import useAuth from './useAuth'

function useUserPreference() {
  const router = useRouter()
  const { user } = useAuth()
  const { globalArgs: { currencies = [] } = {} } = useGlobalContext()
  const { handleUserResponse } = useAuth()
  const [editProfile] = useMutation(PROFILE_MUTATION.mutation)
  const isBookingIntentPage = Boolean(useRouteMatch(BOOKING_DETAILS_ROUTE))

  const updateUserPreference = async ({ currency, locale }: { currency?: string; locale?: string }) => {
    try {
      const details: { currency?: string; locale?: string } = {}

      if (currency) details.currency = currency
      if (locale) {
        details.locale = locale
      }
      const { data } = await editProfile({ variables: { details } })
      const response = data?.[PROFILE_MUTATION.mutationName]

      const error = getError(response)
      if (error?.code) throw error

      handleUserResponse({ customer: response })
    } catch (error) {
      logError(error)
    }
  }

  const applyUserPreferences = (user: Customer) => {
    const validUserLocale = router.locales?.find((l) => l?.toLowerCase() === user?.locale?.toLowerCase())
    if (validUserLocale) {
      setCookieUserLocale(user.locale)
    }
    const validUserCurrency = currencies.find((currency) => currency.currencyId === user?.currency)
    if (validUserCurrency?.currencyId && validUserCurrency?.currencyId !== getCookieCurrency()) {
      // handle a case where user checkout product with currency USD (load primer for USD currency)
      // on DBF, guest user signed-in which refresh the page with the currency attached to his profile (for example SGD : loads stripe)
      // before signin state -> USD, after signin state -> SGD
      // which leads to attached payment for that checked out product into invalid state
      // hence don't override the cookie currency if the user is on the booking intent page
      if (isBookingIntentPage) return

      setCookieCurrency(user.currency)
    }
  }

  const getDerivedLocale = useCallback(
    (languageOrLocale: string): string => {
      // already passed locale as param, no need to derive it from language id
      if (languageOrLocale.includes('-')) return languageOrLocale
      // user is already on the selected language route
      if (router.locale?.includes(languageOrLocale)) return router.locale

      let newLocale = localeInfo.supportedLocales.find(
        (_locale) => _locale === `${languageOrLocale}-${user?.countryOfResidence}`
      )
      if (!newLocale) {
        if (languageOrLocale === 'en') newLocale = FALLBACK_LOCALE
        else
          newLocale =
            localeInfo.supportedLocales.find((_locale) => _locale.includes(languageOrLocale)) ||
            FALLBACK_LOCALE
      }
      return newLocale || FALLBACK_LOCALE
    },
    [router.locale, user?.countryOfResidence]
  )

  return {
    getDerivedLocale,
    updateUserPreference,
    applyUserPreferences,
  }
}

export default useUserPreference
