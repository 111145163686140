import cn from 'classnames'

import { Checkbox } from 'ui/checkbox'
import { Image } from 'ui/image'

import useTranslation from 'lib/hooks/useTranslation'

import s from './styles.module.scss'

interface CommonProps {
  id: string
  count: number
  name: string
  thumbnail: MediaItem
  onClick: (id: string) => void
}

interface VerticalCard extends CommonProps {
  variant?: 'vertical-card'
  checked?: never
}

export interface ChecklistCard extends CommonProps {
  variant?: 'checklist'
  checked?: boolean
}

type WishlistCardProps = VerticalCard | ChecklistCard

const WishlistCard: React.FC<WishlistCardProps> = ({
  id,
  count,
  name,
  thumbnail,
  onClick,
  checked,
  variant = 'vertical',
}) => {
  const { t } = useTranslation()

  return (
    <div
      className={cn(s.card, s[variant])}
      onClick={() => {
        onClick?.(id)
      }}
    >
      <div className={s.image}>
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <Image src={thumbnail} layout="fill" lazyLoad={false} />
      </div>
      <div className={s.content}>
        <h4 className={s.name}>{name}</h4>
        <p className={s.count}>
          <span>{t('numberOfActivity', { count, ns: 'product' })}</span>
        </p>
      </div>
      {variant === 'checklist' && (
        <Checkbox size="medium" checked={checked} onClick={(e) => e.stopPropagation()} readOnly />
      )}
    </div>
  )
}

export { WishlistCard }
