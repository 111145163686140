import { useCallback, useMemo } from 'react'
import { TOptions } from 'i18next'
import { useTranslation } from 'next-i18next'

import { sentenceCase, smallCase, smartCase } from 'lib/utils'
import { TNamespaces } from 'lib/@Types/translation'

type EnhancedTOptions = TOptions & {
  ns?: TNamespaces
}

const useCustomTranslation = (ns?: string | string[]) => {
  const { t, ...others } = useTranslation(ns)

  const tFn = useCallback(
    (i18nKey: string, options?: EnhancedTOptions) => {
      return t(i18nKey, options)
    },
    [t]
  )

  // space
  const sp = useMemo(() => ' ', [])

  // title case by ignoring conjunctions
  const tsmart = useCallback(
    (i18nKey: string, options?: EnhancedTOptions & { titleCaseLimit?: 1 | 2 | 3 | 'none' }) => {
      const { titleCaseLimit = 3, ..._options } = options || {}
      return smartCase(t(i18nKey, _options), titleCaseLimit)
    },
    [t]
  )

  const tsentence = useCallback(
    (i18nKey: string, options?: EnhancedTOptions) => {
      return sentenceCase(t(i18nKey, options))
    },
    [t]
  )

  // translation with small case
  const tsmall = useCallback(
    (i18nKey: string, options?: EnhancedTOptions) => {
      return smallCase(t(i18nKey, options))
    },
    [t]
  )

  // add punctuation
  const tp = useCallback(
    (i18nKey: string, options?: EnhancedTOptions) => {
      return `${t(i18nKey, options)}${t('lang.punctuation', { ns: 'common' })}`
    },
    [t]
  )

  // make paragraph by giving sentences. space and punctuation will be added by this function
  const join = useCallback(
    (
      translatedTexts: string[],
      {
        punctuation = t('lang.punctuation', { ns: 'common' }),
        endsWithPunctuation = true,
      }: { punctuation?: string; endsWithPunctuation?: boolean } = {}
    ) => {
      return `${translatedTexts.join(`${punctuation}${sp}`)}${endsWithPunctuation ? punctuation : ''}`
    },
    [t, sp]
  )

  return { t: tFn, ...others, tp, join, sp, tsmart, tsmall, tsentence }
}

export default useCustomTranslation
