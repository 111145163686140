import { useCallback, useEffect, useRef, useState } from 'react'

import _useLayoutEffect from './useLayoutEffect'

const useModal = ({
  open = false,
  ignorePageScroll = false,
  onClickOutside,
  onEscKeyPress,
}: {
  open: boolean
  ignorePageScroll?: boolean
  onClickOutside?: () => void
  onEscKeyPress?: () => void
}) => {
  const overlayRef = useRef<HTMLDivElement | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const clickOutsideHandlerRef = useRef<() => void>()
  const escKeyPressHandlerRef = useRef<() => void>()

  const [activateAnimation, setActivateAnimation] = useState<boolean>(false)
  // For animation purpose only
  const [delayOpen, setDelayOpen] = useState<boolean>(false)

  clickOutsideHandlerRef.current = onClickOutside
  escKeyPressHandlerRef.current = onEscKeyPress

  _useLayoutEffect(() => {
    if (ignorePageScroll) return

    document?.body?.classList.toggle('overflow-hidden', open)

    return () => {
      document?.body?.classList.toggle('overflow-hidden', false)
    }
  }, [open, ignorePageScroll])

  useEffect(() => {
    if (!open) {
      setActivateAnimation(false)
      return
    }
    setTimeout(() => {
      setActivateAnimation(true)
    }, 100) // wait for 100ms for mounting so that we can apply transition
  }, [open])

  useEffect(() => {
    if (open) {
      setDelayOpen(true)
      return
    } else {
      setTimeout(() => {
        setDelayOpen(false)
      }, 300)
    }
  }, [open])

  const outsideClickEventCallback = useCallback((event: any) => {
    if (!containerRef.current?.contains(event?.target)) {
      event.stopPropagation()
      clickOutsideHandlerRef.current?.()
    }
  }, [])

  // unregister outside click event
  // order matters - 1st in order
  useEffect(() => {
    return () => {
      overlayRef.current?.removeEventListener('click', outsideClickEventCallback)
    }
  }, [open, outsideClickEventCallback])

  // register outside click event
  // order matters - 2nd in order
  const outSideClickHandler = useCallback(() => {
    // this will stop register again after unregister if modal is closed
    if (!open) return

    overlayRef.current?.addEventListener('click', outsideClickEventCallback)
  }, [open, outsideClickEventCallback])

  // outside click handler
  const overlayRefCallback = useCallback(
    (node: HTMLDivElement) => {
      if (!node) return

      overlayRef.current = node
      outSideClickHandler()
    },
    [outSideClickHandler]
  )

  const containerRefCallback = useCallback((node: HTMLDivElement) => {
    if (!node) return

    containerRef.current = node
  }, [])

  // esc key press handler
  // current implementation will trigger onEscKeyPress on all active modals
  useEffect(() => {
    if (!open) return

    const eventCallback = (event: KeyboardEvent) => {
      event.stopPropagation()

      if (event.key === 'Escape') escKeyPressHandlerRef.current?.()
    }
    window.addEventListener('keydown', eventCallback)

    return () => window.removeEventListener('keydown', eventCallback)
  }, [open])

  return {
    overlayRef: overlayRefCallback,
    containerRef: containerRefCallback,
    activateAnimation,
    isOpen: delayOpen,
    getOverlayRef: overlayRef,
  }
}

export { useModal }
