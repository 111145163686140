import { PelagoError } from 'gql/fragments'

export const DYNAMIC_COMPONENTS_DATA_QUERY_BODY = `
  dynamicComponentsData(dynamicComponentsInput: $dynamicComponentsInput) {
    ... on DynamicComponentOutput {
      componentList {
        componentId
        componentName
        componentConfig
        componentContent
        componentModifiers
        componentTrackMeta
        componentType
        componentEventId
        componentPage
        componentRank
        componentMetaType
        destinationId
        countryId
      }
      details {
        destination {
          destinationId
          destinationCode
          destinationName
          destinationUri
          isActive
          countryId
        }
        country {
          countryId
          countryCode
          countryName
          countryUri
          isActive
          regionId
        }
      }
    }
    ${PelagoError}
  }
`
