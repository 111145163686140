import React, { FC, useEffect } from 'react'
import cn from 'classnames'
import { useRouter } from 'next/router'

import Breadcrumbs from 'components/bread-crumbs'

import { SummaryDrawer } from 'page-modules/articles/detail-page/summary-drawer'

import { LayoutAdditionalParams } from 'lib/@Types/layout'
import { BlogDataProvider } from 'lib/context/blog-context'
import { useAppData } from 'lib/context/app-data-context'

import Footer from '../footer'
import Header, { HeaderProps } from '../header'

interface MainLayoutProps {
  noHeader?: boolean
  noFooter?: boolean
  className?: string
  notification?: any
  hasLangCurrSelector?: boolean
  hasSearch?: boolean
  variant?: HeaderProps['variant']
  additionalParams?: LayoutAdditionalParams
}

interface ContentProps {
  className?: string
  whiteBg?: boolean
  breadcrumbs?: Breadcrumb[]
  breadcrumbsLoading?: boolean
}

interface BlogContentProps {
  className?: string
  whiteBg?: boolean
  hasSummary?: boolean
  breadcrumbs?: never
  breadcrumbsLoading?: never
}

const MainLayout: FC<MainLayoutProps> & { Content: FC<ContentProps>; BlogContent: FC<BlogContentProps> } = ({
  noHeader,
  noFooter,
  children,
  className,
  notification,
  hasLangCurrSelector,
  hasSearch,
  variant,
  additionalParams,
}) => {
  const { nonStickyHeader, isBlogPage, ...restAdditionalParams } = additionalParams || {}
  const { pathname } = useRouter()
  const {
    partnerFeatureControl: { showFeature },
  } = useAppData()

  // User can go to any page after "/support" page, try to close zendesk widget after that
  useEffect(() => {
    if (pathname !== '/support') {
      if (!window || !window?.zE) return

      try {
        window?.zE('webWidget', 'hide')
      } catch (e) {}
    }
  }, [pathname])

  return (
    <div className={className}>
      {!noHeader && (
        <Header
          hasLangCurrSelector={hasLangCurrSelector}
          hasSearch={hasSearch}
          variant={variant}
          isNonSticky={nonStickyHeader}
          additionalParams={restAdditionalParams}
          notification={notification}
          isBlogPage={isBlogPage}
        />
      )}
      {children}
      {showFeature('footer') ? (
        !noFooter && <Footer isBlogPage={isBlogPage} />
      ) : (
        <>
          {/* For partner views, add generic space when we are not showing footer */}
          {!noFooter && <div className="mt-16"></div>}
        </>
      )}
    </div>
  )
}

MainLayout.Content = ({ children, whiteBg, className, breadcrumbs, breadcrumbsLoading }) => (
  <div className={cn('l-main', { 'l-main--white-bg': whiteBg }, className)}>
    {breadcrumbs && <Breadcrumbs levels={breadcrumbs} />}
    {breadcrumbsLoading && <Breadcrumbs loading />}
    <div className="l-main__content">{children}</div>
  </div>
)
MainLayout.Content.displayName = 'MainLayoutContent'

MainLayout.BlogContent = ({ children, whiteBg, className, hasSummary }) => {
  return (
    <div className={cn('l-main', 'l-main--auto-min-height', { 'l-main--white-bg': whiteBg }, className)}>
      <BlogDataProvider>
        <div className="l-main__content">
          <div className="l-main__blog">
            <div className="l-main__blog__columns">{children}</div>
          </div>
        </div>
        {hasSummary && <SummaryDrawer />}
      </BlogDataProvider>
    </div>
  )
}
MainLayout.BlogContent.displayName = 'MainLayoutBlogContent'

export default MainLayout
