import useTranslation from 'lib/hooks/useTranslation'

import s from './styles.module.scss'

const Loader = () => {
  const { t } = useTranslation()

  return (
    <div className={s.info}>
      <div className={s.loadingText}>
        <span>{t('t.searching', { ns: 'common' })}</span>
        {/* eslint-disable-next-line i18next/no-literal-string */}
        <span className={s.dots}>…</span>
      </div>
    </div>
  )
}

export { Loader }
