import { MutableRefObject, useMemo } from 'react'
import cn from 'classnames'

import { Link } from 'components/link'

import { buildPath } from 'lib/utils'

import { HOME_ROUTE } from 'lib/constants/routes'
import { EVENTS } from 'lib/constants/events'

import PelagoWhiteLogoDesktop from 'brand-assets/illustrations/logo/pelago-by-sg-airline-desktop-white-yellow.svg'
import PelagoBlogLogoDesktop from 'brand-assets/illustrations/logo/pelago-blog-yellow.svg'

import s from './styles.module.scss'

const DesktopLogo = ({
  isBlogPage = false,
  hasPartnerLogo = false,
  trackEvent,
  onCloseMenu,
}: {
  isBlogPage?: boolean
  hasPartnerLogo?: boolean
  trackEvent?: MutableRefObject<TrackEventType>
  onCloseMenu?: () => void
}) => {
  const [Logo, isMiniLogo] = useMemo(() => {
    return [
      isBlogPage || hasPartnerLogo ? PelagoBlogLogoDesktop : PelagoWhiteLogoDesktop,
      isBlogPage || hasPartnerLogo,
    ]
  }, [isBlogPage, hasPartnerLogo])

  return (
    <div className={cn(s.headerNavLeft, { [s.hasMiniLogo]: isMiniLogo })}>
      <Link href={buildPath(HOME_ROUTE)} passHref>
        <a
          className={cn(s.headerLogo, s.headerNavItem)}
          onClick={() => {
            trackEvent?.current?.({
              attributeId: EVENTS.HEADER_LOGO,
              attributeType: EVENTS.ATTRIBUTES_TYPE.LINK,
            })
            onCloseMenu?.()
          }}
        >
          <Logo alt="Pelago Brand Logo" className={s['pelago-white-logo']} />
        </a>
      </Link>
    </div>
  )
}

export { DesktopLogo }
