import { createContext, useEffect, useContext, useMemo, ReactNode, useCallback, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useRouter } from 'next/router'

import { CountryDestInfo } from 'components/country-dest-filter/types'

import { PageCountry, PageDestination, DestinationTree } from 'lib/@Types'
import { noop } from 'lib/utils'
import { isIsrPageRequest } from 'lib/utils/middleware'

import { LOCALE_EN_US } from 'lib/constants'

import { COUNTRIES_QUERY, DESTINATIONS_QUERY, DESTINATION_TREE_QUERY } from 'gql'

type DestinationCountryContextType = {
  destinations: Record<string, PageDestination>
  fallbackDestinations: Record<string, PageDestination>
  countries: Record<string, PageCountry>
  fallbackCountries: Record<string, PageCountry>
  countriesByCode: Record<string, PageCountry>
  destinationTree: DestinationTree
  isLoading: boolean
  countryWithDestinations: CountryDestInfo[]
  getDestinationInfo: (
    destinationId: string,
    defaultLocaleValues?: Record<string, string>
  ) => PageDestination | undefined
}

type DestinationCountryProviderProps = {
  children: ReactNode
}

const DestinationCountryContext = createContext<DestinationCountryContextType>({
  destinations: {},
  fallbackDestinations: {},
  fallbackCountries: {},
  countries: {},
  countriesByCode: {},
  destinationTree: {},
  isLoading: true,
  countryWithDestinations: [],
  getDestinationInfo: noop,
})

export const useDestinationCountryContext = () => {
  const context = useContext(DestinationCountryContext)
  if (!context) {
    throw new Error('useDestinationCountryContext must be used within DestinationCountryProvider')
  }

  return context
}

export const DestinationCountryProvider = ({ children }: DestinationCountryProviderProps) => {
  const [destinations, setDestinations] = useState<PageDestination[]>()
  const [fallbackDestinations, setFallbackDestinations] = useState<PageDestination[]>()

  const [countries, setCountries] = useState<PageCountry[]>()
  const [fallbackCountries, setFallbackCountries] = useState<PageCountry[]>()

  const [fetchFallbackDestinations] = useLazyQuery(DESTINATIONS_QUERY.query, {
    context: {
      queryDeduplication: false,
      headers: {
        'X-Locale': LOCALE_EN_US,
      },
    },
  })

  const [fetchFallbackCountries] = useLazyQuery(COUNTRIES_QUERY.query, {
    context: {
      queryDeduplication: false,
      headers: {
        'X-Locale': LOCALE_EN_US,
      },
    },
  })

  const [fetchDestinations, { loading: destinationLoading }] = useLazyQuery(DESTINATIONS_QUERY.query)
  const [fetchCountries, { loading: countriesLoading }] = useLazyQuery(COUNTRIES_QUERY.query)
  const [fetchDestinationTree, { data: destinationTreeResponse, loading: destinationTreeLoading }] =
    useLazyQuery(DESTINATION_TREE_QUERY.query)

  const router = useRouter()
  const isArticlePath = isIsrPageRequest(router.asPath)

  useEffect(() => {
    const fetchDestinationCountryData = async () => {
      const [{ data: destinationResponse }, { data: countriesResponse }] = await Promise.all([
        fetchDestinations(),
        fetchCountries(),
        fetchDestinationTree(),
      ])

      const destinationsData = destinationResponse?.[DESTINATIONS_QUERY.queryName] as
        | PageDestination[]
        | undefined

      const countriesData = countriesResponse?.[COUNTRIES_QUERY.queryName] as PageCountry[] | undefined

      setDestinations(destinationsData)
      setCountries(countriesData)
    }

    fetchDestinationCountryData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const fetchFallbackDestination = async () => {
      if (fallbackDestinations) {
        return
      }

      if (!router.locale?.includes('en') && isArticlePath) {
        const { data: fallbackDestinationsResponse } = await fetchFallbackDestinations()
        const fallbackDestinationsData = fallbackDestinationsResponse?.[DESTINATIONS_QUERY.queryName] as
          | PageDestination[]
          | undefined

        setFallbackDestinations(fallbackDestinationsData)
      } else if (destinations) {
        setFallbackDestinations(destinations)
      }
    }

    fetchFallbackDestination()
  }, [destinations, router.locale, fetchFallbackDestinations, isArticlePath, fallbackDestinations])

  useEffect(() => {
    const fetchFallbackCountryData = async () => {
      if (fallbackCountries) {
        return
      }

      if (!router.locale?.includes('en') && isArticlePath) {
        const { data: fallbackCountriesResponse } = await fetchFallbackCountries()
        const fallbackCountriesData = fallbackCountriesResponse?.[COUNTRIES_QUERY.queryName] as
          | PageCountry[]
          | undefined

        setFallbackCountries(fallbackCountriesData)
      } else if (countries) {
        setFallbackCountries(countries)
      }
    }

    fetchFallbackCountryData()
  }, [countries, router.locale, fetchFallbackCountries, isArticlePath, fallbackCountries])

  const destinationMapping = useMemo(() => {
    if (!destinations) return {}

    return destinations.reduce((accum, destination) => {
      accum[destination.destinationId] = destination
      return accum
    }, {} as Record<string, PageDestination>)
  }, [destinations])

  const fallbackDestinationMapping = useMemo(() => {
    if (!fallbackDestinations) return {}

    return fallbackDestinations.reduce((accum, destination) => {
      accum[destination.destinationId] = destination
      return accum
    }, {} as Record<string, PageDestination>)
  }, [fallbackDestinations])

  const { countriesMapping, countryCodesMapping } = useMemo(() => {
    if (!countries)
      return {
        countriesMapping: {},
        countryCodesMapping: {},
      }

    const countriesMapping: Record<string, PageCountry> = {}
    const countryCodesMapping: Record<string, PageCountry> = {}

    countries.forEach((country) => {
      countriesMapping[country.countryId] = country
      countryCodesMapping[country.countryCode] = country
    })

    return {
      countriesMapping,
      countryCodesMapping,
    }
  }, [countries])

  const fallbackCountriesMapping = useMemo(() => {
    if (!fallbackCountries) return {}

    return fallbackCountries.reduce((accum, country) => {
      accum[country.countryId] = country
      return accum
    }, {} as Record<string, PageCountry>)
  }, [fallbackCountries])

  const destinationTree = useMemo(() => {
    return (destinationTreeResponse?.[DESTINATION_TREE_QUERY.queryName] || {}) as DestinationTree
  }, [destinationTreeResponse])

  const isLoading = destinationLoading || countriesLoading || destinationTreeLoading

  const getDestinationInfo = useCallback(
    (destId: string) => {
      if (isArticlePath) {
        return fallbackDestinationMapping[destId]
      }

      return destinationMapping[destId]
    },
    [destinationMapping, fallbackDestinationMapping, isArticlePath]
  )

  const countryWithDestinations = useMemo(() => {
    if (isLoading || !destinations) return []

    const info = Object.entries(destinationTree).reduce((accum, [countryCode, destinationIds]) => {
      const countriesMap = isArticlePath ? fallbackCountriesMapping : countriesMapping

      const data = {
        countryCode,
        countryNumericCode: countriesMap?.[countryCode]?.countryCode,
        countryName: countriesMap?.[countryCode]?.countryName,
        countryId: countriesMap?.[countryCode]?.countryUri,
        destinations: destinationIds.reduce((destinationAcc, destinationId) => {
          const destination = getDestinationInfo(destinationId)
          if (destination && destination.isActive) {
            destinationAcc.push(destination)
          }

          return destinationAcc
        }, [] as PageDestination[]),
      }

      accum.push(data)

      return accum
    }, [] as CountryDestInfo[])

    return info.sort((a, b) => a?.countryName?.localeCompare(b?.countryName))
  }, [
    destinations,
    destinationTree,
    countriesMapping,
    fallbackCountriesMapping,
    isArticlePath,
    getDestinationInfo,
    isLoading,
  ])

  const value = useMemo(() => {
    return {
      destinations: destinationMapping,
      fallbackDestinations: fallbackDestinationMapping,
      countries: countriesMapping,
      fallbackCountries: fallbackCountriesMapping,
      countriesByCode: countryCodesMapping,
      destinationTree,
      isLoading,
      countryWithDestinations,
      getDestinationInfo,
    }
  }, [
    destinationMapping,
    fallbackDestinationMapping,
    countriesMapping,
    fallbackCountriesMapping,
    countryCodesMapping,
    destinationTree,
    isLoading,
    countryWithDestinations,
    getDestinationInfo,
  ])

  return <DestinationCountryContext.Provider value={value}>{children}</DestinationCountryContext.Provider>
}
