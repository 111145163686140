export const sift = {
  setUserId: (userId: string) => window?._sift?.push?.(['_setUserId', userId]),

  setSessionId: (sessionId: string) => window._sift?.push?.(['_setSessionId', sessionId]),
  // events
  events: {
    pageView: () => {
      window?._sift?.push?.(['_trackPageview'])
    },
  },
}
