import { useState } from 'react'

const useSearchModal = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [loader, setLoader] = useState(false)

  const openSearchModal = (props: { showLoader?: boolean } = { showLoader: true }) => {
    if (props?.showLoader) {
      setLoader(true)
    }

    setIsOpen(true)
  }

  const closeSearchModal = () => {
    setIsOpen(false)
    setLoader(false)
  }

  return {
    openSearchModal,
    closeSearchModal,
    isSearchModalOpen: isOpen,
    loader,
  }
}

export { useSearchModal }
