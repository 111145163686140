import Script from 'next/script'

import { useAppData } from 'lib/context/app-data-context'

const GoogleTagManager = () => {
  const { enableGtm } = useAppData()
  if (!enableGtm) return null

  return (
    <Script id="gtm-script" strategy="afterInteractive">
      {`
(function (w, d, s, l, i) {
w[l] = w[l] || [];
w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
var f = d.getElementsByTagName(s)[0],
j = d.createElement(s),
dl = l != "dataLayer" ? "&l=" + l : "";
j.async = true;
j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
var n = d.querySelector("[nonce]");
n && j.setAttribute("nonce", n.nonce || n.getAttribute("nonce"));
f.parentNode.insertBefore(j, f);
})(window, document, "script", "dataLayer", "${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_KEY}");
`}
    </Script>
  )
}

export { GoogleTagManager }
