import { FC } from 'react'

interface ExternalLinkProps {
  href: string
  className?: string
  target?: string
  noRel?: boolean
  onClick?: (e: any) => void
}

const ExternalLink: FC<ExternalLinkProps> = ({
  children,
  noRel = false,
  target = '_blank',
  ...restProps
}) => (
  <a target={target} {...(noRel ? {} : { rel: 'noopener noreferrer' })} {...restProps}>
    {children}
  </a>
)

export { ExternalLink }
