import React from 'react'
import cn from 'classnames'

import { Icon } from 'ui/icon'

import { IconNames } from 'brand-assets/icon-lib'

import s from './styles.module.scss'

interface Item {
  id: string
  label?: string
  content?: React.ReactNode
}

interface CommonProps {
  active?: boolean
  title?: string
  titleIconName?: IconNames
  noHeaderOffset?: boolean
  noBackButton?: boolean
  belowHeader?: boolean
  onClose: () => void
}

type ConditionalProps =
  | {
      children: React.ReactNode
      items?: never
      onSelect?: never
      selectedId?: never
    }
  | {
      selectedId: string
      items: Item[]
      onSelect: (item: Item) => void
      children?: never
    }

type DrawerProps = CommonProps & ConditionalProps

const Drawer = React.forwardRef<HTMLDivElement, DrawerProps>(
  (
    {
      title,
      titleIconName,
      children,
      active,
      items,
      selectedId,
      noHeaderOffset,
      noBackButton,
      belowHeader,
      onClose,
      onSelect,
    },
    ref
  ) => {
    if (!active) return null

    return (
      <div
        ref={ref}
        className={cn(s.drawer, {
          [s.active]: active,
          [s.noHeaderOffset]: noHeaderOffset,
          [s.belowHeader]: belowHeader,
        })}
      >
        {(!noBackButton || title) && (
          <div className={s.drawerNav}>
            {!noBackButton && (
              <Icon name="caret-left" size="medium" onClick={onClose} className={s.backButton} />
            )}
            {title && (
              <div className={s.drawerTitle}>
                {titleIconName && <Icon name={titleIconName} size="medium" />}
                <h3>{title}</h3>
              </div>
            )}
          </div>
        )}
        <div className={s.drawerContent}>
          {children || (
            <ul className={s.lists}>
              {items?.map?.((item: Item) => (
                <li key={item.label} onClick={() => onSelect?.(item)} className="text-grey-2">
                  {item.content || (
                    <span className={cn('label-sm ', { [s.activeListItem]: item.id === selectedId })}>
                      {item.label}
                    </span>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    )
  }
)

Drawer.displayName = 'Drawer'

export { Drawer }
