import { DrawerDialog } from 'ui/drawer-dialog'
import { Icon, IconNames } from 'ui/icon'

import { ExternalLink } from 'components/external-link'

import useCustomTranslation from 'lib/hooks/useTranslation'

import { useGlobalContext } from 'lib/context/global-context'

import { AFFILIATE_PARTNER_LINK, SUPPLY_PARTNER_LINK } from 'lib/constants'

import s from './styles.module.scss'

const PartnerModal = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const { isMobileView } = useGlobalContext()
  const { t } = useCustomTranslation('common')

  const items: { icon: IconNames; label: string; link: string }[] = [
    { icon: 'ticket', label: t('t.joinAsSupplyPartner'), link: SUPPLY_PARTNER_LINK },
    { icon: 'user-switch', label: t('t.joinAsAffiliatePartner'), link: AFFILIATE_PARTNER_LINK },
  ]

  return (
    <DrawerDialog
      open={open}
      variant={isMobileView ? 'xsmall' : 'small'}
      autoMinHeight
      sideSpace={isMobileView}
      header={t('t.partnerWithUs')}
      onClose={onClose}
    >
      {items.map((item, index) => (
        <ExternalLink href={item.link} key={index} className={s.row}>
          <Icon name={item.icon} size="medium" />
          <div className={'bold label-md'}>{item.label}</div>
        </ExternalLink>
      ))}
    </DrawerDialog>
  )
}

export { PartnerModal }
