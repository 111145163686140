import { NextRequest } from 'next/server'

import { ARTICLE_HOME_ROUTE } from 'lib/constants/routes'

export const isPagePathRequest = (request: NextRequest) => {
  const isNonPagePathPrefix = /^\/(?:_next|api)\//
  const isFile = /\..*$/
  const { pathname } = request.nextUrl
  return !isNonPagePathPrefix.test(pathname) && !isFile.test(pathname)
}

export const isIsrPageRequest = (pathName: string) => {
  // ARTICLE_HOME_ROUTE,
  // ARTICLE_COUNTRY_ROUTE,
  // ARTICLE_DESTINATION_ROUTE,
  // ARTICLE_DETAIL_ROUTE
  // above routes are ISR pages, checking only HOME_ROUTE is enough as all ISR routes prefixed with HOME_ROUTE
  return pathName.startsWith(ARTICLE_HOME_ROUTE)
}
