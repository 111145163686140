import cn from 'classnames'

import { Chip } from 'ui/chip'
import { Icon } from 'ui/icon'

import { useHorizontalScroll } from 'lib/hooks/useHorizontalScroll'

import s from './styles.module.scss'

type RegionChipsProps = {
  regions: Region[]
  selectedRegionId: string
  onSelectRegion: (regionId: string) => () => void
}

const RegionChips = ({ regions, selectedRegionId, onSelectRegion }: RegionChipsProps) => {
  const { ref, isStartPosition, isEndPosition, scrollToLeft, scrollToRight } = useHorizontalScroll({
    defaultIsEndPosition: false,
  })

  return (
    <div className={s.regionChips}>
      <div
        className={cn(s.sliderArrowContainer, s.previous, {
          [s.hidden]: isStartPosition,
        })}
      >
        <div className={cn(s.sliderArrow, s.sliderArrowPrevious)} onClick={scrollToLeft}>
          <Icon name="caret-left" size="mini" />
        </div>
      </div>
      <div className={s.chipContainer} ref={ref}>
        {regions.map((region) => (
          <Chip
            id={region.regionId}
            key={region.regionId}
            label={region.regionName}
            onClick={onSelectRegion(region.regionId)}
            selected={selectedRegionId === region.regionId}
            size="medium"
            variant="light-ghost-bold"
            className={s.chip}
          />
        ))}
      </div>

      <div
        className={cn(s.sliderArrowContainer, s.next, {
          [s.hidden]: isEndPosition,
        })}
      >
        <div className={cn(s.sliderArrow, s.sliderArrowNext)} onClick={scrollToRight}>
          <Icon name="caret-right" size="mini" />
        </div>
      </div>
    </div>
  )
}

export { RegionChips }
