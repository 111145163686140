import React from 'react'

import { noop } from 'lib/utils'

type InitialPosition = 'left' | 'top' | 'right' | 'bottom'

type ModalOptions = {
  isActive?: boolean
  withCloseBtn?: boolean
  content: React.ReactNode
  closeOnOverlayClick?: boolean
  closeOnEsc?: boolean
  blur?: boolean
  backgroundWhite?: boolean
  padding?: boolean
  appearFrom?: InitialPosition
}

export const initialGlobalArgs: GlobalArgs = {
  locales: [],
  categories: [],
  constants: {},
  currencies: [],
  tags: [],
  rewardsBanner: {},
  whitelabel: [],
  kfMilesRate: {
    kfMilesBurnRateSgd: 0,
    kfMilesEarnRateSgd: 0,
  },
  appTouchpointsData: {
    isEnabled: false,
    header: '',
    subHeader: '',
  },
}

export interface IGlobalContext {
  globalArgs: GlobalArgs
  geoLocation?: GeoLocation
  currentCurrency: string | undefined
  openModal: (options: ModalOptions) => void
  closeModal: () => void
  isMobileView: boolean
  isTabletView: boolean
  yellowAi: {
    renderScript: boolean
    showAndToggle: () => void
    hide: () => void
    show: () => void
    showIcon: boolean
  }
}

export const GlobalContext = React.createContext<IGlobalContext>({
  globalArgs: initialGlobalArgs,
  currentCurrency: undefined,
  openModal: noop,
  closeModal: noop,
  isMobileView: false,
  isTabletView: false,
  yellowAi: {
    renderScript: false,
    showAndToggle: noop,
    hide: noop,
    show: noop,
    showIcon: false,
  },
})

const GlobalContextProvider = GlobalContext.Provider

function useGlobalContext() {
  const context = React.useContext(GlobalContext)

  if (context === undefined) {
    throw new Error('useGlobalContext must be used within a GlobalContextProvider')
  }
  return context
}

export { GlobalContextProvider, useGlobalContext }
