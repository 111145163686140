import React from 'react'

interface Callback {
  (): void
}

const useClickOutside = (el: React.MutableRefObject<HTMLElement | null>, callback: Callback) => {
  const callbackRef = React.useRef<Callback>()
  callbackRef.current = callback

  React.useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (!(e.target instanceof Node) || !el.current) return
      if (!el.current.contains(e.target) && callbackRef.current) {
        callbackRef.current()
      }
    }

    document.addEventListener('click', handleClickOutside)
    return () => document.removeEventListener('click', handleClickOutside)
  }, [el, callbackRef])
}

export default useClickOutside
