import { CSSProperties, useCallback, useMemo } from 'react'
import cn from 'classnames'

import { Icon } from 'ui/icon'
import { Image } from 'ui/image'

import { Link } from 'components/link'
import { InfiniteScroller } from 'components/infinite-scroll'

import useCustomTranslation from 'lib/hooks/useTranslation'
import { useRegionWithDestination } from 'lib/hooks/useRegionWithDestination'

import { buildPath } from 'lib/utils'
import { useAppData } from 'lib/context/app-data-context'
import { useGlobalContext } from 'lib/context/global-context'

import { DESTINATION_ROUTE_V1 } from 'lib/constants/routes'

import type { ItemClickFn } from '../types'
import { RegionChipsLoading, DestinationsByRegionLoading } from './loading'
import { RegionChips } from './region-chips'
import { DESTINATIONS_BY_REGION_PAGE_SIZE } from '../constants'

import s from './styles.module.scss'

const TOP_10_REGION_ID = 'topTrendingDestinations'

type ExploreDestinationsProps = {
  destinationsByRegion: DestinationByRegion | undefined
  fetchMoreDestinationsByRegion: () => void
  shouldRedirectAfterSelect?: boolean
  initialLoading: boolean
  isLoading: boolean
  currentPage: number
  selectedTag?: string
  onItemClick: ItemClickFn
}

const ExploreDestinations = ({
  shouldRedirectAfterSelect,
  destinationsByRegion,
  initialLoading,
  isLoading,
  currentPage,
  selectedTag,
  fetchMoreDestinationsByRegion,
  onItemClick,
}: ExploreDestinationsProps) => {
  const { t } = useCustomTranslation('common')
  const { activityLog } = useAppData()
  const {
    globalArgs: { tags },
  } = useGlobalContext()
  const { destinations, onSelectRegion, regions, selectedRegionId } = useRegionWithDestination({})

  const renderDestinationButton = useCallback(
    (destination: RegionDestination, index) => {
      return (
        <button
          onClick={() =>
            onItemClick(destination.uri, {
              isCountry: false,
              source: selectedRegionId,
              name: destination.name,
              parentCountryName: destination.countryName,
              code: `${destination.code}`,
            })
          }
          type="button"
          key={`${destination.uri}-${index}-${selectedRegionId}`}
          className={s.destination}
          style={
            { '--stagger-delay': `${(index % DESTINATIONS_BY_REGION_PAGE_SIZE) * 0.02}s` } as CSSProperties
          }
        >
          <div className={s.destinationContentContainer}>
            <div className={s.destinationImageContainer}>
              <Image
                src={destination.thumbnail}
                size="xsmall"
                alt={destination.name}
                layout="fill"
                lazyLoad={false}
                className={s.destinationImage}
              />
            </div>
            <div className={s.destinationContent}>
              <p className={s.destinationContentName}>{destination.name}</p>
              <span className={s.destinationContentCountry}>{destination.countryName}</span>
            </div>
          </div>
          <div className={s.destinationIcon}>
            <Icon name="arrow-right" />
          </div>
        </button>
      )
    },
    [onItemClick, selectedRegionId]
  )

  const renderDestinations = useCallback(() => {
    if (!destinations || !destinations.length) return null

    return (
      <div className={s.destinationContainer}>
        {destinations.map((destination, index) => {
          return !shouldRedirectAfterSelect ? (
            renderDestinationButton(destination, index)
          ) : (
            <Link
              key={`${destination.uri}-${index}-${selectedRegionId}`}
              href={buildPath(DESTINATION_ROUTE_V1, {
                destinationId: destination.destinationId,
                destinationCode: `${destination.code}`,
              })}
            >
              {renderDestinationButton(destination, index)}
            </Link>
          )
        })}
      </div>
    )
  }, [destinations, shouldRedirectAfterSelect, renderDestinationButton, selectedRegionId])

  const hasRecentlyViewed = !!activityLog.destinationCountries?.length
  const totalPages = useMemo(() => {
    if (selectedRegionId === TOP_10_REGION_ID) return 1

    const selectedRegion = destinationsByRegion?.groupedByRegion.find(
      (regionGroup) => regionGroup.region.regionId === selectedRegionId
    )
    if (!selectedRegion) return 1

    return Math.ceil(selectedRegion.destinationList.destinationCount / DESTINATIONS_BY_REGION_PAGE_SIZE)
  }, [selectedRegionId, destinationsByRegion])

  const hasMore = currentPage < totalPages

  const title = useMemo(() => {
    if (selectedTag) {
      const tagLabel = tags.find((tag) => tag.tagId === selectedTag)?.tagLabel || ''
      if (tagLabel) {
        return t('t.exploreActivityIn', { activityType: tagLabel })
      }
    }

    return t('t.exploreNumPlusDestinations', { num: 2000 })
  }, [selectedTag, tags, t])

  return (
    <>
      <div className={s.header}>
        <h3 className={cn(s.title, { [s.titleMd]: hasRecentlyViewed })}>{title}</h3>
        {initialLoading ? (
          <RegionChipsLoading />
        ) : (
          <RegionChips
            regions={regions}
            selectedRegionId={selectedRegionId}
            onSelectRegion={onSelectRegion}
          />
        )}
      </div>
      {initialLoading ? (
        <DestinationsByRegionLoading />
      ) : selectedRegionId === TOP_10_REGION_ID ? (
        renderDestinations()
      ) : (
        <InfiniteScroller
          onFetchMoreCallback={fetchMoreDestinationsByRegion}
          results={renderDestinations()}
          hasMore={hasMore}
          isLoading={isLoading}
          showLoader
        />
      )}
    </>
  )
}

export { ExploreDestinations }
