import cn from 'classnames'

import { Button } from 'ui/button'

import useTranslation from 'lib/hooks/useTranslation'

import { useAppData } from 'lib/context/app-data-context'

import { EVENTS } from 'lib/constants/events'

import s from './styles.module.scss'

const SeeMore = ({
  value,
  className,
  onClick,
  trackEvent,
  hidden,
  flatButton,
}: SearchAutoCompleteSeeMore) => {
  const { activityLog } = useAppData()
  const { t } = useTranslation()

  return !hidden ? (
    <div className={cn(s.seeMoreBtn, { [s.flatButton]: flatButton })}>
      <Button
        variant="secondary"
        size="medium"
        fluid
        onClick={() => {
          activityLog.addItem({ keyword: value })

          trackEvent?.({
            attributeId: EVENTS.AUTOSUGGEST_SEARCH_SHOW_ALL,
            attributeType: EVENTS.ATTRIBUTES_TYPE.OPTION,
            attributeValue: {
              value,
            },
          })
          onClick?.()
        }}
        className={cn(s.info, s.seeMore, className)}
      >
        <div>{t('action.seeAllResultsFor', { query: value })}</div>
      </Button>
    </div>
  ) : null
}

export { SeeMore }
